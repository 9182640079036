<div class="control-container main-container margin-top" [ngClass]="getBackgroundColor()">
    <div class="header-container">
        <div class="container">
            <div *ngIf="practiceMode">{{ timer$ | async}}</div>
            <div *ngIf="challengeMode">{{ countdown }}</div>
            <div><b>Score: {{score}}</b></div>
            <div>{{ returnScoreAsString() }}</div>
        </div>
        <br />
        <div class="container">
            <svg
            (click)="playSample(false)" 
            xmlns="http://www.w3.org/2000/svg" id="speaker" x="0" y="0" version="1.1" viewBox="0 0 29 29" 
            xml:space="preserve"><path d="M20.535 19.035a.999.999 0 0 1-.707-1.707C20.573 16.584 21 15.554 21 14.5s-.427-2.084-1.172-2.828a.999.999 
            0 1 1 1.414-1.414C22.359 11.373 23 12.92 23 14.5s-.641 3.127-1.758 4.242a.993.993 0 0 1-.707.293z"></path>
            <path d="M23.364 21.864a.999.999 0 0 1-.707-1.707C24.146 18.667 25 16.605 25 14.5s-.854-4.167-2.343-5.657a.999.999 0 1 1 
            1.414-1.414C25.933 9.291 27 11.868 27 14.5s-1.067 5.209-2.929 7.071a.993.993 0 0 1-.707.293zM17 25.5a.995.995 0 0 1-.581-.187L9.68 20.5H3a1 
            1 0 0 1-1-1v-10a1 1 0 0 1 1-1h6.68l6.739-4.813A.999.999 0 1 1 18 4.5v20a1.001 1.001 0 0 1-1 1zm-13-7h6c.208 0 .412.065.581.187L16 22.557V6.443l-5.419 3.87A.994.994 0 0 1 10 10.5H4v8z">
            </path></svg>
            <div class="loading-container">
                <div class="loading-bar" [style.width.%]="loading$ | async"></div>
            </div>
            <div class="invisible">

            </div>
        </div>

    </div>

    <div *ngIf="gameId == 1" class="button-container">
        <div *ngIf="difficulty == 1">
            <div class="button-container-easy">
                <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(1, button1)">Major Triad</button>
                <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(4, button2)">Minor Triad</button>
                <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(7, button3)">Augmented Triad</button>
                <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="checkAnswer(10, button4)">Diminished Triad</button>
                
            </div>
        </div>
        <div *ngIf="difficulty == 2">
            <div class="button-container-medium">
                <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="checkAnswer(13, button5)">Dom7</button>
                <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="checkAnswer(17, button6)">Maj7</button>
                <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="checkAnswer(21, button7)">Min7</button>
                <button #button8 class="button" [ngClass]="getBackgroundColor()" id="8" (click)="checkAnswer(25, button8)">Min7b5</button>
                <button #button9 class="button" [ngClass]="getBackgroundColor()" id="9" (click)="checkAnswer(29, button9)">Dim7</button>
            </div>
        </div>
        <div *ngIf="difficulty == 3">
            <div class="button-container-hard">
                <button #button11 class="button" [ngClass]="getBackgroundColor()" id="11" (click)="checkAnswer(35, button11)">Dom9</button>
                <button #button10 class="button" [ngClass]="getBackgroundColor()" id="10" (click)="checkAnswer(30, button10)">Maj9</button>
                <button #button12 class="button" [ngClass]="getBackgroundColor()" id="12" (click)="checkAnswer(40, button12)">Min9</button>
                <button #button13 class="button" [ngClass]="getBackgroundColor()" id="13" (click)="checkAnswer(45, button13)">Dom7b9</button>
                <button #button14 class="button" [ngClass]="getBackgroundColor()" id="14" (click)="checkAnswer(50, button14)">Dom7#9</button>
                <button #button16 class="button" [ngClass]="getBackgroundColor()" id="16" (click)="checkAnswer(60, button16)">Dom11</button>
                <button #button15 class="button" [ngClass]="getBackgroundColor()" id="15" (click)="checkAnswer(55, button15)">Maj11</button>
                <button #button17 class="button" [ngClass]="getBackgroundColor()" id="17" (click)="checkAnswer(65, button17)">Min11</button>
                <button #button18 class="button" [ngClass]="getBackgroundColor()" id="18" (click)="checkAnswer(70, button18)">Dom7#11</button>
                <button #button20 class="button" [ngClass]="getBackgroundColor()" id="20" (click)="checkAnswer(80, button20)">Dom13</button>
                <button #button19 class="button" [ngClass]="getBackgroundColor()" id="19" (click)="checkAnswer(75, button19)">Maj13</button>
                <button #button21 class="button" [ngClass]="getBackgroundColor()" id="21" (click)="checkAnswer(85, button21)">Min13</button>
            </div>
        </div>
    </div>

    <div *ngIf="gameId == 2" class="button-container">
        <div *ngIf="difficulty == 1">
            <div class="button-container-easy">
                  <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(3, button1)">m3</button>
                  <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(4, button2)">M3</button>
                  <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(7, button3)">P5</button>
                  <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="checkAnswer(12, button4)">P8</button>
                
            </div>
        </div>
        <div *ngIf="difficulty == 2">
            <div class="button-container-medium">
                  <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(2, button1)">M2</button>
                  <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(4, button2)">M3</button>
                  <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(5, button3)">P4</button>
                  <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="checkAnswer(7, button4)">P5</button>
                  <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="checkAnswer(9, button5)">M6</button>
                  <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="checkAnswer(11, button6)">M7</button>
                  <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="checkAnswer(12, button7)">P8</button>

            </div>
        </div>
        <div *ngIf="difficulty == 3">
            <div class="button-container-hard">
                  <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(1, button1)">m2</button>
                  <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(2, button2)">M2</button>
                  <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(3, button3)">m3</button>
                  <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="checkAnswer(4, button4)">M3</button>
                  <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="checkAnswer(5, button5)">P4</button>
                  <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="checkAnswer(6, button6)">A4</button>
                  <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="checkAnswer(7, button7)">P5</button>
                  <button #button8 class="button" [ngClass]="getBackgroundColor()" id="8" (click)="checkAnswer(8, button8)">m6</button>
                  <button #button9 class="button" [ngClass]="getBackgroundColor()" id="9" (click)="checkAnswer(9, button9)">M6</button>
                  <button #button10 class="button" [ngClass]="getBackgroundColor()" id="10" (click)="checkAnswer(10, button10)">m7</button>
                  <button #button11 class="button" [ngClass]="getBackgroundColor()" id="11" (click)="checkAnswer(11, button11)">M7</button>
                  <button #button12 class="button" [ngClass]="getBackgroundColor()" id="12" (click)="checkAnswer(12, button12)">P8</button>
            </div>
        </div>
    </div>

    <div *ngIf="gameId == 3" class="button-container">
        <div *ngIf="difficulty == 1">
            <div class="button-container-easy">
                  <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(1, button1)">Major</button>
                  <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(2, button2)">Natural Minor</button>
                  <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(3, button3)">Melodic Minor</button>
                  <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="checkAnswer(4, button4)">Harmonic Minor</button>
                
            </div>
        </div>
        <div *ngIf="difficulty == 2">
            <div class="button-container-medium">
                  <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(1, button1)">Ionian</button>
                  <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(6, button2)">Dorian</button>
                  <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(7, button3)">Phrygian</button>
                  <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="checkAnswer(8, button4)">Lydian</button>
                  <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="checkAnswer(9, button5)">Mixolydian</button>
                  <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="checkAnswer(2, button6)">Aeolian</button>
                  <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="checkAnswer(10, button7)">Locrian</button>

            </div>
        </div>
        <div *ngIf="difficulty == 3">
            <div class="button-container-hard">
                <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="checkAnswer(5, button1)">Harmonic Major</button>
                <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="checkAnswer(11, button2)">Persian</button>
                <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="checkAnswer(12, button3)">Hungarian Minor</button>
                <button #button4 class="button" [ngClass]="getBackgroundColor()"id="4" (click)="checkAnswer(13, button4)">Double Harmonic</button>
                <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5"(click)="checkAnswer(14, button5)">Enigmatic</button>                    
                <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="checkAnswer(15, button6)">Neapolitan Major</button>
                <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="checkAnswer(16, button7)">Neapolitan Minor</button>
            </div>
        </div>
    </div>

    <div [ngClass]="getBackgroundColor()">
        <button class="submit-button" [ngClass]="getBackgroundColor()" (click)="finish()">Finish</button>
    </div>
</div>

