import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { GameId } from '../../../constants/GameIdEnum';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { Staff } from '../../../staff-graphics/models/Staff';
import { StaffInterval } from '../../../staff-graphics/models/StaffInterval';
import { SharedDataService } from '../../../util/SharedDataService';
import { Interval } from '../../../models/Interval';

@Component({
  selector: 'app-staff-building-interval-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-building-interval-game.component.css"]
})
export class StaffBuildingIntervalGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy {
    public override gameId = GameId.StaffBuilding_Intervals;
    override initialCountdown: number = 600;
    private staff: Staff = new Staff([4, 4], []);

    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object){
        super(router, sharedDataService, dialog, route);
    }

    override ngOnInit(): void {
        this.initModule();
        const interval: Interval = this.generateRandomInterval(this.isTrebleClef);
        this.randomElement = new StaffInterval(interval)
    }
    
    //Runs after view has initialized
    ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            this.enableEventHandlers();
        }
    }

    //Refreshes canvas
    protected override refreshStaff(): void {
        //Generate new interval and refresh the canvas
        setTimeout(async () => {
            const interval = this.generateRandomInterval(this.isTrebleClef);
            this.randomElement = new StaffInterval(interval);
            this.clickEnabled = await this.builder.resetNoteArray(this.randomElement, this.isTrebleClef);
        }, 500);
        

    }

}
