import { Clef } from "../../constants/DifficultyEnum";
import { Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule} from '@angular/common';
import { SharedDataService } from '../../util/SharedDataService';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { GameHandler } from "../GameHandler";
import { ScoreRow } from "../score-sheet/ScoreTypes";
import { MatDialog } from '@angular/material/dialog';
import { RoutingUtil } from "../../util/RoutingUtil";
import { StaffBuilder } from "../../staff-graphics/StaffBuilder";
import { StaffNote } from "../../staff-graphics/models/StaffNote";
import { Note } from "../../models/Note";
import { CanvasDoubleBuffer } from "../../staff-graphics/CanvasDoubleBuffer";
import { StaffInterval } from "../../staff-graphics/models/StaffInterval";
import { StaffChord } from "../../staff-graphics/models/StaffChord";
import { StaffScale } from "../../staff-graphics/models/StaffScale";
import { StaffElement } from "../../staff-graphics/models/StaffElement";
import { Interval } from "../../models/Interval";
import { Chord } from "../../models/Chord";
import { Scale } from "../../models/Scale";
import { CommonUtil } from "../../util/CommonUtil";
import { GameId } from "../../constants/GameIdEnum";


@Component({
  selector: 'app-staff-game-handler',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './staff-game-handler.component.html',
  styleUrl: './staff-game-handler.component.css'
})

export class StaffGameHandlerComponent extends GameHandler{

    @ViewChild('staffCanvas')
    canvas!: ElementRef<HTMLCanvasElement>;
    canvasDoubleBuffer!: CanvasDoubleBuffer;

    @ViewChild('staffContainer2')
    parentDivRef!: ElementRef<HTMLDivElement>;

    protected randomElement!: StaffElement;
    protected builder!: StaffBuilder;

    protected isTrebleClef: boolean = true;
    protected clickEnabled: boolean = true; //Enables click events for staff building games

    private mouseY: number = 0;
    private yPos: number = 0;
    private noteCount: number = 0;

    protected images = [];

    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog,
                protected override route: ActivatedRoute 
    ) {
        super(router, sharedDataService, dialog, route);
    }

    ngOnInit(): void {

    }

    override initModule(): boolean{
        //Receives payload from difficulty selector
        const payload = this.sharedDataService.getAndClearFormData();
        if (payload) {
            this.difficulty = payload['difficulty'];
            this.clefType = CommonUtil.flipCoin() === true ? Clef.Treble : Clef.Bass;
            this.practiceMode = payload['practiceModeSelected'];
            this.challengeMode = payload['challengeModeSelected'];
        } else {
            RoutingUtil.navigateToMenu(this.gameId, this.router);
            return false;
        }

        this.isTrebleClef = CommonUtil.flipCoin();
        this.initializeTimers();
        return true;
    }

    // TODO: Regression test for staff building games before removing this method
    public submitNote(): void{
        // console.log("Note submitted");
    }
    
    protected override playSample(correct: boolean): void {}

    //Ends current game and displays score sheet
    protected override finish(): void {
        const payload = {
            scoreRows: this.scoreRows,
            score: this.score,
            gameId: this.gameId,
            difficulty: this.difficulty,
            time: this.secondsPassed,
            mode: this.challengeMode ? true : false
        }
        this.stopTimer();

        //Opens score sheet dialog component
        this.handleDialog(payload);
    }

    protected check(guess: number | string, buttonRef: HTMLButtonElement): void{}
    
    //Handles logic for checking if a user submitted a correct answer
    protected override checkAnswer(guess: number | string, buttonRef: HTMLButtonElement){
        let answer: number | string = "";
        if(this.randomElement instanceof StaffNote){
            answer = this.randomElement.getNote.getRoot;
        } else if(this.randomElement instanceof StaffInterval || this.randomElement instanceof StaffChord){
            answer = this.randomElement.getQuality;
        } else if(this.randomElement instanceof StaffScale){
            answer = this.randomElement.getScale.getQuality;
        }

        let backgroundClassColor = '';
        const id: string = buttonRef.id;

        if(guess === answer){
            this.flashButton(id, true);
            backgroundClassColor = this.changeButtonBackgroundColor(true);
            this.correctGuesses += 1;
            this.incrementScore();

            //Rebuild the staff
            this.refreshStaff(this.randomElement);
        } else {
            this.flashButton(id, false);
            this.decrementScore();
            backgroundClassColor = this.changeButtonBackgroundColor(false);
        }
        buttonRef.classList.add(backgroundClassColor);
        this.totalGuesses += 1;


        //Removes flash class from affected button
        setTimeout(() => {
            buttonRef.classList.remove(backgroundClassColor);
            this.resetFlashClasses();
        }, 200)

        //Adds score row to array for results dialog
        const scoreRow: ScoreRow = {
            id: guess,
            expectedId: answer,
            gameId: this.gameId 
        }
        this.scoreRows.push(scoreRow);
    }
    
    //Refreshes the staff element and resets builder note array
    protected refreshStaff(element: StaffElement): void {
        let staffElement: StaffElement = new StaffNote(new Note("C4"));
        this.isTrebleClef = CommonUtil.flipCoin();
        if(element instanceof StaffNote){
            const note: Note = this.generateRandomNote(this.isTrebleClef);
            staffElement = new StaffNote(note);
        } else if(element instanceof StaffInterval){
            const interval: Interval = this.generateRandomInterval(this.isTrebleClef);
            staffElement = new StaffInterval(interval);
        } else if(element instanceof StaffChord){
            const chord: Chord = this.generateRandomChord(this.isTrebleClef);
            staffElement = new StaffChord(chord);
        } else if(element instanceof StaffScale){
            const scale: Scale = this.generateRandomScale(this.isTrebleClef);
            staffElement = new StaffScale(scale);
        }
        this.randomElement = staffElement
        this.builder.resetNoteArray(this.randomElement, this.isTrebleClef);
    };

    //Sets fundamental game variables back to 0
    protected override restartGame(): void{
        this.correctGuesses = 0;
        this.totalGuesses = 0;
        this.timer$ = new Observable<string>();
        this.loading$ = new Observable<number>();
        this.secondsPassed = 0;
        this.countdownSubscription = new Subscription();
        this.scoreRows = [];
        this.score = 0;
        this.initializeTimers();

        this.refreshStaff(this.randomElement);
    }

    //Sets the size of the canvas to match the size of its parent div
    protected setCanvasSize(): void {
        const parentDiv: HTMLDivElement = this.parentDivRef.nativeElement;
        const canvas: HTMLCanvasElement = this.canvas.nativeElement;
    
        // Set canvas size to match the parent div
        canvas.width = parentDiv.clientWidth;
        canvas.height = parentDiv.clientHeight;
        this.canvas.nativeElement = canvas;
        console.log(canvas.width + " : " + canvas.height);
    }

    //Reupdates the size of the canvas
    protected test(): void{

        const canvas: HTMLCanvasElement = this.canvas.nativeElement;
        canvas.width = this.parentDivRef.nativeElement.clientWidth;
        canvas.height = this.parentDivRef.nativeElement.clientHeight;
    }

    //Manages the event handlers for the staff building games
    protected enableEventHandlers(): void{
        //Update pitch count 
        this.yPos = this.getYPos();
        this.noteCount += 1;
        this.canvas.nativeElement.addEventListener('click', (event) => {
            if(this.clickEnabled){
                requestAnimationFrame(async () => {
                    const mouseY: number = event.clientY - this.canvas.nativeElement.getBoundingClientRect().top;    
                    
                    //Ensure that user click is within legal bounds
                    if(this.yPos - mouseY >= 10 && mouseY >= 15){
                        this.builder.setClickEventListener(this.canvas.nativeElement, this.isTrebleClef, this.randomElement, event, mouseY);
                         
                        let guess: number = 0;
                        let answer: number = -1;
                        const guessedValue: StaffElement = this.builder.evaluateNoteSelection(this.gameId, this.isTrebleClef);
                        if(guessedValue instanceof StaffInterval &&
                                  this.randomElement instanceof StaffInterval){
                            this.clickEnabled = false;
                            guess = guessedValue.getQuality.valueOf();
                            answer = this.randomElement.getQuality.valueOf();
                        } else if(guessedValue instanceof StaffChord &&
                                    this.randomElement instanceof StaffChord){
                            //Only continue if the two lengths match
                            this.yPos = this.getYPos();
                            if(this.randomElement.getNotes.length != guessedValue.getNotes.length){
                                return;
                            }
                            this.clickEnabled = false;
                            guess = guessedValue.getQuality.valueOf();
                            answer = this.randomElement.getQuality.valueOf();

                        } else if(guessedValue instanceof StaffScale && 
                                this.randomElement instanceof StaffScale){
                            this.noteCount = this.builder.noteCount;
                            if(this.noteCount < this.randomElement.getNotes.length){
                                return;
                            }
                            this.clickEnabled = false;
                            guess = guessedValue.getScale.getQuality.valueOf();
                            answer = this.randomElement.getScale.getQuality.valueOf();

                            //Adjust Ionian and Aeolian values to match major and minor scales
                            if(this.gameId == GameId.StaffIdentification_Scales ||
                                (this.gameId == GameId.StaffBuilding_Scales && this.difficulty == 2)){
                                if(guess === 1){
                                    guess = 17;
                                } else if(guess === 2){
                                    guess = 18;
                                }
                            }
                            
                        } 

                        if(guess === answer){
                            //Answer is correct, update the score 
                            this.correctGuesses += 1;
                            this.incrementScore();
                            
                            //Flash green to indicate that the answer was correct
                            this.builder.changeNoteColor(guessedValue, this.isTrebleClef, true);

                            //Generate new staff element and refresh the canvas
                            setTimeout(() => {
                                if(this.randomElement instanceof StaffNote){
                            
                                } else if(this.randomElement instanceof StaffInterval){
                                    const interval = this.generateRandomInterval(this.isTrebleClef);
                                    this.randomElement = new StaffInterval(interval);
                                } else if(this.randomElement instanceof StaffChord){
                                    const chord = this.generateRandomChord(this.isTrebleClef);
                                    this.randomElement = new StaffChord(chord);
                                } else if(this.randomElement instanceof StaffScale){
                                    const scale = this.generateRandomScale(this.isTrebleClef);
                                    this.randomElement = new StaffScale(scale);
                                } 
                                
                                this.clickEnabled = this.builder.resetNoteArray(this.randomElement, this.isTrebleClef);
                            }, 500);
                            this.isTrebleClef = CommonUtil.flipCoin();
                        } else{
                            //Answer is not correct, update the score
                            this.decrementScore();

                            //Flash red to indicate that the answer was NOT correct
                            this.builder.changeNoteColor(guessedValue, this.isTrebleClef, false);
                            setTimeout(() => {
                                this.clickEnabled = this.builder.resetNoteArray(this.randomElement, this.isTrebleClef);
                            }, 500);                                
                        }
                        // this.builder.pitchCount += 1;
                        this.totalGuesses += 1;
                        

                        //Update score row
                        const scoreRow: ScoreRow = {
                            id: guess,
                            expectedId: answer,
                            gameId: this.gameId 
                        }
                        this.scoreRows.push(scoreRow);
    
                    }
                });
            }
            
            
        });

        //Mousemove event
        this.canvas.nativeElement.addEventListener('mousemove', (event) => {
            // this.yPos = this.get.yPos();
            this.yPos = this.getYPos();
            
            const mouseY: number = event.clientY - this.canvas.nativeElement.getBoundingClientRect().top;   
            this.mouseY = mouseY;

            if(this.clickEnabled){
                const mouseY = event.clientY - this.canvas.nativeElement.getBoundingClientRect().top;    
                this.mouseY = mouseY;

                requestAnimationFrame(() => {
                    this.builder.setHoverEventListener(this.canvas.nativeElement, this.isTrebleClef, this.randomElement, event, mouseY);
                });
            }
            // this.builder.updateMousePosition(mouseY);
        });

        //Keypress event
        document.addEventListener("keyup", (event) => {
            if(this.clickEnabled){
                if(this.yPos - this.mouseY >= 10 && this.mouseY >= 15){
                    if (event.key === "w" || event.key === "W" ||
                    event.key === 's' || event.key === 'S' ||
                    event.key === 'a' || event.key === 'A' ||
                    event.key === 'd' || event.key === 'D' ||
                    event.key === 'q' || event.key === 'Q' ||
                    event.key === 'x' || event.key === 'X') {
                        this.builder.setHoverEventListener(this.canvas.nativeElement, this.isTrebleClef, this.randomElement, event, this.mouseY);
                    }
                }
            }
        });
    }

    //Returns the Y position of the first note of a staff element object
    private getYPos(): number{
        if(this.randomElement instanceof StaffNote){
            return this.randomElement.getYPos;
        } else if(this.randomElement instanceof StaffInterval){
            return this.randomElement.getNote1.getYPos;
        } else if(this.randomElement instanceof StaffChord || this.randomElement instanceof StaffScale){
            return this.randomElement.getNotes[0].getYPos;
        }

        //Return 0 on type mismatch
        return 0;
    }

    //Destroys subscription object when component is terminated
    ngOnDestroy() {
        this.stopTimer();
    }

    

}
