import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';
import { AuthenticationService } from '../../../auth/service/AuthenticationService';
import { CommonUtil } from '../../../util/CommonUtil';
import { AuthDataService } from '../../../data-services/AuthDataService';

@Component({
  selector: 'app-password-profile-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule],
  templateUrl: './password-profile-dialog.component.html',
  styleUrls: ['./password-profile-dialog.component.css', '../profile-dialog/profile-dialog.component.css', 
    '../../profile.component.css']
})
export class PasswordProfileDialogComponent implements OnInit{
    public passwordChangeError_serviceUnavailable: boolean = false;
    public passwordChangeError_emailUnavailable: boolean = false;
    public passwordChangeError_emailNotSent: boolean = false;
    public emailSentSuccessfully: boolean = false;
    public emailAddr: string = "";

    constructor(public dialogRef: MatDialogRef<ProfileDialogComponent>, private authService: AuthenticationService){}
    
    public async ngOnInit(): Promise<void> {
        //Check if the email is stored in the session
        //If not, have user manually enter the email
        if(CommonUtil.isEmpty(this.authService.getEmail() as string)){
            this.passwordChangeError_emailUnavailable = true;
        }

        //If email is available, send a password change request
        if(!this.passwordChangeError_emailUnavailable){
            const email: string = this.authService.getEmail() as string;
            const tokenGeneratedJson: string = await AuthDataService.generatePasswordResetToken(email);
            let tokenGenerated: boolean = false;
            let emailSent: boolean = false;

            if(!CommonUtil.isEmpty(tokenGeneratedJson)){
                tokenGenerated = JSON.parse(tokenGeneratedJson).tokenGenerated;
            }

            //If the token was generated, attempt to send email
            if(tokenGenerated){
                const emailSentJson: string = await AuthDataService.sendPasswordResetEmail(email);
                if(!CommonUtil.isEmpty(emailSentJson)){
                    emailSent = JSON.parse(emailSentJson).emailSent;
                }
            }

            //Verify that token was generated and email was sent
            if(tokenGenerated && emailSent){
                this.emailSentSuccessfully = true;
            } else if (tokenGenerated && !emailSent){
                this.passwordChangeError_emailNotSent = true;
            } else {
                this.passwordChangeError_serviceUnavailable = true;
            }
        }
    }

    public confirm(): void{
        //Reset pre-existing error flags
        this.passwordChangeError_serviceUnavailable = false;
        this.passwordChangeError_emailUnavailable = false;

    
    }

    public cancel(): void{
        this.dialogRef.close();
    }

}
