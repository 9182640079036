<app-unavailable *ngIf="!displayRecords && rendered">
</app-unavailable>

<div *ngIf="!rendered">
    <app-spinner ></app-spinner>
</div>

<div class="leaderboard-container margin-top-leaderboard" *ngIf="displayRecords && rendered">
    <div class="leaderboard-control-container">
        <select [(ngModel)]="gameType" (change)="selectGameType()">
            <option value="2">Ear Training - Intervals</option>
            <option value="1">Ear Training - Chords</option>
            <option value="3">Ear Training - Scales</option>
            <option value="7">Staff Identification - Notes</option>
            <option value="6">Staff Identification - Intervals</option>
            <option value="4">Staff Identification - Chords</option>
            <option value="5">Staff Identification - Scales</option>
            <option value="10">Staff Building - Intervals</option>
            <option value="8">Staff Building - Chords</option>
            <option value="9">Staff Building - Scales</option>
        </select>
        <select [(ngModel)]="difficulty" (change)="selectGameType()">
            <option value="1" selected="selected">Easy</option>
            <option value="2">Medium</option>
            <option value="3">Hard</option>
        </select>
        <div></div>
        <button (click)="decrement()" [disabled]="pageIndex === 0">&larr;
        </button>
        <button (click)="increment()" [disabled]="currentRecords.length <= 20 || currentRecords.length <= (pageIndex * 20) + 20">&rarr;
        </button>
    </div>

    <table class="leaderboard-table">
        <thead>
            <tr>
                <th>Rank</th>
                <th>User</th>
                <th>Score</th>
                <!-- <th>Total Correct</th>
                <th>Total Attempted</th> -->
                <th>Accuracy</th>
                <th>Date</th>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let record of currentRecords | slice:pageIndex * 20: (pageIndex * 20) + 20; let i = index" 
                [ngStyle]="{'background-color': record.backgroundColor}">
                <td *ngIf="gameType == record.gameId" class="record-attribute">{{record.rank}}</td>
                <td *ngIf="gameType == record.gameId" class="record-attribute">{{record.username}}</td>
                <td *ngIf="gameType == record.gameId" class="record-attribute">{{record.score}}</td>
                <!-- <td *ngIf="gameType == record.gameId" class="record-attribute">{{record.totalCorrect}}</td>
                <td *ngIf="gameType == record.gameId" class="record-attribute">{{record.totalAttempted}}</td> -->
                <td *ngIf="gameType == record.gameId" class="record-attribute">{{record.accuracy}}%</td>
                <td *ngIf="gameType == record.gameId" class="record-attribute">{{pipeDate(record.timestamp)}}</td>
            </tr>
        </tbody>
    </table>
</div>
