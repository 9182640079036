import { Staff } from "./models/Staff";
import { StaffNote } from "./models/StaffNote";
import { Note } from "../models/Note";
import { StaffUtil } from "../util/StaffUtil";
import { StaffInterval } from "./models/StaffInterval";
import { StaffScale } from "./models/StaffScale";
import { StaffChord } from "./models/StaffChord";
import { GameId } from "../constants/GameIdEnum";
import { Interval } from "../models/Interval";
import { Chord } from "../models/Chord";
import { ChordQuality } from "../constants/ChordEnum";
import { StaffElement } from "./models/StaffElement";
import { ScaleQuality } from "../constants/ScaleQualityConstants";
import { Scale } from "../models/Scale";

//Handles the front end construction of a staff
export class StaffBuilder{
    private staff: Staff;
    private canvas: HTMLCanvasElement;
    private context: CanvasRenderingContext2D | null;
    private gameId: GameId;

    //Constant canvas elements
    private lineWidth: number = 560;
    private readonly lineHeight: number = 2;
    private readonly shortLineWidth: number = 40;
    private readonly shortLineHeight: number = 2;
    private readonly lineX: number = 10;
    private shortLineX: number = 290;
    private readonly lineAbove3Y: number = 50;
    private readonly lineAbove2Y: number = 70;
    private readonly lineAbove1Y: number = 90;        
    private readonly line1Y: number = 110;
    private readonly line2Y: number = 130;
    private readonly line3Y: number = 150;
    private readonly line4Y: number = 170;
    private readonly line5Y: number = 190;
    private readonly lineBelow1Y: number = 210;
    private readonly lineBelow2Y: number = 230;
    private readonly lineBelow3Y: number = 250;
    private offset: number = 3;

    //Symbol displacements
    private readonly noteDisplacementX: number = this.shortLineX - 5;
    private readonly noteDisplacementY: number = 54;
    private readonly flatDisplacementX: number = 25;
    private readonly sharpDisplacementX: number = 25;
    private readonly flatDisplacementY: number = 10;
    private readonly sharpDisplacementY: number = 15;
    private readonly doubleFlatDisplacementX: number = 40;
    private readonly doubleSharpDisplacementX: number = 30;
    private readonly doubleFlatDisplacementY: number = 10;
    private readonly doubleSharpDisplacementY: number = 47;

    //Symbol fonts
    private readonly wholeNoteFont = '85px Arial';
    private readonly flatFont = '55px Arial';
    private readonly sharpFont = '45px Arial';
    private readonly doubleFlatFont = '55px Arial';
    private readonly doubleSharpFont = '80px Arial';

    // private debouncedMousemove: ((event: MouseEvent) => void) | undefined;
    private blackNoteImage: HTMLImageElement = new Image();
    private blackNoteImage_rotated: HTMLImageElement = new Image();
    private greyNoteImage: HTMLImageElement = new Image();
    private greenNoteImage: HTMLImageElement = new Image();
    private greenNoteImage_rotated: HTMLImageElement = new Image();
    private redNoteImage: HTMLImageElement = new Image();
    private redNoteImage_rotated: HTMLImageElement = new Image();
    private wholeNoteImage: HTMLImageElement = new Image();
    private readonly wholeNoteSymbol: string = "\u{1D15D}";
    private readonly doubleSharpSymbol: string = "\uD834\uDD2A";
    private readonly sharpSymbol: string = "\u266F";
    private readonly flatSymbol: string = "\u266D";
    private readonly doubleFlatSymbol: string = "\uD834\uDD2B";
    private trebleClefImage = new Image();
    private bassClefImage = new Image(); 

    //Tracks pitch rotation for building game
    private currPitch: number = 0;

    //Tracks last mouse position
    private currNote: StaffNote = new StaffNote(new Note("A0"));
    public noteCount: number = 1;

    //Flags status of mouseover event 
    private hoverEnabled: boolean = true;
    private clickEnabled: boolean = true;


    public drawnNotes: StaffNote[] = [];
    public pitchCount: number = 0;
    public currentDisplacementX: number = 0;

    //Stores image sources
    private imageSources: string[] = [];
    private imagePromises!: any;

    // Create canvas, context, and double buffer, and loads all staff symbols/images
    constructor(staff: Staff, canvas: HTMLCanvasElement, gameId: GameId){
        this.staff = staff;
        this.canvas = canvas;
        this.context = this.canvas.getContext('2d');
        console.log("THE CANVAS' WIDTH IS " + this.canvas.width);

        this.shortLineX = ((this.canvas.width) / 2) - 20;
        this.noteDisplacementX = this.shortLineX;
        this.lineWidth = this.canvas.width;
        this.lineWidth -= this.lineX;

        this.gameId = gameId;
        


        //Initialize and encode SVG string values
        const svgBlackNote: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="69" y="0" width="8" height="80" fill="black"/>' +
        '<ellipse cx="50" cy="80" rx="18" ry="28" fill="black" transform="rotate(75 50 80)"/>' +
        '</svg>'

        const svgBlackNote_rotated: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="23" y="0" width="8" height="80" fill="black"/>' +
        '<ellipse cx="50" cy="0" rx="18" ry="28" fill="black" transform="rotate(75 50 0)"/>' +
        '</svg>';

        const svgGreyNote: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="69" y="0" width="8" height="80" fill="grey" fill-opacity="0.8"/>' +
        '<ellipse cx="50" cy="80" rx="18" ry="28" fill="grey" fill-opacity="1.0" transform="rotate(75 50 80)"/>' +
        '</svg>'

        const svgGreenNote: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="69" y="0" width="8" height="80" fill="green"/>' +
        '<ellipse cx="50" cy="80" rx="18" ry="28" fill="green" transform="rotate(75 50 80)"/>' +
        '</svg>'

        const svgGreenNote_rotated: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="23" y="0" width="8" height="80" fill="green"/>' +
        '<ellipse cx="50" cy="0" rx="18" ry="28" fill="green" transform="rotate(75 50 0)"/>' +
        '</svg>';

        const svgRedNote: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="69" y="0" width="8" height="80" fill="red"/>' +
        '<ellipse cx="50" cy="80" rx="18" ry="28" fill="red" transform="rotate(75 50 80)"/>' +
        '</svg>'

        const svgRedNote_rotated: string = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="50" height="80">' +
        '<rect x="23" y="0" width="8" height="80" fill="red"/>' +
        '<ellipse cx="50" cy="0" rx="18" ry="28" fill="red" transform="rotate(75 50 0)"/>' +
        '</svg>';

        
        this.blackNoteImage = new Image(); 
        this.blackNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgBlackNote);
        this.blackNoteImage.onload = () => {};

        this.blackNoteImage_rotated = new Image();
        this.blackNoteImage_rotated.src = 'data:image/svg+xml,' + encodeURIComponent(svgBlackNote_rotated);
        this.blackNoteImage_rotated.onload = () => {};

        this.greyNoteImage = new Image();
        this.greyNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgGreyNote);
        this.greyNoteImage.onload = () => {};

        this.greenNoteImage = new Image();
        this.greenNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgGreenNote);
        this.greenNoteImage.onload = () => {};

        this.greenNoteImage_rotated = new Image();
        this.greenNoteImage_rotated.src = 'data:image/svg+xml,' + encodeURIComponent(svgGreenNote_rotated);
        this.greenNoteImage_rotated.onload = () => {};

        this.redNoteImage = new Image();
        this.redNoteImage.src = 'data:image/svg+xml,' + encodeURIComponent(svgRedNote);
        this.redNoteImage.onload = () => {};

        this.redNoteImage_rotated = new Image();
        this.redNoteImage_rotated.src = 'data:image/svg+xml,' + encodeURIComponent(svgRedNote_rotated);
        this.redNoteImage_rotated.onload = () => {};

        this.wholeNoteImage = new Image();
        this.wholeNoteImage.src = 'assets/staff/whole-note.svg';
        this.wholeNoteImage.onload = () => {};

        this.trebleClefImage = new Image();
        this.trebleClefImage.src = 'assets/staff/treble-clef.png';
        this.trebleClefImage.onload = () => {};

        this.bassClefImage = new Image();
        this.bassClefImage.src = 'assets/staff/bass-clef.png';
        this.bassClefImage.onload = () => {};

        this.imageSources = [this.blackNoteImage.src, this.blackNoteImage_rotated.src, this.greyNoteImage.src,
            this.greenNoteImage.src, this.greenNoteImage_rotated.src, this.redNoteImage.src,
            this.redNoteImage_rotated.src, this.wholeNoteImage.src, this.trebleClefImage.src,
            this.bassClefImage.src];
        
        this.imagePromises = this.imageSources.map(src => this.loadImage(src));

        

    }

    //Configures the staff game by drawing appropriate element structure based on game type
    private configureGame(staffElement: StaffElement): void{
        const errorMsg: string = "Mismatch of game type and element type";
        if(this.gameId === GameId.StaffIdentification_Notes){
            if(staffElement instanceof StaffNote){
                this.drawnNotes.push(staffElement);
            } else {
                throw Error(errorMsg);
            }
        } else if(this.gameId === GameId.StaffIdentification_Intervals){
            if(staffElement instanceof StaffInterval){
                this.drawnNotes.push(staffElement.getNote1);
                this.drawnNotes.push(staffElement.getNote2);
            } else {
                throw Error(errorMsg);

            }
        } else if (this.gameId === GameId.StaffBuilding_Intervals){
            if(staffElement instanceof StaffInterval){
                //Only draw the root of the interval for the staff builder game
                this.drawnNotes.push(staffElement.getNote1);
            } else {
                throw Error(errorMsg);
            }

        }else if(this.gameId === GameId.StaffIdentification_Chords){
            if(staffElement instanceof StaffChord){
                for(let note of staffElement.getNotes){
                    this.drawnNotes.push(note);
                }
            } else {
                throw Error(errorMsg);

            }
        } else if(this.gameId === GameId.StaffBuilding_Chords) {
            if(staffElement instanceof StaffChord){
                this.drawnNotes.push(staffElement.getNotes[0]);
            } else {
                throw Error(errorMsg);

            }
            
        } else if(this.gameId === GameId.StaffIdentification_Scales){
            if(staffElement instanceof StaffScale){
                for(let note of staffElement.getNotes){
                    this.drawnNotes.push(note);
                }
        
                // this.canvas.width = 1000;
                this.lineWidth = this.canvas.width;
                this.lineWidth -= this.lineX;
            } else {
                throw Error(errorMsg);

            }
        } else if (this.gameId === GameId.StaffBuilding_Scales){
            if(staffElement instanceof StaffScale){
                this.drawnNotes.push(staffElement.getNotes[0]);
                // this.canvas.width = 1000;
                this.lineWidth = this.canvas.width;
                this.lineWidth -= this.lineX;
            } else {
                throw Error(errorMsg);
            }
        }
        else{
            throw Error("Game id was not found");
        }
    }

    private loadImage(src: string): Promise<HTMLImageElement>{
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = () => reject(new Error(`Failed to load image: ${src}`));
            img.src = src;
        });
    }

    //Draws a simple staff for each type of game
    public drawSimpleStaff(staffElement: any, clef: boolean): void{
        //Make sure note array is empty for each game instantiation
        Promise.all(this.imagePromises)
            .then(() => {
                this.drawnNotes = [];
                this.configureGame(staffElement);
        
                //Draw the staff after populating the note array
                if(this.context != null){
                    const canvas2: HTMLCanvasElement = document.createElement('canvas');
                    canvas2.width = this.canvas.width;
                    canvas2.height = this.canvas.height;
                    const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
                    if(ctx2 != null){
                        this.refreshNotes(ctx2, clef);
                        this.drawStaffLines(ctx2);
        
                        if(clef){
                            this.drawTrebleClef(ctx2);
                        } else{
                            this.drawBassClef(ctx2);
                        }
                        this.context.drawImage(canvas2, 0, 0);
                    } else{
                        throw Error("Context failed to initialize");
                    }
                }
            })
            .catch(error => {
                console.error('One or more images failed to load', error);
            });
        
    }

    
    //Redraws the staff
    public refreshStaff(staffElement: any, clef: boolean){
        //Refresh note array and reconfigure the game
        this.drawnNotes = [];
        this.configureGame(staffElement);

        //Generate random clef 
        //Draw the staff after populating the note array
        this.redrawStaff(clef);

    }

    //Event listener for the interval building mouseover event
    public setHoverEventListener(canvas: HTMLCanvasElement, clef: boolean, staffElement: StaffElement, event: Event, mouseY: number): void{
        this.hoverEnabled = true;
        if(this.hoverEnabled){
            const canvas2: HTMLCanvasElement = document.createElement('canvas');
            canvas2.width = this.canvas.width;
            canvas2.height = this.canvas.height;
            const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
            

            if(ctx2 != null){
                this.drawStaffLines(ctx2);
                this.refreshNotes(ctx2, clef);
                if(staffElement instanceof StaffInterval){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNote1, staffElement, clef);
                } else if(staffElement instanceof StaffChord || staffElement instanceof StaffScale){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNotes[0], staffElement, clef);
                }

                if(clef){
                    this.drawTrebleClef(ctx2);
                } else {
                    this.drawBassClef(ctx2);
                }

                if(event instanceof KeyboardEvent){
                    if(event.key === 'x' || event.key === 'X'){
                        this.removeLastNote(clef);
                    } else if(event.key === 'w' || event.key === 'W'){
                        this.currPitch = 1;
                    } else if(event.key === 's' || event.key === 'S'){
                        this.currPitch = 2;
                    } else if(event.key === 'a' || event.key === 'A'){
                        this.currPitch = -1;
                    } else if(event.key === 'd' || event.key === 'D'){
                        this.currPitch = -2;
                    } else if(event.key === 'q' || event.key === 'Q'){
                        this.currPitch = 0;
                    }

                    ctx2.clearRect(0, 0, canvas.width, canvas.height);
                }
                // bufferContext.clearRect(0, 0, canvas.width, canvas.height);
                this.drawStaffLines(ctx2);
                this.refreshNotes(ctx2, clef);
                if(staffElement instanceof StaffInterval){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNote1, staffElement, clef);
                } else if(staffElement instanceof StaffChord || staffElement instanceof StaffScale){
                    this.highlightMouseOver(ctx2, mouseY, staffElement.getNotes[0], staffElement, clef);
                }
                if(clef){
                    this.drawTrebleClef(ctx2);
                } else{
                    this.drawBassClef(ctx2);
                }

                if(this.context != null){
                    this.context.clearRect(0, 0, canvas.width, canvas.height);
                    this.context.drawImage(canvas2, 0, 0);
                } else {
                    throw Error("Context failed to initialize");
                }
                   
            } else {
                throw Error("Secondary context failed to initialize");
            }
    
        } else{
            return;
        }
        

    }


    //Event listener for the interval building click event
    public setClickEventListener(canvas: HTMLCanvasElement, clef: boolean, staffElement: any, event: MouseEvent, mouseY: number): void{
        if(this.clickEnabled){
            const canvas2: HTMLCanvasElement = document.createElement('canvas');
            canvas2.width = this.canvas.width;
            canvas2.height = this.canvas.height;
            const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
            if(ctx2 != null){
                //Set the new staff element on the canvas
                if(staffElement instanceof StaffInterval){
                    this.setNoteOnStaff(mouseY, clef, staffElement.getNote1.getYPos, staffElement);
                } else if(staffElement instanceof StaffChord || staffElement instanceof StaffScale){
                    this.setNoteOnStaff(mouseY, clef, staffElement.getNotes[0].getYPos, staffElement);
                }
                this.refreshNotes(ctx2, clef);
                this.drawStaffLines(ctx2);
    
                if(clef){
                    this.drawTrebleClef(ctx2);
                } else{
                    this.drawBassClef(ctx2);
                }
                this.currPitch = 0; //Reset the pitch after note submission
                
                if(this.context != null){ 
                    this.context.clearRect(0, 0, canvas.width, canvas.height);
                    this.context.drawImage(canvas2, 0, 0);
                }
            } else{
                throw Error("Secondary context failed to initialize");
            }

        } else{
            return;
        }
        
        

    }

    //Draws the staff lines
    private drawStaffLines(context: CanvasRenderingContext2D): void{
        context.fillStyle = 'black'; 
        context.fillRect(this.lineX, this.line1Y, this.lineWidth, this.lineHeight); 
        context.fillRect(this.lineX, this.line2Y, this.lineWidth, this.lineHeight); 
        context.fillRect(this.lineX, this.line3Y, this.lineWidth, this.lineHeight); 
        context.fillRect(this.lineX, this.line4Y, this.lineWidth, this.lineHeight); 
        context.fillRect(this.lineX, this.line5Y, this.lineWidth, this.lineHeight); 
        context.fillRect(this.lineX, this.line1Y, 20, 80);
        
    }

    //Draws treble clef image
    private drawTrebleClef(context: CanvasRenderingContext2D): void{
        const x: number = 10;
        const y: number = 92;
        const w: number = 125;
        const h: number = 125;
        if (this.trebleClefImage.complete) {
            // If the image is already loaded, draw it immediately
            context.drawImage(this.trebleClefImage, x, y, w, h);
        } else {
            this.trebleClefImage.onload = () =>{
                context.drawImage(this.trebleClefImage, x, y, w, h);
            }
        }
        
    }

    private drawBassClef(context: CanvasRenderingContext2D): void{
        const xPos: number = 40;
        const yPos: number = 110;
        const width: number = 65;
        const height: number = 55;
        if (this.bassClefImage.complete) {
            // If the image is already loaded, draw it immediately
            context.drawImage(this.bassClefImage, xPos, yPos, width, height);
        } else {
            this.bassClefImage.onload = () =>{
                context.drawImage(this.bassClefImage, xPos, yPos, width, height);
            }
        }
    }

    //Refreshes the notes for the note game
    private refreshNotes(context: CanvasRenderingContext2D, clef: boolean): void{
        let noteDisplacementX: number = 0;
        const noteDisplacementY: number = 54;

        let sharpDisplacementX: number = 0;
        let flatDisplacementX: number = 0;
        let doubleSharpDisplacementX: number = 0;
        let doubleFlatDisplacementX: number = 0;

        let sharpDisplacementY: number = 0;
        let flatDisplacementY: number = 0;
        let doubleSharpDisplacementY: number = 0;
        let doubleFlatDisplacementY: number = 0;

        let symbolDisplacement: number = 0;

        let x: number = this.canvas.width / 6; 
        const increment: number = Math.floor(((5 * (x) - (x)) / this.drawnNotes.length) + 10);

        let isNeighboringSymbol: boolean = false;
        let isNeighboringNote: boolean = false;
        let noteCount: number = 0;
        for (let i = 0; i < this.drawnNotes.length; i++) {
            //Draws the note onto the canvas
            const staffNote: StaffNote = this.drawnNotes[i];
            const root: string = staffNote.getNote.getRoot;

            //Draw inverted root note for interval games
            if(this.gameId === GameId.StaffBuilding_Intervals || this.gameId === GameId.StaffIdentification_Intervals){
                if(i === 0){
                    this.drawImage(this.blackNoteImage_rotated, context, this.noteDisplacementX, staffNote.getYPos - 14);
                } else {
                    //If the distance between the neighboring notes is 10, modify the X displacement
                    const lastNote = this.drawnNotes[i - 1];
                    const lastNoteRoot = lastNote.getNote.getRoot;
                    const distance = Math.abs(staffNote.getYPos - lastNote.getYPos);
                    if(distance <= 10){
                        noteDisplacementX = 25;
                    }
                    
                    if((distance <= 40 && StaffUtil.isFlat(lastNoteRoot)) || (distance <= 30 && StaffUtil.isSharp(lastNoteRoot))){
                        symbolDisplacement = 25;
                    }
                    
                    this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos - noteDisplacementY);
                }
                this.drawExtendedLines(staffNote, context, this.shortLineX + 5);

                //Draw notes for chord game
            } else if(this.gameId === GameId.StaffBuilding_Chords || this.gameId === GameId.StaffIdentification_Chords){        
                if(i === 0){
                    if(StaffUtil.isPitchModified(root)){
                        this.currentDisplacementX = 25;
                    } else {
                        this.currentDisplacementX = 0;
                    }
                    this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos - noteDisplacementY);
                } else {
                    //If the distance between the neighboring notes is 10, modify the X displacement
                    const lastNote: StaffNote = this.drawnNotes[i - 1];
                    const lastNoteRoot: string = lastNote.getNote.getRoot;
                    const distance: number = Math.abs(staffNote.getYPos - lastNote.getYPos);
                    if(distance <= 10 && noteCount % 2 === 0){
                        noteDisplacementX = 25;
                        isNeighboringNote = !isNeighboringNote;
                    }
                    noteCount++;

                    if(distance > 40){
                        symbolDisplacement = 0;
                        this.currentDisplacementX = 0;

                    } else if(isNeighboringSymbol){ 
                        symbolDisplacement = 0;
                        this.currentDisplacementX = 0;
                        isNeighboringSymbol = !isNeighboringSymbol;
                    }
                    else{
                        if(StaffUtil.isPitchModified(lastNoteRoot)){
                            symbolDisplacement = 25;
                            this.currentDisplacementX = 25;
                            isNeighboringSymbol = !isNeighboringSymbol;
                        } 
                    }

                    this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos - noteDisplacementY);
                }
                this.drawExtendedLines(staffNote, context, this.shortLineX + 5);
                
            //Draw notes for scale game
            } else if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
                x = (i * 80) + 150;
                this.drawScaleNote(context, x, staffNote);
            } else if(this.gameId === GameId.StaffIdentification_Notes){
                this.drawImage(this.blackNoteImage, context, this.noteDisplacementX + noteDisplacementX, staffNote.getYPos - noteDisplacementY);
                this.drawExtendedLines(staffNote, context, this.shortLineX + 5);
            }

            //Calculate displacement values for scale game
            if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
                sharpDisplacementX = x - this.sharpDisplacementX - 5;
                flatDisplacementX = x - this.flatDisplacementX - 5;
                doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
                doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
            } else{
                sharpDisplacementX = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacement;
                flatDisplacementX = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacement;
                doubleSharpDisplacementX = this.noteDisplacementX - this.doubleSharpDisplacementX - symbolDisplacement;
                doubleFlatDisplacementX = this.noteDisplacementX - this.doubleFlatDisplacementX - symbolDisplacement;
            }

            sharpDisplacementY = staffNote.getYPos + this.sharpDisplacementY;
            flatDisplacementY = staffNote.getYPos + this.flatDisplacementY;
            doubleSharpDisplacementY = staffNote.getYPos + this.doubleSharpDisplacementY;
            doubleFlatDisplacementY = staffNote.getYPos + this.doubleFlatDisplacementY;

            if(StaffUtil.isFlat(root)){
                context.font = this.flatFont;
                context.fillText(this.flatSymbol, flatDisplacementX, flatDisplacementY);
            } else if(StaffUtil.isSharp(root)){
                context.font = this.sharpFont;
                context.fillText(this.sharpSymbol, sharpDisplacementX, sharpDisplacementY);
            } else if(StaffUtil.isDoubleFlat(root)){
                context.font = this.doubleFlatFont;
                context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, doubleFlatDisplacementY);
            }  else if(StaffUtil.isDoubleSharp(root)){
                context.font = this.doubleSharpFont;
                context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, doubleSharpDisplacementY);
            } else {
                x -= 15;
            }

            x += increment;
            noteDisplacementX = 0;

        }
    }

    //Draws the scale note 
    private drawScaleNote(context: CanvasRenderingContext2D, x: number, staffNote: StaffNote, fontColor?: string): void{
        context.font = this.wholeNoteFont;
        if(fontColor != null){
            context.fillStyle = fontColor;
        }

        context.save();
        context.translate(x, staffNote.getYPos);
        context.rotate(175 * Math.PI / 180);
        context.fillText(this.wholeNoteSymbol, -32, 7); 
        context.restore();
        this.drawExtendedLines(staffNote, context, x - 6);
    }

    //Removes the last note from the canvas, with the exception of the first note
    public removeLastNote(clef: boolean): void{
        if(this.drawnNotes.length > 1){
            //Decrement pitch count if the note to be removed is pitch modified
            if(StaffUtil.isPitchModified(this.drawnNotes[this.drawnNotes.length - 1].getNote.getRoot)){
                this.pitchCount -= 1;
            }
            this.drawnNotes.pop();
            this.noteCount--;
            
            
            //Redraw the staff
            this.redrawStaff(clef);
        }
    }

    private redrawStaff(clef: boolean): void{
        //Draw the staff after populating the note array
        const canvas2: HTMLCanvasElement = document.createElement('canvas');
        canvas2.width = this.canvas.width;
        canvas2.height = this.canvas.height;
        const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;
        if(ctx2 != null){
            this.refreshNotes(ctx2, clef);
            this.drawStaffLines(ctx2);
            
            if(clef){
                this.drawTrebleClef(ctx2);
            } else{
                this.drawBassClef(ctx2);
            }
        }

        if(this.context != null){
            this.context.drawImage(canvas2, 0, 0);
        }
    }

    //Draws extended staff lines
    private drawExtendedLines(note: StaffNote, context: CanvasRenderingContext2D, x: number): void{
        //Draw extra lines below
        if(note.getYPos === 210 || note.getYPos === 220){
            context.fillRect(x, 210, this.shortLineWidth, this.shortLineHeight);
        } else if(note.getYPos === 230 || note.getYPos === 240){
            context.fillRect(x, 210, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(x, 230, this.shortLineWidth, this.shortLineHeight);
        } else if(note.getYPos === 250){ 
            context.fillRect(x, 210, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(x, 230, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(x, 250, this.shortLineWidth, this.shortLineHeight);
        }

        //Draw extra lines above
        if(note.getYPos == 90){
            context.fillRect(x, 90, this.shortLineWidth, this.shortLineHeight);
        } else if(note.getYPos == 80 || note.getYPos == 70 || note.getYPos == 60){
            context.fillRect(x, 90, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(x, 70, this.shortLineWidth, this.shortLineHeight);
        } else if(note.getYPos == 50){ 
            context.fillRect(x, 90, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(x, 70, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(x, 50, this.shortLineWidth, this.shortLineHeight);
        }
    }

    //Adds gray note over canvas position where mouse is hovering
    private highlightMouseOver(context: CanvasRenderingContext2D, mouseY: number, note: StaffNote, element: StaffElement, clef: boolean): void{                
        let centerY: number = 0;
        let lineDisplacementX: number = this.shortLineX + 5;
        let scaleNoteDisplacementX: number = 0;
        const noteY: number = note.getYPos;
        let isSpace: boolean = true;
        let isLine: boolean = true;
        let minY: number = 0;
        let maxY: number = 1000;
        

        //Add note selection restraints for chord and scale building games
        if(element instanceof StaffChord){
            if(noteY % 20 === 10){
                isSpace = false;
                this.offset = 10;
            }else{
                isLine = false;
                this.offset = 0;
            }
            minY = 10;
            maxY = 30;
            if(mouseY > this.drawnNotes[this.drawnNotes.length - 1].getYPos - minY) return;

        } else if(element instanceof StaffScale){
            let length: number = this.drawnNotes.length;
            scaleNoteDisplacementX = (length * 80) + 150;
            lineDisplacementX = scaleNoteDisplacementX - 6;

            minY = -7;
            maxY = 20;
            if(this.drawnNotes[this.drawnNotes.length - 1].getYPos % 20 === 0){
                minY = -7;
            } else{     
                minY = -3;
            }

            this.currNote = this.createNoteFromStaffPosition(mouseY, noteY, clef);
            

        }
        const length: number = this.drawnNotes.length;
        
        //Check if the click is between lines 3 above and 2 above
        if (mouseY > this.lineAbove3Y + this.offset  && mouseY < this.lineAbove2Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.lineAbove3Y + this.lineAbove2Y) / 2;
            context.fillRect(lineDisplacementX, this.lineAbove3Y, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(lineDisplacementX, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
            
        }
        
        //Check if the click is between lines 2 above and 1 above
        else if (mouseY > this.lineAbove2Y + this.offset  && mouseY < this.lineAbove1Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.lineAbove2Y + this.lineAbove1Y) / 2;
            context.fillRect(lineDisplacementX, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
        }

        //Check if the click is between lines 1 above and 1
        else if (mouseY > this.lineAbove1Y + this.offset  && mouseY < this.line1Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.lineAbove1Y + this.line1Y) / 2;
            context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);
        }

        // Check if the click is between lines 1 and 2
        else if (mouseY > this.line1Y + this.offset  && mouseY < this.line2Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.line2Y + this.line1Y) / 2;
        }

        // Check if the click is between lines 2 and 3
        else if (mouseY > this.line2Y + this.offset  && mouseY < this.line3Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.line3Y + this.line2Y) / 2;
        }

        // Check if the click is between lines 3 and 4
        else if (mouseY > this.line3Y + this.offset  && mouseY < this.line4Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.line4Y + this.line3Y) / 2;
        }

        // Check if the click is between lines 4 and 5
        else if (mouseY > this.line4Y + this.offset  && mouseY < this.line5Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.line5Y + this.line4Y) / 2;
        }

        //Check for clicks of below lines
        else if (mouseY > this.line5Y + this.offset  && mouseY < this.lineBelow1Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.line5Y + this.lineBelow1Y) / 2;
            context.fillRect(lineDisplacementX, this.line5Y, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(lineDisplacementX, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
        }
        
        else if (mouseY > this.lineBelow1Y + this.offset  && mouseY < this.lineBelow2Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.lineBelow1Y + this.lineBelow2Y) / 2;
            context.fillRect(lineDisplacementX, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(lineDisplacementX, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);
        }

        else if (mouseY > this.lineBelow2Y + this.offset  && mouseY < this.lineBelow3Y - this.offset && mouseY < noteY - 10 && isSpace) {
            centerY = (this.lineBelow2Y + this.lineBelow3Y) / 2;
            context.fillRect(lineDisplacementX, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);
            context.fillRect(lineDisplacementX, this.lineBelow3Y, this.shortLineWidth, this.shortLineHeight);
        }

        // Check if the click is on a line
        else if (mouseY >= this.lineAbove3Y - this.offset && mouseY <= this.lineAbove3Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.lineAbove3Y;
            context.fillRect(lineDisplacementX, this.lineAbove3Y, this.shortLineWidth, this.shortLineHeight);
            
        }
        else if (mouseY >= this.lineAbove2Y - this.offset && mouseY <= this.lineAbove2Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.lineAbove2Y;
            context.fillRect(lineDisplacementX, this.lineAbove2Y, this.shortLineWidth, this.shortLineHeight );

        }
        else if (mouseY >= this.lineAbove1Y - this.offset && mouseY <= this.lineAbove1Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.lineAbove1Y;
            context.fillRect(lineDisplacementX, this.lineAbove1Y, this.shortLineWidth, this.shortLineHeight);

        }
        else if (mouseY >= this.line1Y - this.offset && mouseY <= this.line1Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.line1Y;
        }
        else if (mouseY >= this.line2Y - this.offset && mouseY <= this.line2Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.line2Y;
        }
        else if (mouseY >= this.line3Y - this.offset && mouseY <= this.line3Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.line3Y;
        }
        else if (mouseY >= this.line4Y - this.offset && mouseY <= this.line4Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.line4Y;
        }
        else if (mouseY >= this.line5Y - this.offset && mouseY <= this.line5Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.line5Y;
        }

        else if (mouseY >= this.lineBelow3Y - this.offset && mouseY <= this.lineBelow3Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.lineBelow3Y;
            context.fillRect(lineDisplacementX, this.lineBelow3Y, this.shortLineWidth, this.shortLineHeight);
            
        }
        else if (mouseY >= this.lineBelow2Y - this.offset && mouseY <= this.lineBelow2Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.lineBelow2Y;
            context.fillRect(lineDisplacementX, this.lineBelow2Y, this.shortLineWidth, this.shortLineHeight);

        }
        else if (mouseY >= this.lineBelow1Y - this.offset && mouseY <= this.lineBelow1Y + this.offset && mouseY < noteY - 10 && isLine){
            centerY = this.lineBelow1Y;
            context.fillRect(lineDisplacementX, this.lineBelow1Y, this.shortLineWidth, this.shortLineHeight);

        }
        this.offset = 3;

        //Check to make sure that the Y value was updated.  If so, proceed to draw note and symbol
        if(centerY !== 0){
            let symbolDisplacementX: number = 0;
            let noteDisplacementX: number = 0;
            let resetDisplacement: boolean = false;

            //Adjust displacement based on symbol of the root note
            if(element instanceof StaffChord){
                const root1: string = this.drawnNotes[this.drawnNotes.length - 1].getNote.getRoot;
                let root2: string = "";
                if(this.drawnNotes.length >= 2){
                    root2 = this.drawnNotes[this.drawnNotes.length - 2].getNote.getRoot;
                }
                //Reset symbol displacement for 11th and 13th chords
                if(this.drawnNotes.length >= 4){
                    if(StaffUtil.isPitchModified(root1) && StaffUtil.isPitchModified(root2)){
                        resetDisplacement = true;
                    }
                }

                if(this.currentDisplacementX === 25){
                    symbolDisplacementX = 0;
                } else {
                    symbolDisplacementX = 25;
                }
                if(StaffUtil.isPitchModified(root1)){
                    if(!StaffUtil.isPitchModified(root2)){
                        symbolDisplacementX = 25;
                    } else {
                        symbolDisplacementX = this.pitchCount % 2 === 1 ? 25 : 0;
                    }
                } else {
                    symbolDisplacementX = 0;
                }

                if(resetDisplacement){
                    symbolDisplacementX = 0
                }
            } else if(element instanceof StaffInterval){
                if(StaffUtil.isFlat(note.getNote.getRoot)){
                    if(Math.abs(centerY - noteY) < 50){
                        symbolDisplacementX = 25;
                    } else {
                        symbolDisplacementX = 0;
                    }
                } else if(StaffUtil.isSharp(note.getNote.getRoot)){
                    if(Math.abs(centerY - noteY) < 40){
                        symbolDisplacementX = 25;
                    } else {
                        symbolDisplacementX = 0;
                    }
                } 
            }
            
            //Displace note to the right for 2nd intervals
            if(Math.abs(centerY - noteY) < 20){
                noteDisplacementX = 25;
            }

            let sharpDisplacementX: number = this.noteDisplacementX - this.sharpDisplacementX - symbolDisplacementX;
            let flatDisplacementX: number = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacementX;
            let doubleSharpDisplacementX: number = this.noteDisplacementX - this.doubleSharpDisplacementX - symbolDisplacementX;
            let doubleFlatDisplacementX: number = this.noteDisplacementX - this.doubleFlatDisplacementX - symbolDisplacementX;

            const sharpDisplacementY: number = centerY + this.sharpDisplacementY;
            const flatDisplacementY: number = centerY + this.flatDisplacementY;
            const doubleSharpDisplacementY: number = centerY + this.doubleSharpDisplacementY;
            const doubleFlatDisplacementY: number = centerY + this.doubleFlatDisplacementY;

            let isInvalid: boolean = false;
            let isOutOfBounds: boolean = false;
            if(element instanceof StaffChord){
                isOutOfBounds = this.checkForNoteIsOutOfBounds(mouseY);
            }
            else if(element instanceof StaffScale){
                sharpDisplacementX = scaleNoteDisplacementX - this.sharpDisplacementX - 5;
                flatDisplacementX = scaleNoteDisplacementX - this.flatDisplacementX - 5;
                doubleSharpDisplacementX = scaleNoteDisplacementX - this.doubleSharpDisplacementX;
                doubleFlatDisplacementX = scaleNoteDisplacementX - this.doubleFlatDisplacementX;

                isInvalid = this.checkForNoteIsInvalid(mouseY, length);
            }

            //Change fill color for invalid notes
            context.fillStyle = isInvalid || isOutOfBounds ? 'red' : 'grey';

            if(this.currPitch === -1){
                context.font = this.flatFont;
                context.fillText(this.flatSymbol, flatDisplacementX, flatDisplacementY);
            } else if(this.currPitch === 1){
                context.font = this.sharpFont;
                context.fillText(this.sharpSymbol, sharpDisplacementX, sharpDisplacementY);
    
            } else if(this.currPitch === -2){
                context.font = this.doubleFlatFont;
                context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, doubleFlatDisplacementY);
            }  else if(this.currPitch === 2){
                context.font = this.doubleSharpFont;
                context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, doubleSharpDisplacementY);
            }

            //Adjust image, x, and y values for scale building game
            let image: HTMLImageElement = isOutOfBounds ? this.redNoteImage : this.greyNoteImage;
            let x: number = this.shortLineX + noteDisplacementX;
            let y: number = centerY - 54;
            if(scaleNoteDisplacementX !== 0){
                image = this.wholeNoteImage;
                x = scaleNoteDisplacementX;
                y = centerY - 9;
                context.font = this.wholeNoteFont;
                context.save();
                context.translate(x, y);
                context.rotate(175 * Math.PI / 180);
                context.fillText(this.wholeNoteSymbol, -32, -2); 
                context.restore();
            } else{
                this.drawImage(image, context, x, y);
                
            }

            //Reset the fill style
            context.fillStyle = 'black';

        }

    }

    //Places a note on the staff
    public setNoteOnStaff(mouseY: number, clef: boolean, noteY: number, element: StaffElement): void{
        let minY: number = 0;
        let maxY: number = 1000;
        let scaleNoteDisplacementX: number = 0;
        let lineDisplacementX: number = 0;

        //Add note selection restraints for chord and scale building games
        if(element instanceof StaffChord){
            if(noteY % 20 === 10){
                this.offset = 10;
            }else{
                this.offset = 0;
            }
    
            const isOutOfBounds: boolean = this.checkForNoteIsOutOfBounds(mouseY);
            if(isOutOfBounds) return;

        } else if(element instanceof StaffScale){
            let length: number = this.drawnNotes.length;
            scaleNoteDisplacementX = (length * 80) + 150;
            lineDisplacementX = scaleNoteDisplacementX - 6;
          
            minY = 5;
            maxY = 20;
            if(this.drawnNotes[this.drawnNotes.length - 1].getYPos % 20 === 0){
                minY = -7;
            } else{
                minY = -3;
            }

            const isInvalid: boolean = this.checkForNoteIsInvalid(mouseY, length);
            if(isInvalid) return;
        }

        //Set temporary pitch and update the total note count
        this.noteCount++;
        
        //Create the staff note based on coordiante positioning
        const staffNote: StaffNote = this.createNoteFromStaffPosition(mouseY, noteY, clef);
        this.offset = 3;

        //Check that a note was populated, otherwise do not push it to drawn notes
        if(staffNote.getFullName != "A0"){
            this.drawnNotes.push(staffNote);
        }

    }

    private createNoteFromStaffPosition(mouseY: number, noteY: number, clef: boolean): StaffNote{
        let letter: string = "A";
        let symbol: string = "";
        let octave: number = 0;

        //Sets the symbol based on the current selected pitch
        switch(this.currPitch){
            case -2:
                symbol = "bb";
                break;
            case -1:
                symbol = "b";
                break;
            case 0:
                symbol = "";
                break;
            case 1: 
                symbol = "#";
                break;
            case 2:
                symbol = "x";
                break;
        }

        // Check if the click is between lines 3 above and 2 above
        if (mouseY > this.lineAbove3Y + this.offset && mouseY < this.lineAbove2Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "D";
                octave = 6;
            } else{
                letter = "F";
                octave = 4;
            }
        } 
        //Check if the click is between lines 2 above and 1 above
        else if (mouseY > this.lineAbove2Y + this.offset  && mouseY < this.lineAbove1Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "B";
                octave = 5;
            } else{
                letter = "D";
                octave = 4;
            }
        }

        //Check if the click is between lines 1 above and 1
        else if (mouseY > this.lineAbove1Y + this.offset  && mouseY < this.line1Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "G";
                octave = 5;
            } else{
                letter = "B";
                octave = 3;
            }
        }

        // Check if the click is between lines 1 and 2
        else if (mouseY > this.line1Y + this.offset  && mouseY < this.line2Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "E";
                octave = 5;
            } else{
                letter = "G";
                octave = 3;
            }

        }

        // Check if the click is between lines 2 and 3
        else if (mouseY > this.line2Y + this.offset  && mouseY < this.line3Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "C";
                octave = 5;
            } else{
                letter = "E";
                octave = 3;
            }
        }

        // Check if the click is between lines 3 and 4
        else if (mouseY > this.line3Y + this.offset  && mouseY < this.line4Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "A";
                octave = 4;
            } else{
                letter = "C";
                octave = 3;
            }
        }

        // Check if the click is between lines 4 and 5
        else if (mouseY > this.line4Y + this.offset  && mouseY < this.line5Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "F";
                octave = 4;
            } else{
                letter = "A";
                octave = 2;
            }
        }

        //Check for clicks of below lines
        else if (mouseY > this.line5Y + this.offset  && mouseY < this.lineBelow1Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "D";
                octave = 4;
            } else{
                letter = "F";
                octave = 2;
            }
        }
        
        else if (mouseY > this.lineBelow1Y + this.offset  && mouseY < this.lineBelow2Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "B";
                octave = 3;
            } else{
                letter = "D";
                octave = 2;
            }

        }

        else if (mouseY > this.lineBelow2Y + this.offset  && mouseY < this.lineBelow3Y - this.offset && mouseY < noteY - 10) {
            if(clef){
                letter = "G";
                octave = 3;
            } else{
                letter = "B";
                octave = 1;
            }
        }

        // Check if the click is on a line
        else if (mouseY >= this.lineAbove3Y - this.offset && mouseY <= this.lineAbove3Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "E";
                octave = 6;
            } else{
                letter = "G";
                octave = 4;
            }
        }
        else if (mouseY >= this.lineAbove2Y - this.offset && mouseY <= this.lineAbove2Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "C";
                octave = 6;
            } else{
                letter = "E";
                octave = 4;
            }
        }
        else if (mouseY >= this.lineAbove1Y - this.offset && mouseY <= this.lineAbove1Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "A";
                octave = 5;
            } else{
                letter = "C";
                octave = 4;
            }
        }
        else if (mouseY >= this.line1Y - this.offset && mouseY <= this.line1Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "F";
                octave = 5;
            } else{
                letter = "A";
                octave = 3;
            }
        }
        else if (mouseY >= this.line2Y - this.offset && mouseY <= this.line2Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "D";
                octave = 5;
            } else{
                letter = "F";
                octave = 3;
            }
        }
        else if (mouseY >= this.line3Y - this.offset && mouseY <= this.line3Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "B";
                octave = 4;
            } else{
                letter = "D";
                octave = 3;
            }
        }
        else if (mouseY >= this.line4Y - this.offset && mouseY <= this.line4Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "G";
                octave = 4;
            } else{
                letter = "B";
                octave = 2;
            }
        }
        else if (mouseY >= this.line5Y - this.offset && mouseY <= this.line5Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "E";
                octave = 4;
            } else{
                letter = "G";
                octave = 2;
            }
        }

        else if (mouseY >= this.lineBelow3Y - this.offset && mouseY <= this.lineBelow3Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "F";
                octave = 3;
            } else{
                letter = "A";
                octave = 1;
            }
        }
        else if (mouseY >= this.lineBelow2Y - this.offset && mouseY <= this.lineBelow2Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "A";
                octave = 3;
            } else{
                letter = "C";
                octave = 2;
            }
        }
        else if (mouseY >= this.lineBelow1Y - this.offset && mouseY <= this.lineBelow1Y + this.offset && mouseY < noteY - 10){
            if(clef){
                letter = "C";
                octave = 4;
            } else{
                letter = "E";
                octave = 2;
            }
        }


        //Construct the note name, create the staff note to be pushed to the drawn notes array
        const noteName: string = letter + symbol + octave;
        const note: Note = new Note(noteName, clef);
        const staffNote: StaffNote = new StaffNote(note);

        //Update pitch count for alternating pitch modifier symbols
        if(StaffUtil.isPitchModified(note.getRoot)){
            this.pitchCount += 1;
        }
        return staffNote;
    }

    //Checks to see if a note is invalid in the staff building games
    private checkForNoteIsInvalid(mouseY: number, length: number): boolean{
        const lastNote: StaffNote = this.drawnNotes[length - 1];

        let displacement: number = 0;
        if(lastNote.getYPos % 20 === 0){
            displacement = 7;
        } else{ 
            displacement = 3;
        }

        if(this.currNote.getNote.getFrequency <= lastNote.getNote.getFrequency){
            return true;
        }
        if(Math.abs(mouseY - lastNote.getYPos) >= 20 + displacement){
            return true;
        }

        return false;
    }

    //Checks to see if a note is out of bounds
    private checkForNoteIsOutOfBounds(mouseY: number): boolean{
        const minY: number = 10;
        const maxY: number = 30;
        const noteY: number = this.drawnNotes[this.drawnNotes.length - 1].getYPos;

        return (mouseY > noteY - minY) || (mouseY < noteY - maxY);
    }

    //Handles the click event and returns a response to the game handler
    public evaluateNoteSelection(gameId: GameId, clef: boolean): StaffElement{
        let res: StaffElement;
        if(gameId === GameId.StaffBuilding_Intervals){
            const note1: Note = this.drawnNotes[0].getNote;
            const note2: Note = this.drawnNotes[1].getNote;
            let interval: Interval = new Interval(note1, 0, clef, note2);
            let staffInterval: StaffInterval = new StaffInterval(interval);
            res = staffInterval;
        } else if(gameId === GameId.StaffBuilding_Chords){
            let intervals: number[] = [];
            for(let i = 0; i < this.drawnNotes.length - 1; i++){
                const note1: Note = this.drawnNotes[i].getNote;
                const note2: Note = this.drawnNotes[i+1].getNote;
                const interval: Interval = new Interval(note1, 0, clef, note2);
                intervals.push(interval.getNumberOfSteps());
            }
            const chord: Chord = new Chord(this.drawnNotes[0].getNote, ChordQuality.Null, intervals);
            const staffChord: StaffChord = new StaffChord(chord);
            res = staffChord;
            
        } else if(gameId === GameId.StaffBuilding_Scales) {
            let intervals: number[] = [];
            
            for(let i = 0; i < this.drawnNotes.length - 1; i++){
                const note1: Note = this.drawnNotes[i].getNote;
                const note2: Note = this.drawnNotes[i+1].getNote;
                const interval: Interval = new Interval(note1, 0, clef, note2);
                intervals.push(interval.getNumberOfSteps());
            }
            const scale: Scale = new Scale(ScaleQuality.NULL, this.drawnNotes[0].getNote, intervals);
            const staffScale: StaffScale = new StaffScale(scale);
            res = staffScale;
        } else {
            //Return arbitrary note in case of type failure
            res = new StaffNote(new Note("A0", true));
        }

        return res;

        
    }

    //If user gets a wrong answer in the game handler, adjust the note array so that they can
    //try again
    public resetNoteArray(staffElement: StaffElement, isTreble: boolean): boolean{
        this.currPitch = 0;
        this.currNote = this.drawnNotes[this.drawnNotes.length - 1];

        // Refresh the buffer
        const canvas2: HTMLCanvasElement = document.createElement('canvas');
        canvas2.width = this.canvas.width;
        canvas2.height = this.canvas.height;
        const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;

        if(ctx2 != null){
            if(staffElement instanceof StaffNote || staffElement instanceof StaffInterval){
                //Redraw the staff for notes and intervals
                this.drawSimpleStaff(staffElement, isTreble);
                this.hoverEnabled, this.clickEnabled = false;
                if(this.context != null){
                    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.context.drawImage(canvas2, 0, 0);
                    this.drawSimpleStaff(staffElement, isTreble);
                } else {
                    throw Error("Context failed to initialize");
                }
            } else if(staffElement instanceof StaffChord){
                //Determine the number of notes that are allowed at any given time
                let lowerBound: number = 2;
                if(staffElement.getQuality >= 13 && staffElement.getQuality <= 29){
                    lowerBound = 3;
                } else if(staffElement.getQuality >= 30 && staffElement.getQuality <= 54){
                    lowerBound = 4;
                } else if(staffElement.getQuality >= 55 && staffElement.getQuality <= 74){
                    lowerBound = 5;
                } else if(staffElement.getQuality >= 75){
                    lowerBound = 6;
                }

                if(StaffUtil.isPitchModified(staffElement.getNotes[0].getNote.getRoot)){
                    this.pitchCount = 1;
                } else {
                    this.pitchCount = 0;
                }

                //Enforce lower bound value and redraw the staff
                if(staffElement.getNotes.length > lowerBound && this.context != null){
                    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.context.drawImage(canvas2, 0, 0);
                    this.drawSimpleStaff(staffElement, isTreble);
                }
            } else if(staffElement instanceof StaffScale){
                //Redraw the staff for scales
                // this.drawSimpleStaff(staffElement, isTreble);
                this.hoverEnabled, this.clickEnabled = false;
                if(this.context != null){
                    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                    this.context.drawImage(canvas2, 0, 0);
                    this.drawSimpleStaff(staffElement, isTreble);
                } else {
                    throw Error("Context failed to initialize");
                }
    
            }
            this.noteCount = 1;
            
        } else {
            throw Error("Secondary context failed to initialize");
        }
        
        //Reenable hover and click events
        this.hoverEnabled, this.clickEnabled = true;
        return true; 
    }

    //For staff building games, change the note color depending on if the answer is right or wrong
    public changeNoteColor(staffElement: StaffElement, clef: boolean, isCorrect: boolean): void{
        let noteDisplacementX: number = 0;
        let canvas2: HTMLCanvasElement = document.createElement('canvas');

        //Include note displacement for interval elements
        if(staffElement instanceof StaffInterval){
            const y1: number = staffElement.getNote1.getYPos;
            const y2: number = staffElement.getNote2.getYPos;
            if(Math.abs(y1 - y2) < 20){
                noteDisplacementX = 25;
            }            
        }

        //Draw the new canvas to the buffer
        canvas2 = this.drawColoredNotes(staffElement, noteDisplacementX, isCorrect, clef);

        if(this.context != null){
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context.drawImage(canvas2, 0, 0);
        } else{
            throw Error("Context failed to initialize");
        }
     
    }

    //Draws the colored notes and returns the canvas
    private drawColoredNotes(staffElement: StaffElement, noteDisplacementX: number, isCorrect: boolean, isTreble: boolean): HTMLCanvasElement{
        const rotatedNoteDisplacementY: number = 14;

        let noteImage: HTMLImageElement = new Image();
        let rotatedNoteImage: HTMLImageElement = new Image();
        let fontColor: string = 'black';

        //Draw the colored notes to indicate correctness
        if(isCorrect){
            noteImage = this.greenNoteImage;
            rotatedNoteImage = this.greenNoteImage_rotated;
            fontColor = 'green';
        } else {
            noteImage = this.redNoteImage;
            rotatedNoteImage = this.redNoteImage_rotated;
            fontColor = 'red';
        }

        const canvas2: HTMLCanvasElement = document.createElement('canvas');
        canvas2.width = this.canvas.width;
        canvas2.height = this.canvas.height;
        const ctx2: CanvasRenderingContext2D = canvas2.getContext('2d') as CanvasRenderingContext2D;

        if(ctx2 != null){
            this.drawStaffLines(ctx2);
            if(staffElement instanceof StaffInterval){
                this.drawExtendedLines(this.drawnNotes[0], ctx2, this.shortLineX + 5);
                this.changeSymbolColor(ctx2, fontColor, staffElement, 0);
                this.drawImage(rotatedNoteImage, ctx2, this.noteDisplacementX, this.drawnNotes[0].getYPos - rotatedNoteDisplacementY);
                this.drawImage(noteImage, ctx2, this.noteDisplacementX + noteDisplacementX, this.drawnNotes[1].getYPos - this.noteDisplacementY);
            } else if(staffElement instanceof StaffChord){
                this.drawExtendedLines(this.drawnNotes[0], ctx2, this.shortLineX + 5);
                this.drawnNotes.forEach((note) => {
                    this.changeSymbolColor(ctx2, fontColor, staffElement, 0);
                    this.drawImage(noteImage, ctx2, this.noteDisplacementX + noteDisplacementX, note.getYPos - this.noteDisplacementY);
                });
            } else if(staffElement instanceof StaffScale){
                for(let i = 0; i < this.drawnNotes.length; i++){
                    let x: number = (i * 80) + 150;
                    const staffNote: StaffNote = this.drawnNotes[i];

                    this.changeSymbolColor(ctx2, fontColor, staffElement, x, i);
                    this.drawScaleNote(ctx2, x, staffNote, fontColor);
                }
            }
            

            if(isTreble){
                this.drawTrebleClef(ctx2);   
            } else{
                this.drawBassClef(ctx2);
            }

        } else {
            throw Error("Secondary context failed to initialize");
        }
        return canvas2;
    }

    //Draws the colored symbols depending on right or wrong selection for staff building games
    private changeSymbolColor(context: CanvasRenderingContext2D, fillColor: string, staffElement: StaffElement, x: number, i?: number): void{
        let symbolDisplacementX: number = 0;
        let sharpDisplacementX: number = 0;
        let flatDisplacementX: number = 0;
        let doubleSharpDisplacementX: number = 0;
        let doubleFlatDisplacementX: number = 0;

        context.fillStyle = fillColor; 

        //Iterate through each drawn note and adjust their symbol colors
        if(staffElement instanceof StaffScale && i != null){
            sharpDisplacementX = x - this.sharpDisplacementX - 5;
            flatDisplacementX = x - this.flatDisplacementX - 5;
            doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
            doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
            
            const note: StaffNote = this.drawnNotes[i];
            const root: string = note.getNote.getRoot;
            const y: number = note.getYPos;

            //Draw the subsequent symbols
            if(StaffUtil.isFlat(root)){
                context.font = this.flatFont;
                context.fillText(this.flatSymbol, flatDisplacementX, y + this.flatDisplacementY);
            } else if(StaffUtil.isSharp(root)){
                context.font = this.sharpFont;
                context.fillText(this.sharpSymbol, sharpDisplacementX, y + this.sharpDisplacementY);
            } else if(StaffUtil.isDoubleFlat(root)){
                context.font = this.doubleFlatFont;
                context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, y + this.doubleFlatDisplacementY);
            } else if(StaffUtil.isDoubleSharp(root)){
                context.font = this.doubleSharpFont;
                context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, y + this.doubleSharpDisplacementY);
            }
        } else {
            for(let i = 0; i < this.drawnNotes.length - 1; i++){
                const note1: StaffNote = this.drawnNotes[i];
                const note2: StaffNote = this.drawnNotes[i + 1];
                const root1: string = note1.getNote.getRoot;
                const root2: string = note2.getNote.getRoot;
                const y1: number = note1.getYPos;
                const y2: number = note2.getYPos;
    
                //Adjust the symbol displacement for the chord games
                if(staffElement instanceof StaffChord){
                    if(StaffUtil.isPitchModified(root1)){
                        symbolDisplacementX = symbolDisplacementX === 0 ? 25 : 0;
                    } else {
                        symbolDisplacementX = 0;
                    }
                } else if(staffElement instanceof StaffInterval){
                    if(StaffUtil.isPitchModified(root1)){
                        if((StaffUtil.isFlat(root2) || StaffUtil.isDoubleFlat(root2)) && Math.abs(y1 - y2) < 50){
                            symbolDisplacementX = 25;
                        } else if((StaffUtil.isSharp(root2) || StaffUtil.isDoubleSharp(root2)) && Math.abs(y1 - y2) < 40){
                            symbolDisplacementX = 25;
                        } else{
                            symbolDisplacementX = 0;
                        }
                    } else {
                        symbolDisplacementX = 0;
                    }
                }
    
                //Adjust the individual symbol displacements
                if(this.gameId === GameId.StaffBuilding_Scales || this.gameId === GameId.StaffIdentification_Scales){
                    sharpDisplacementX = x - this.sharpDisplacementX - 5;
                    flatDisplacementX = x - this.flatDisplacementX - 5;
                    doubleSharpDisplacementX = x - this.doubleSharpDisplacementX;
                    doubleFlatDisplacementX = x - this.doubleFlatDisplacementX;
                } else{
                    sharpDisplacementX = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacementX;
                    flatDisplacementX = this.noteDisplacementX - this.flatDisplacementX - symbolDisplacementX;
                    doubleSharpDisplacementX = this.noteDisplacementX - this.doubleSharpDisplacementX - symbolDisplacementX;
                    doubleFlatDisplacementX = this.noteDisplacementX - this.doubleFlatDisplacementX - symbolDisplacementX;
                }
    
                //Draw the first symbol of the element
                if(i === 0){
                    if(StaffUtil.isFlat(root1)){
                        context.font = this.flatFont;
                        context.fillText(this.flatSymbol, flatDisplacementX + symbolDisplacementX, y1 + this.flatDisplacementY);
                    } else if(StaffUtil.isSharp(root1)){
                        context.font = this.sharpFont;
                        context.fillText(this.sharpSymbol, sharpDisplacementX + symbolDisplacementX, y1 + this.sharpDisplacementY);
                    } else if(StaffUtil.isDoubleFlat(root1)){
                        context.font = this.doubleFlatFont;
                        context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX + symbolDisplacementX, y1 + this.doubleFlatDisplacementY);
                    } else if(StaffUtil.isDoubleSharp(root1)){
                        context.font = this.doubleSharpFont;
                        context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX + symbolDisplacementX, y1 + this.doubleSharpDisplacementY);
                    }
                }
    
                //Draw the subsequent symbols
                if(StaffUtil.isFlat(root2)){
                    context.font = this.flatFont;
                    context.fillText(this.flatSymbol, flatDisplacementX, y2 + this.flatDisplacementY);
                } else if(StaffUtil.isSharp(root2)){
                    context.font = this.sharpFont;
                    context.fillText(this.sharpSymbol, sharpDisplacementX, y2 + this.sharpDisplacementY);
                } else if(StaffUtil.isDoubleFlat(root2)){
                    context.font = this.doubleFlatFont;
                    context.fillText(this.doubleFlatSymbol, doubleFlatDisplacementX, y2 + this.doubleFlatDisplacementY);
                } else if(StaffUtil.isDoubleSharp(root2)){
                    context.font = this.doubleSharpFont;
                    context.fillText(this.doubleSharpSymbol, doubleSharpDisplacementX, y2 + this.doubleSharpDisplacementY);
                }
                
            }

        }
    }
    
    public drawImage(image: HTMLImageElement, context: CanvasRenderingContext2D, x: number, y: number): void{       
        if (image.complete) {
            // If the image is already loaded, draw it immediately
            context.drawImage(image, x, y);
            
        } else {
            // If the image is not loaded yet, set the onload event
            image.onload = () => {
                context.drawImage(image, x, y);
            };
        }

    }

    public drawRotatedImage(image: HTMLImageElement, context: CanvasRenderingContext2D, x: number, y: number): void{       
        context.save();
        if (image.complete) {
            // If the image is already loaded, draw it immediately
            context.translate(200, 200);
            context.rotate(180 * (Math.PI/180)); 
            context.drawImage(image, x, y);
            
        } else {
            // If the image is not loaded yet, set the onload event
            image.onload = () => {
                context.translate(200, 200);
                context.rotate(180 * (Math.PI/180)); 
                context.drawImage(image, x, y);
            };
        }
        context.restore();

    }

    get getDrawnNotes(): StaffNote[]{
        return this.drawnNotes;
    }


}