import { APIDataService } from "./APIDataService";

interface RequestOptions{
    method: string,
    headers: {
        'Content-Type': string;
        'X-API-KEY': string;
    };
    body: any;
}

//Makes API calls for retrieving user details, such as profile pictures, date joined, etc...
export class UserDataService extends APIDataService{

    //Returns the user profile details for the specified user
    public static async getUserProfileDetails(username: string): Promise<string>{
        const url: string = this.getAPIUrl()  + `/user/`+ username + "/profileDetails";
        const res: string = await this.get(url);
        return res;
    }

    //Sets a new profile picture for the user
    public static async setUserProfilePicture(img: Blob, username: string): Promise<string>{        
        let params: URLSearchParams = new URLSearchParams();
        params.set('username', username);
        const url: string = this.getAPIUrl() + "/user/setProfilePicture/" + username;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/octet-stream',
                'X-API-KEY': this.getAPIKey()
            },
            body: img
        }

        const res: string = await this.post(url, requestOptions);
        return res;
        
    }

    //Returns a list of all users in the database for the admin console
    public static async getUserList(): Promise<string>{
        const url: string = this.getAPIUrl() + "/user/listUsers";
        const res: string = await this.get(url);
        return res;
    }
}