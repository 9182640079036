import { ChangeDetectorRef, Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameDataService } from '../data-services/GameDataService';
import { FormsModule } from '@angular/forms'; 
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { CommonUtil } from '../util/CommonUtil';
import { AuthDataService } from '../data-services/AuthDataService';
import { SpinnerComponent } from "../graphics/spinner/spinner.component";
import { UnavailableComponent } from "../graphics/unavailable/unavailable.component";
import { GameData } from '../constants/GameData';

@Component({
    selector: 'app-leaderboard',
    standalone: true,
    templateUrl: './leaderboard.component.html',
    styleUrl: './leaderboard.component.css',
    imports: [CommonModule, MatFormFieldModule, FormsModule, MatSelectModule, SpinnerComponent, UnavailableComponent]
})
export class LeaderboardComponent implements OnInit {

    public records: GameData[] = [];
    public currentRecords: GameData[] = [];
    public recordCounter: number = 0;
    public rank: number = 0;
    public gameType: string;
    public gameTypeList: string[] = ["Ear Training - Chords", "Ear Training - Intervals", "Ear Training - Scales"];
    public difficulty: string = "1";
    public difficultyList: string[] = ["Easy", "Medium", "Hard"];

    public displayRecords: boolean = false;
    public rendered: boolean = false;
    public isLoading: boolean = true;

    public pageIndex: number = 0;

    constructor(private cdr: ChangeDetectorRef, protected renderer: Renderer2){
        this.gameType = '2';
        this.difficulty = '1';
    }

    async ngOnInit(): Promise<void> {
        //Test the connection 
        const connectionIsAvailable: boolean = await AuthDataService.testConnection();
        if(connectionIsAvailable){
            //Connection is available, so proceed to query for the records
            const json: string = await GameDataService.getLeaderboardRecords();
            this.records = JSON.parse(json);

            //Select the current records and display the page
            this.selectCurrentRecords();
            this.displayRecords = true;
        }
        this.rendered = true;     
   
    }

    //Creates a subset array that contains the records for the selected game id
    private selectCurrentRecords(): void{
        this.currentRecords = []; //Reset the current records
        this.pageIndex = 0; //Reset page index in case selection is made beyond first page
        this.records.forEach((record) => {
            if(record.gameId == this.gameType && record.difficulty.toString() == this.difficulty){
                this.rank += 1;
                record.rank = this.rank;
                record.backgroundColor = this.currentRecords.length % 2 === 0 ? this.getEvenBackgroundColor() : this.getOddBackgroundColor();
                this.currentRecords.push(record);
            }
        })
        // console.log("SIZE OF CURRENT RECORDDS: " + this.currentRecords.length);
    }

    //Moves forward one page
    public increment(): void{
        this.pageIndex += 1;
    }

    //Moves backward one page
    public decrement(): void{
        this.pageIndex -= 1;
    }

    //Handles logic for selecting a game type from the drop down menu
    public selectGameType(): void{
        this.recordCounter = 0;
        this.rank = 0;
        this.selectCurrentRecords();
        this.changeBackgroundColor();
        // this.updateBackgroundColors();
        this.cdr.detectChanges();
    }

    //Translates the numeric game id value into its formal name
    public getFormalGameTypeName(gameType: string): string{
        return CommonUtil.getFormalGameTypeName(gameType);
    }

    //Pipe the date
    public pipeDate(date: string): string{
        return CommonUtil.pipeDate(date);
    }

    private getEvenBackgroundColor(): string {
        switch (this.gameType) {
            case '1':
                return "var(--primary-color)";
            case '2':
                return "var(--primary-color)";
            case '3':
                return "var(--primary-color)";
            case '4':
                return "var(--secondary-color)";
            case '5':
                return "var(--secondary-color)";
            case '6':
                return "var(--secondary-color)";
            case '7':
                return "var(--secondary-color)";
            case '8':
                return "var(--tertiary-color)";
            case '9':
                return "var(--tertiary-color)";
            case '10':
                return "var(--tertiary-color)";
            default:
                return '#ffffff';
        }
    }

    private getOddBackgroundColor(): string {
        switch (this.gameType) {
            case '1':
                return "var(--primary-accent)";
            case '2':
                return "var(--primary-accent)";
            case '3':
                return "var(--primary-accent)";
            case '4':
                return "var(--secondary-accent)";
            case '5':
                return "var(--secondary-accent)";
            case '6':
                return "var(--secondary-accent)";
            case '7':
                return "var(--secondary-accent)";
            case '8':
                return "var(--tertiary-accent)";
            case '9':
                return "var(--tertiary-accent)";
            case '10':
                return "var(--tertiary-accent)";
            default:
                return '#ffffff';
        }
    }

    private changeBackgroundColor(): void{
        switch(this.gameType){
            case '1':
                CommonUtil.addNewBackgroundColorClass('background-color-primary', this.renderer);
                break;
            case '2':
                CommonUtil.addNewBackgroundColorClass('background-color-primary', this.renderer);
                break;
            case '3':
                CommonUtil.addNewBackgroundColorClass('background-color-primary', this.renderer);
                break;
            case '4':
                CommonUtil.addNewBackgroundColorClass('background-color-secondary', this.renderer);
                break;
            case '5':
                CommonUtil.addNewBackgroundColorClass('background-color-secondary', this.renderer);
                break;
            case '6':
                CommonUtil.addNewBackgroundColorClass('background-color-secondary', this.renderer);
                break;
            case '7':
                CommonUtil.addNewBackgroundColorClass('background-color-secondary', this.renderer);
                break;
            case '8':
                CommonUtil.addNewBackgroundColorClass('background-color-tertiary', this.renderer);
                break;
            case '9':
                CommonUtil.addNewBackgroundColorClass('background-color-tertiary', this.renderer);
                break;
            case '10':
                CommonUtil.addNewBackgroundColorClass('background-color-tertiary', this.renderer);
                break;
            default:
                '#ffffff'; 
        }
    }

}
