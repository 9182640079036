
<mat-toolbar class="navbar" *ngIf="rendered">
    <button mat-icon-button class="nav-icon" (click)="toggleMenu()">
        <svg xmlns="http://www.w3.org/2000/svg" id="Outline" viewBox="0 0 24 24" width="512" height="512"><rect y="11" width="24" height="2" rx="1"/><rect y="4" width="24" height="2" rx="1"/><rect y="18" width="24" height="2" rx="1"/></svg>
    </button>
    <div class="nav-logo">
        <img class="nav-logo" src="assets/icons/BT-icon-blue-resize.png" (click)="navigate('/')"/>
    </div>
    <div class="nav-item-container" *ngIf="!isMobile">
        <a (click)="navigate('/')">Home</a>
        <a (click)="navigate('/leaderboard')">Leaderboard</a>
        <a (click)="navigate('/about-us')">About Us</a>
        <a (click)="navigate('/changelog')">Changelog</a>
    </div> 

    <span class="nav-space" *ngIf="!isMobile"></span>
    <div class="login-button-container" *ngIf="isLogged" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
        <img class="profile-picture" [src]="profilePicture" (click)="navigateToProfile()" />
        <div class="modal-content" *ngIf="isModal">
            <div class="modal-selection">
                <p (click)="navigateToProfile()">My profile</p>
            </div>
            <div class="modal-selection">
                <p (click)="logout()">Log out</p>
            </div>
        </div>
    </div> 
    
    <div class="login-button-container">
        <button *ngIf="!isLogged" class="nav-button" (click)="navigateToLogin()">Log In</button>
    </div>

    
</mat-toolbar>
