<div class="main-container margin-top"> 
    <div *ngIf="isLoading">
        <app-spinner></app-spinner>
    </div>
    <div *ngIf="registrationSuccessful" class="auth-control-container">
        <h3><b>Your account has been verified!  You can now log in, or return
            to the home page.
        </b></h3>
        <div class="auth-button-container">
            <button type="submit" (click)="router.navigate(['/'])">Go to Home Page</button>
            <button type="submit" (click)="router.navigate(['/login'])">Log In</button>
        </div>
    </div>
    <div *ngIf="registrationFailed" class="auth-control-container">
        <h3 style="color: red"><b>There was an issue with registering your account.  Please try to
            log in and request a new verification email.
        </b></h3>
        <div class="auth-button-container">
            <button type="submit" (click)="router.navigate(['/'])">Go to Home Page</button>
            <button type="submit" (click)="router.navigate(['/login'])">Log In</button>
        </div>
    </div>
</div> 