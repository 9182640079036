<div class="main-container auth-container margin-top">
    <div class="logo-container margin-top-icon">
        <img src="/assets/icons/BeatTheory-banner.png" />
     </div>
    <div class="form-container auth-form-container">
        <form [formGroup]="registrationForm" (ngSubmit)="onSubmit()" class="registration-form">
            <div class="auth-control-container">
                <div class="auth-field-container-parent">
                    <div class="auth-field-container">
                        <label for="email">Email</label>
                        <input type="email" id="email" maxlength="255" formControlName="email"
                        [ngClass]="{'auth-input-error': registrationFailed_invalidEmail || registrationFailed_emailTaken, 
                        'auth-input': !registrationFailed_invalidEmail || !registrationFailed_invalidEmail}" >
                    <div *ngIf="registrationFailed_invalidEmail" class="auth-error-message auth-error-message-left">Invalid email address.</div>
                    <div *ngIf="registrationFailed_emailTaken" class="auth-error-message auth-error-message-left">This email is already registered.</div>    
                </div>
                    
                </div>
            
                <div class="auth-field-container-parent">
                    <div class="auth-field-container">
                        <label for="username">Username</label>
                        <input type="text" id="username" maxlength="16" formControlName="username"
                        [ngClass]="{'auth-input-error': registrationFailed_invalidUsername_invalidChars || registrationFailed_usernameTaken,  
                        'auth-input': !registrationFailed_invalidUsername_invalidChars && !registrationFailed_usernameTaken}" >
                        <div *ngIf="registrationFailed_invalidUsername_invalidChars" class="auth-error-message auth-error-message-left">Username can only contain alphanumeric characters.</div>
                        <div *ngIf="registrationFailed_usernameTaken" class="auth-error-message auth-error-message-left">This username is already in use.</div>
                    </div> 
                </div>
                   
                
                <div class="auth-field-container-parent">
                    <div class="auth-field-container">
                        <label for="password">Password</label>
                        <input type="password" id="password" maxlength="255" formControlName="password"
                        [ngClass]="{'auth-input-error': registrationFailed_invalidPassword_failsRegex || registrationFailed_passwordsDontMatch,  
                        'auth-input':!registrationFailed_invalidPassword_failsRegex && !registrationFailed_passwordsDontMatch}" >
                    <div *ngIf="registrationFailed_invalidPassword_failsRegex" class="auth-error-message auth-error-message-left">Password must contain at least one uppercase character and one special character.</div>
                </div>

                </div>
                
                <div class="auth-field-container-parent">
                    <div class="auth-field-container">
                        <label for="passwordConfirmation">Confirm Password</label>
                        <input type="password" id="passwordConfirmation" maxlength="255" formControlName="passwordConfirmation"
                        [ngClass]="{'auth-input-error': registrationFailed_passwordsDontMatch, 'auth-input': !registrationFailed_passwordsDontMatch}" >
                        <div *ngIf="registrationFailed_passwordsDontMatch" class="auth-error-message auth-error-message-left">Passwords don't match.</div>
                    </div>
                </div>
                
                <div class="auth-field-container">
                    <button *ngIf="!isSubmitting" type="submit" [disabled]="registrationForm.invalid">Register</button>
                    
                </div>

                <div class="auth-field-container-parent">
                    <div class="auth-error-message auth-error-message-left" *ngIf="registrationFailed">
                        <p>There was an error with registration.  Please try again.  If you continue to run into issues, please
                            contact our support team
                        </p>
                    </div>
                </div>

                <div *ngIf="registrationFailed_serviceUnavailable" class="auth-field-container-parent">
                    <div class="auth-field-container">
                        <div class="auth-error-message auth-error-message-left">Our backend servers are currently unavailable.  We apologize for 
                            this inconvenience.</div>
                    </div>
                </div>

                <div *ngIf="isSubmitting" >
                    <app-spinner></app-spinner>
                </div>
                

            </div>
            
        </form>
    </div>

</div>