import { Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PianoUtil } from '../../../util/PianoUtil';
import { GameHandlerComponent } from '../../../game/game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { ScoreRow } from '../../score-sheet/ScoreTypes';
import { Interval } from '../../../models/Interval';

@Component({
  selector: 'app-interval-identifier',
  standalone: true,
  imports: [CommonModule, GameHandlerComponent],
  templateUrl: '../../game-handler.component.html', 
  styleUrls: ['../../game-handler.component.css', './interval-identifier.component.css'],
})
export class IntervalIdentifierComponent extends GameHandlerComponent implements OnInit{
    override gameId = GameId.EarTraining_Intervals;    
    override initialCountdown: number = 300;
    private interval!: Interval;
    private sampler: PianoUtil = new PianoUtil();
    
    override ngOnInit(): void{
        const isInit: boolean = this.initModule();
        this.interval = this.generateRandomInterval(true);
        if(isInit) this.playSample(false);
        this.terminateAudio();
    }

    //Plays sample of the interval
    protected override playSample(correct: boolean): void{        
        if(correct){
            this.interval = this.generateRandomInterval(true); //Answer was correct, play new interval
        }
       
        this.sampler.playInterval(this.interval);
        this.updateBar((this.audioFileLength * 2) / 3);
    }

    //Checks answer and updates score
    protected override checkAnswer(guess: number, buttonRef: HTMLButtonElement): void {
        let correct: boolean = false;
        let backgroundClassColor: string = '';
        const id: string = buttonRef.id;

        if(guess == this.interval.getQuality){
            this.incrementScore();
            this.flashButton(id, true);
            backgroundClassColor = this.changeButtonBackgroundColor(true);
            correct = true;
        }else{
            this.decrementScore();
            this.flashButton(id, false);
            backgroundClassColor = this.changeButtonBackgroundColor(false);
        }
        buttonRef.classList.add(backgroundClassColor);
        this.handleAnswer(guess, correct, backgroundClassColor, buttonRef);

    }

    //Helper method to modularize game logic after answer is submitted
    private handleAnswer(guess: number, correct: boolean,
        backgroundClassColor: string, buttonRef: HTMLButtonElement
    ){
        this.updateScore(correct);
        let scoreRow: ScoreRow = {
            id: guess,
            expectedId: this.interval.getQuality,
            gameId: this.gameId

        }
        this.scoreRows.push(scoreRow);
        this.playSample(correct);
        setTimeout(() => {
            this.resetFlashClasses();
            buttonRef.classList.remove(backgroundClassColor);
        }, 200);
    }

}
