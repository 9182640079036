<div class="main-container">
    <div class="logo-container logo-container-white margin-top-icon">
        <img src="/assets/icons/BeatTheory-banner.png" /> 
    </div>

    <div class="changelog-container">
        <div id="entry_20242710" class="changelog-entry">
            <div class="changelog-header">
                <h2>v.1.0.1 - 2024-27-10</h2>
                <h3 id="entry_20242710_sign" (click)="expandNotes($event)">-</h3>
            </div>
            <div class="changelog-body">
                <ul>
                    <li>Implemented mobile compatibility</li>
                    <ul>
                        <li>Redesigned UI for 5/10 modules to be mobile compatible</li>
                        <li>Redesigned UI for leaderboard and profile record tables to be mobile compatible</li>
                        <li>Redesigned UI for difficulty selection screens to be mobile compatible</li>
                        <li>Redesigned UI for the score sheet to be mobile compatible</li>
                        <li>Redesigned UI for the navbar to remove links on mobile devices</li>
                        <li><b>DEV NOTE - I am still working on mobile compatibility for the Staff Identification and 
                        Staff Building games.  Due to limitations of the graphical architecture used for these categories, I need more time to introduce a
                        proper fix.  These games are still available for devices with a resolution width greater than 1024px.</b></li>
                    </ul>
                    <li>General UI changes</li>
                    <ul>
                        <li>Redesigned text and button layout of the difficulty select screens</li>
                        <li>Removed Total Correct and Total Attempted columns from the leaderboard and profile records</li>
                        <li>Redesigned button layout for the game modules to be less clunky</li>
                        <li>Updated naming convention for interval and chord game buttons to use shorthand notation</li>
                        <li>Adjusted error message size and layout</li>
                        <li>Added dynamic background colors that change depending on what game module you are playing</li>
                        <li>Added footer with version number</li>
                    </ul>
                    <li>Implemented caching of audio and image files for more response experience</li>
                    <li>Fixed Ear Training - Scale Identification game bug where Ionian and Aeolian modes were not being
                        correctly identified
                    </li>
                </ul>
            </div>
        </div>

    
    </div>

</div>
