import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { StaffNote } from '../../../staff-graphics/models/StaffNote';
import { Staff } from '../../../staff-graphics/models/Staff';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '../../../util/SharedDataService';
import { ActivatedRoute, Router } from '@angular/router';
import { Note } from '../../../models/Note';


@Component({
  selector: 'app-staff-identification-note-game',
  standalone: true,
  imports: [CommonModule,],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-identification-note-game.component.css",
              './../../game-handler.component.css']
})
export class StaffIdentificationNoteGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy {
    public override gameId = GameId.StaffIdentification_Notes;
    override initialCountdown: number = 300;
    private staff: Staff = new Staff([4, 4], []);


    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object, private cdr: ChangeDetectorRef){
        super(router, sharedDataService, dialog, route);
    }
    

    override ngOnInit(): void {
        this.initModule();
        const note: Note = this.generateRandomNote(this.isTrebleClef);
        this.randomElement = new StaffNote(note);

        if(isPlatformBrowser(this.platformId)){
            //Ensure that canvas size is continuously updated on screen resize
            // this.currentWidth = window.innerWidth;
            window.addEventListener('resize', (event) => {
                this.setCanvasSize();
                if(this.canvas.nativeElement.width > 0 && this.canvas.nativeElement.height > 0){
                    this.builder.refreshStaff(this.randomElement, this.isTrebleClef);

                }
                // const newWidth: number = window.innerWidth
                // if(this.currentWidth > newWidth) this.canvas.nativeElement.width -= 1;
                // else this.canvas.nativeElement.width += 1;
                // this.currentWidth = newWidth;
                // // console.log(this.canvas.nativeElement.width);
                // // this.setCanvasSize(); 
                // this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
                // // this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
                // this.builder.refreshStaff(this.randomElement, this.isTrebleClef);
            });
        }
    }
    
    //Runs after view has initialized
    ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);

        }

        
    }

    protected override check(guess: number | string, buttonRef: HTMLButtonElement){
        this.checkAnswer(guess, buttonRef);
    }

}
