<mat-dialog-content>
    <h3 class="dialog-header" *ngIf="!passwordChangeError_emailUnavailable">An email has been sent to reset your password.</h3>
    <h3 class="dialog-header" *ngIf="passwordChangeError_emailUnavailable">Please enter your email address:</h3>
    <div *ngIf="passwordChangeError_serviceUnavailable" class="auth-error-message">
        <p>The service is currently unavailable.  Please try again later.</p>
    </div>

    <div *ngIf="passwordChangeError_emailNotSent" class="auth-error-message">
        <p>There was an issue with sending a password reset email.  Please try again later.</p>
    </div>

    <div class="profile-change-container" *ngIf="passwordChangeError_emailUnavailable">
        <input type="text" maxlength="254" [(ngModel)]="emailAddr" />
    </div>

    <div class="profile-change-button-container">
        <button (click)="cancel()">Close</button>
    </div>
</mat-dialog-content>