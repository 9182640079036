//Handles making calls to the backend for game-related database transactions,

import { CommonUtil } from "../util/CommonUtil";
import { APIDataService } from "./APIDataService";

interface RequestOptions{
    method: string,
    headers: {
        'Content-Type': string;
        'X-API-KEY': string;
    };
    body: URLSearchParams;
}

//such as retrieving and saving game scores
export class GameDataService extends APIDataService{
    
    //Returns all of the entries in the scores table
    public static async getLeaderboardRecords(): Promise<string>{
        const url: string = this.getAPIUrl() + "/game/scores";
        const res: string = await this.get(url);
        return res;
    }

    //Returns all of the database records for a specific user
    public static async getUserLeaderboardRecords(username: string): Promise<string>{
        const url: string = this.getAPIUrl() + "/game/scores/" + username;
        const res: string = await this.get(url);
        return res;
    }

    //Inserts a new record into the scores table
    public static async setLeaderboardRecord(gameId: number, userId: number, totalCorrect: number,
                                            totalAttempted: number, accuracy: string, timestamp: Date, difficulty: number, 
                                            score: number): Promise<string>{
        const url: string = this.getAPIUrl() + "/game/scores/setScore";
        const params: URLSearchParams = new URLSearchParams();
        params.append("gameId", gameId.toString());
        params.append("userId", userId.toString());
        params.append("totalCorrect", totalCorrect.toString());
        params.append("totalAttempted", totalAttempted.toString());
        params.append("accuracy", accuracy);
        params.append("timestamp", CommonUtil.formatDateUTF(timestamp));
        params.append("difficulty", difficulty.toString());
        params.append("score", score.toString());

        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }


    
}