export class AudioUtil{
    public static audioContext: AudioContext;
    private static audio: HTMLAudioElement;

    public static async sliceAndPlaySample(audioFilePath: string, lengthModifier: number){
        if (!this.audioContext) {
            this.initializeAudioContext();
        }

        this.stopAllSounds();

        // Fetching the audio file
        return fetch(audioFilePath)
            .then(response => response.arrayBuffer())
            .then(data => this.audioContext.decodeAudioData(data))
            .then(buffer => {
                // Get the duration of the original audio
                const originalDuration: number = buffer.duration;

                // Cut the audio buffer in half
                const halfwayPoint: number = originalDuration / lengthModifier;
                const slicedBuffer: AudioBuffer = this.audioContext.createBuffer(buffer.numberOfChannels, this.audioContext.sampleRate * halfwayPoint, this.audioContext.sampleRate);
                for (let channel = 0; channel < buffer.numberOfChannels; channel++) {
                    const sourceData: Float32Array = buffer.getChannelData(channel);
                    const slicedData: Float32Array = slicedBuffer.getChannelData(channel);
                    for (var i = 0; i < slicedData.length; i++) {
                        slicedData[i] = sourceData[i];
                    }
                }

                // Create a buffer source node and connect it to the destination (speakers)
                const source: AudioBufferSourceNode = this.audioContext.createBufferSource();
                source.buffer = slicedBuffer;
                source.connect(this.audioContext.destination);

                // Start playing the sliced audio
                source.start();

                return source;

            })
    }


    //Stops all currently playing audio in case of multiple button clicks
    public static stopAllSounds(){
        if(this.audioContext !== undefined){
            this.audioContext.close().then(() => {
                this.audioContext = new window.AudioContext();
            });
        }
        
    }

    public static stopAudio(){
        this.audio.pause();
        this.audio.currentTime = 0;
    }

    // Initialize the audio context based on the environment
    private static initializeAudioContext(): void {
        if (typeof window !== 'undefined') {
            this.audioContext = new window.AudioContext();
        } else {
            this.audioContext = new AudioContext();
        }
    }
}