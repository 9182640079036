import { Component } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../../auth/service/AuthenticationService';
import { ProfileDialogComponent } from '../profile-dialog/profile-dialog.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { CommonUtil } from '../../../util/CommonUtil';
import { AuthDataService } from '../../../data-services/AuthDataService';

@Component({
  selector: 'app-username-profile-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule],
  templateUrl: './username-profile-dialog.component.html',
  styleUrls: ['./username-profile-dialog.component.css', '../profile-dialog/profile-dialog.component.css', 
    '../../profile.component.css']
})
export class UsernameProfileDialogComponent {


    public nameChangeError_usernameIsTaken: boolean = false;
    public nameChangeError_usernameIsInvalid: boolean = false;
    public nameChangeError_serviceUnavailable: boolean = false;
    public nameChangeError_localFailure: boolean = false;
    public newUsername: string = "";
    constructor(public dialogRef: MatDialogRef<ProfileDialogComponent>, private authService: AuthenticationService){}

    //Closes dialog box
    public cancel(): void{
        this.dialogRef.close();
    }

    //Confirms the user's name change
    public async confirm(): Promise<void>{
        //Reset pre-existing error flags
        this.nameChangeError_serviceUnavailable = false;
        this.nameChangeError_usernameIsInvalid = false;
        this.nameChangeError_usernameIsTaken = false;

        //Make sure the new username is not empty
        if(!CommonUtil.isEmpty(this.newUsername)){ 
            //Check if the username is available
            const jsonString: string = await AuthDataService.checkIfUsernameTaken(this.newUsername);
            if(CommonUtil.isEmpty(jsonString)){
                this.nameChangeError_serviceUnavailable = true;
            } else if(!CommonUtil.isAlphanumeric(this.newUsername)){
                this.nameChangeError_usernameIsInvalid = true;
            } else {
                //Check JSON response
                const usernameTaken: boolean = JSON.parse(jsonString);
                if(usernameTaken){
                    //Username is unavailable
                    this.nameChangeError_usernameIsTaken = true;
                }
            }

            //Username is available, update current user 
            let updateSuccessful: boolean = false;
            if(!this.nameChangeError_serviceUnavailable && !this.nameChangeError_usernameIsTaken && !this.nameChangeError_usernameIsInvalid){
                const id: string = this.authService.getId() as string; 
                if(!CommonUtil.isEmpty(id)){
                    const jsonString2: string = await AuthDataService.updateUsername(id, this.newUsername);
                    if(CommonUtil.isEmpty(jsonString2)){
                        this.nameChangeError_serviceUnavailable = true;
                    } else {
                        updateSuccessful = JSON.parse(jsonString2);
                    }
                } else {
                    this.nameChangeError_localFailure = true;
                }
            }

            //Check if the update was successful
            if(updateSuccessful){
                this.authService.setUsername(this.newUsername);
                this.dialogRef.close(this.newUsername);
            }
            
        } else {
            this.dialogRef.close();
        }
    }
}
