
//Util class for basic operations related to staff elements
export class StaffUtil{
    //Checks if a note is sharp
    public static isSharp(note: string): boolean{
        const sharpRegex: RegExp = /^(?!.*[A-G]b)(?=.*#)[A-G]#?$/;
        return sharpRegex.test(note);
    }

    //Checks if a note is double sharp
    public static isDoubleSharp(note: string): boolean{
        const doubleSharpRegex: RegExp = /.x$/;
        return doubleSharpRegex.test(note);
    }

    //Checks if a note is double flat
    public static isDoubleFlat(note: string): boolean{
        const doubleFlatRegex: RegExp = /.bb$/;
        return doubleFlatRegex.test(note);
    }

    //Checks if a note is flat
    public static isFlat(note: string): boolean{
        const flatRegex: RegExp = /^(.*b){1}$/;
        return flatRegex.test(note) && note.length <= 2;
    }

    //Checks if a note is triple sharp
    public static isTripleSharp(note: string): boolean{
        const tripleSharpRegex: RegExp = /xx/;
        return tripleSharpRegex.test(note);
    }

    public static isPitchModified(root: string): boolean{
        return StaffUtil.isDoubleFlat(root) || 
        StaffUtil.isFlat(root) || 
        StaffUtil.isSharp(root) || 
        StaffUtil.isDoubleSharp(root);
    }

    //Splits an enharmonic note from the notes list and returns one of them
    public static splitNote(note: string, key?: string){
        let res: string = "";
        try{
            const notes: string[] = note.split('/');
            if(notes.length < 2){
                res = note;
            } else {
                const randomIndex: number = Math.floor(Math.random() * notes.length);
                res = notes[randomIndex].trim();

            }
        } catch(error){
            console.error("Error with splitting note " + note);
        }
        
        return res;

    }

}