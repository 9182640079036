import { Component } from '@angular/core';
import { SpinnerComponent } from "../../graphics/spinner/spinner.component";
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonUtil } from '../../util/CommonUtil';
import { AuthDataService } from '../../data-services/AuthDataService';

@Component({
    selector: 'app-reset-password',
    standalone: true,
    templateUrl: './reset-password.component.html',
    styleUrl: './reset-password.component.css',
    imports: [FormsModule, SpinnerComponent, CommonModule]
})
export class ResetPasswordComponent {
    public emailSentError_serviceUnavailable: boolean = false;
    public emailSentError_invalidEmail: boolean = false;
    public emailSentError_emailNotSent: boolean = false;
    public sendingEmail: boolean = false;
    public emailSentSuccessfully: boolean = false;
    public email: string = "";
    constructor(public router: Router){}

    public async sendEmail(): Promise<void>{
        //Reset error flags
        this.emailSentError_serviceUnavailable = false;
        this.emailSentError_invalidEmail = false;
        this.emailSentError_emailNotSent = false;
        this.emailSentSuccessfully = false;
        this.sendingEmail = true;

        //Check for valid email
        if(CommonUtil.isEmpty(this.email) || !CommonUtil.isValidEmail(this.email)){
            this.emailSentError_invalidEmail = true;
            this.sendingEmail = false;
            return;
        }

        //Generate token and send email
        const tokenGeneratedJson: string = await AuthDataService.generatePasswordResetToken(this.email);
        let tokenGenerated: boolean = false;
        let emailSent: boolean = false;

        if(!CommonUtil.isEmpty(tokenGeneratedJson)){
            tokenGenerated = JSON.parse(tokenGeneratedJson).tokenGenerated;
        }

        //If the token was generated, attempt to send email
        if(tokenGenerated){
            const emailSentJson: string = await AuthDataService.sendPasswordResetEmail(this.email);
            if(!CommonUtil.isEmpty(emailSentJson)){
                emailSent = JSON.parse(emailSentJson).emailSent;
            }
        }

        //Verify that token was generated and email was sent
        if(tokenGenerated && emailSent){
            this.emailSentSuccessfully = true;
        } else if (tokenGenerated && !emailSent){
            this.emailSentError_emailNotSent = true;
        } else {
            this.emailSentError_serviceUnavailable = true;
        }

        this.sendingEmail = false;
    }
}
