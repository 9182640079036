import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AuthDataService } from '../../data-services/AuthDataService';
import {} from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from '../service/AuthenticationService';
import { CommonUtil } from '../../util/CommonUtil';
import { SpinnerComponent } from "../../graphics/spinner/spinner.component";

@Component({
    selector: 'app-verify-new-account',
    standalone: true,
    templateUrl: './verify-new-account.component.html',
    styleUrl: './verify-new-account.component.css',
    imports: [CommonModule, FormsModule, SpinnerComponent]
})
export class VerifyNewAccountComponent{
    public email: string = "";
    public verificationInputValue: string = "";

    public isSendingVerificationEmail: boolean = false;
    public verificationError_noEmailAvailable: boolean = false;
    public verificationError_emailNotSent: boolean = false;

    constructor(public router: Router, private authService: AuthenticationService){}

    //Send a new verification email to the user if they are in the session
    public async sendNewVerificationEmail(): Promise<void>{
        this.isSendingVerificationEmail = true;
        this.verificationError_emailNotSent = false;
        this.verificationError_noEmailAvailable = false;

        const email: string = this.authService.getEmail() as string;
        if(CommonUtil.isEmpty(email)){
            this.verificationError_noEmailAvailable = true;
            this.isSendingVerificationEmail = false;
            return;
        }

        const sent: string = await AuthDataService.sendEmailVerification(email);
        if(CommonUtil.isEmpty(sent)){
            this.verificationError_emailNotSent = true;
            this.isSendingVerificationEmail = false;
            return;
        }

        //Verify that the email was sent
        const emailSent: boolean = JSON.parse(sent).emailSent;
        if(!emailSent){
            this.verificationError_emailNotSent = true;
            this.isSendingVerificationEmail = false;
            return;
        }

        this.isSendingVerificationEmail = false;

    }

}
