import { environment } from '../../environments/environment';  // Import the environment

interface RequestOptions{
    method: string,
    headers: {
        'Content-Type': string;
        'X-API-KEY': string;
    };
    body: URLSearchParams;
}


//Base class for making calls to the LU API.  Contains implementation for GET and POST requests
export class APIDataService{
    //Foundation for making get requests
    public static async get(url: string): Promise<any>{
        //Basic get header w/ api key
        const headers = {
            'X-API-KEY': environment.apiKey
        }

        return fetch(url, { headers })
        .then((response) => {
            if (!response.ok) {
                if(response.status === 404){
                    console.error("Resource not found");
                }
                return null;
            }
            return response.json().then(jsonData => JSON.stringify(jsonData));
        })
        .then((data) => {
            return data;
        })
        .catch((error) => {
            console.error('Error with GET request: ' , error);
        });
    }

    //Foundation for making post requests
    public static async post(url: string, options: RequestOptions): Promise<any>{
        return fetch(url, options)
        .then((response) => {
            if (!response.ok) {
                if(response.status === 404){
                    console.log("Resource not found");
                }
                if(response.status === 500){
                    console.error("Internal server error");
                }
                return null;
            }
            return response.json().then(jsonData => JSON.stringify(jsonData));
        })
        .then((data) => {
            return data;
        }).catch((error) => {
            console.error('Error with POST request: ' , error);
        });
    }

    protected static getAPIKey(): string{
        return environment.apiKey;
    }

    protected static getAPIUrl(): string{
        return environment.apiUrl;
    }
}