<script src="https://cdnjs.cloudflare.com/ajax/libs/tone/14.8.25/Tone.min.js"></script>

<div *ngIf="incompatible" class="main-container margin-top">
    <div class="logo-container logo-container-white margin-top-icon">
        <img src="/assets/icons/BeatTheory-banner.png" /> 
        <h3>This game is currently unavailable :(</h3>
    </div>

    <div class="auth-field-container">
        <p>
            Unfortunately, the staff building and staff identification games are currently unsupported on 
            mobile and tablet devices.  You must have a monitor that is greater than 1024px in width in order to play these
            games.
        </p>
        <p>
            We apologize for this inconvenience.  The development team is working diligently on providing mobile accessibility
            for these games.  We will keep you updated on our social media accounts and the application changelog.
        </p>

        <a href="/" style="text-align:center">Return Home</a>
    </div>
</div>
<div *ngIf="!rendered && !incompatible" >
    <app-spinner></app-spinner>
</div>
<div *ngIf="rendered && !incompatible" class="main-container form-container margin-top" [ngClass]="getBackgroundColor()">
    <form [formGroup]="difficultyForm" (ngSubmit)="onSubmit()" class="difficulty-form">
        <div class="difficulty-form-header" [ngClass]="getBackgroundColor()">
            <div></div>
            <h1> {{ title }} </h1>
            <div></div>
            <div></div>
            <p> {{ subtitle }} </p>
            <div></div>
            
        </div>
        
        <div class="difficulty-form-tab-container" >
            <div id="left-cell" class="tab-cell-left" [ngClass]="getBackgroundColor()"
                [class.tab-cell-left-selected]="practiceModeSelected" 
                [class.tab-hovered-ear-training]="isHovered && getBackgroundColor() === 'ear-training-container'"
                [class.tab-hovered-staff-identification]="isHovered && getBackgroundColor() === 'staff-identification-container'"
                [class.tab-hovered-staff-building]="isHovered && getBackgroundColor() === 'staff-building-container'"
                (click)="selectTab('left')"
                (mouseenter)="onMouseEnter('left-cell')"
                (mouseleave)="onMouseLeave()">Practice Mode
            </div>
            <div id="right-cell" class="tab-cell-right" [ngClass]="getBackgroundColor()" 
                [class.tab-cell-right-selected]="challengeModeSelected" 
                [class.tab-hovered-ear-training]="isHovered && getBackgroundColor() === 'ear-training-container'"
                [class.tab-hovered-staff-identification]="isHovered && getBackgroundColor() === 'staff-identification-container'"
                [class.tab-hovered-staff-building]="isHovered && getBackgroundColor() === 'staff-building-container'"
                (click)="selectTab('right')"
                (mouseenter)="onMouseEnter('right-cell')"
                (mouseleave)="onMouseLeave()">Challenge Mode
            </div>
        </div>
        <div *ngIf="practiceModeSelected" class="control-container">
            <div class="challenge-mode-text">
                <p>{{ description_practiceMode }}</p>
            </div>
            
        </div>

        <div *ngIf="challengeModeSelected" class="control-container">
            
            <div class="challenge-mode-text">
                <p>{{description_challengeMode}}</p>
            </div>
        </div>
            
        <hr />

        <div class="difficulty-container">
            <div class="difficulty-label">
                <h2>Difficulty: </h2>
            </div>

            <button type="button" 
                        class="button" 
                        (mouseenter)="isHovered_easy = true"
                        (mouseleave)="isHovered_easy = false"
                        [class.ear-training-container]="difficultyForm.value.difficulty === '1' && checkForCurrentGameId() === 'ET'" 
                        [class.staff-identification-container]="difficultyForm.value.difficulty === '1' && checkForCurrentGameId() === 'SI'"  
                        [class.staff-building-container]="difficultyForm.value.difficulty === '1' && checkForCurrentGameId() === 'SB'"   
                        (click)="selectDifficulty('1')"
                        mat-raised-button [matTooltip]="easyButtonTooltip" matTooltipPosition="right">Easy</button>
                <button *ngIf="gameId != 7" type="button" 
                        class="button" 
                        (mouseenter)="isHovered_medium = true"
                        (mouseleave)="isHovered_medium = false"
                        [class.ear-training-container]="difficultyForm.value.difficulty === '2' && checkForCurrentGameId() === 'ET'" 
                        [class.staff-identification-container]="difficultyForm.value.difficulty === '2' && checkForCurrentGameId() === 'SI'"  
                        [class.staff-building-container]="difficultyForm.value.difficulty === '2' && checkForCurrentGameId() === 'SB'"  
                        (click)="selectDifficulty('2')"
                        mat-raised-button [matTooltip]="mediumButtonTootip" matTooltipPosition="right">Medium</button>
                <button type="button" 
                        class="button"
                        (mouseenter)="isHovered_hard = true"
                        (mouseleave)="isHovered_hard = false" 
                        [class.ear-training-container]="difficultyForm.value.difficulty === '3' && checkForCurrentGameId() === 'ET'" 
                        [class.staff-identification-container]="difficultyForm.value.difficulty === '3' && checkForCurrentGameId() === 'SI'"  
                        [class.staff-building-container]="difficultyForm.value.difficulty === '3' && checkForCurrentGameId() === 'SB'"  
                        (click)="selectDifficulty('3')"
                        mat-raised-button [matTooltip]="hardButtonTooltip" matTooltipPosition="right">Hard</button>
            <div class="difficulty-form-row">
                
                
            </div>
            
        </div>
            
        <button type="submit" [disabled]="difficultyForm.invalid" class="submit-button"
        [ngClass]="getBackgroundColor()">Start</button>
        
    </form>
</div>

