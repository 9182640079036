<div #staffContainer2 class="main-container margin-top" style="border: none !important">
    <div class="control-container" [ngClass]="getBackgroundColor()">
        <div class="header-container">
            <div class="container">
                <div *ngIf="practiceMode">{{ timer$ | async}}</div>
                <div *ngIf="challengeMode">{{ countdown }}</div>
                <div><b>Score: {{score}}</b></div>
                <div>{{ returnScoreAsString() }}</div>
            </div>
            <br />
            <div class="container">

                <div class="invisible">

                </div> 
            </div>
 
        </div>
        <div *ngIf="gameId == 4">
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
                
            </div>
            <div id="tooltip" class="symbol-tooltip"></div>
        
            <div class="button-container" >
                <div *ngIf="difficulty == 1"> 
                    <div class="button-container-easy">                    
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(1, button1)">Major Triad</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(4, button2)">Minor Triad</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(7, button3)">Augmented Triad</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(10, button4)">Diminished Triad</button>
                        
                        
                    </div>
                </div>
                <div *ngIf="difficulty == 2">
                    <div class="button-container-medium">
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(13, button1)">Dom7</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(17, button2)">Maj7</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(21, button3)">Min7</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(25, button4)">Min7b5</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="check(29, button5)">Dim7</button>
                        
                    </div>
                </div>
                <div *ngIf="difficulty == 3">
                    <div class="button-container-hard">
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(35, button1)">Dom9</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(30, button2)">Maj9</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(40, button3)">Min9</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(45, button4)">Dom7b9</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5"(click)="check(50, button5)">Dom7#9</button>
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check(60, button6)">Dom11</button>
                        <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="check(55, button7)">Maj11</button>
                        <button #button8 class="button" [ngClass]="getBackgroundColor()" id="8" (click)="check(65, button8)">Min11</button>
                        <button #button9 class="button" [ngClass]="getBackgroundColor()" id="9"(click)="check(70, button9)">Dom7#11</button>
                        <button #button10 class="button" [ngClass]="getBackgroundColor()" id="10" (click)="check(80, button10)">Dom13</button>
                        <button #button11 class="button" [ngClass]="getBackgroundColor()" id="11" (click)="check(75, button11)">Maj13</button>
                        <button #button12 class="button" [ngClass]="getBackgroundColor()" id="12" (click)="check(85, button12)">Min13</button>
                    </div>
                </div>
            </div>
        </div>
        <div style="width: 1000px" *ngIf="gameId == 5">
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
                
            </div>
        
            <div class="button-container" >
                <div *ngIf="difficulty == 1"> 
                    <div class="button-container-easy">                    
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(1, button1)">Major</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(2, button2)">Natural Minor</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(3, button3)">Melodic Minor</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(4, button4)">Harmonic Minor</button>
                        
                        
                    </div>
                </div>
                <div *ngIf="difficulty == 2">
                    <div class="button-container-medium">
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(17, button1)">Ionian</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(6, button2)">Dorian</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(7, button3)">Phrygian</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(8, button4)">Lydian</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5"(click)="check(9, button5)">Mixolydian</button>
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check(18, button6)">Aeolian</button>
                        <button #button7 class="button"[ngClass]="getBackgroundColor()"  id="7" (click)="check(10, button7)">Locrian</button>
                    </div>
                </div>
                <div *ngIf="difficulty == 3">
                    <div class="button-container-hard">
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(5, button1)">Harmonic Major</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(11, button2)">Persian</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(12, button3)">Hungarian Minor</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()"id="4" (click)="check(13, button4)">Double Harmonic</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5"(click)="check(14, button5)">Enigmatic</button>                    
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check(15, button6)">Neapolitan Major</button>
                        <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="check(16, button7)">Neapolitan Minor</button>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="gameId == 6">
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
                
            </div>
        
            <div class="button-container" >
                <div *ngIf="difficulty == 1"> 
                    <div class="button-container-easy">                    
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(3, button1)">m3</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(4, button2)">M3</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(7, button3)">P5</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(12, button4)">P8</button>
                        
                        
                    </div>
                </div>
                <div *ngIf="difficulty == 2">
                    <div class="button-container-medium">
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(2, button1)">M2</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(4, button2)">M3</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(5, button3)">P4</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(7, button4)">P5</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5"(click)="check(9, button5)">M6</button>
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check(11, button6)">M7</button>
                        <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="check(12, button7)">P8</button>
                        <div></div>
                    </div>
                </div>
                <div *ngIf="difficulty == 3">
                    <div class="button-container-hard">
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check(1, button1)">m2</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check(2, button2)">M2</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check(3, button3)">m3</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check(4, button4)">M3</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5"(click)="check(5, button5)">P4</button>
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check(6, button6)">A4</button>
                        <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="check(7, button7)">P5</button>
                        <button #button8 class="button" [ngClass]="getBackgroundColor()" id="8" (click)="check(8, button8)">m6</button>
                        <button #button9 class="button" [ngClass]="getBackgroundColor()" id="9" (click)="check(9, button9)">M6</button>
                        <button #button10 class="button" [ngClass]="getBackgroundColor()" id="10" (click)="check(10, button10)">m7</button>
                        <button #button11 class="button" [ngClass]="getBackgroundColor()" id="11" (click)="check(11, button11)">M7</button>
                        <button #button12 class="button" [ngClass]="getBackgroundColor()" id="12" (click)="check(12, button12)">P8</button>

                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="gameId == 7">
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
                
            </div> 
        
            <div class="button-container">
                <div *ngIf="difficulty == 1"> 
                    <div class="button-container-easy">                    
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check('C', button1)">C</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check('D', button2)">D</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check('E', button3)">E</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check('F', button4)">F</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="check('G', button5)">G</button>
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check('A', button6)">A</button>
                        <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="check('B', button7)">B</button>
                        <div></div>

                    </div>
                </div>
                <div *ngIf="difficulty == 3">
                    <div class="button-container-hard">
                        <button #button8 class="button" [ngClass]="getBackgroundColor()" id="8" (click)="check('Cb', button8)">Cb</button>
                        <button #button1 class="button" [ngClass]="getBackgroundColor()" id="1" (click)="check('C', button1)">C</button>
                        <button #button15 class="button" [ngClass]="getBackgroundColor()" id="15" (click)="check('C#', button15)">C#</button>
                        <button #button9 class="button" [ngClass]="getBackgroundColor()" id="9" (click)="check('Db', button9)">Db</button>
                        <button #button2 class="button" [ngClass]="getBackgroundColor()" id="2" (click)="check('D', button2)">D</button>
                        <button #button16 class="button" [ngClass]="getBackgroundColor()" id="16" (click)="check('D#', button16)">D#</button>
                        <button #button10 class="button" [ngClass]="getBackgroundColor()" id="10" (click)="check('Eb', button10)">Eb</button>
                        <button #button3 class="button" [ngClass]="getBackgroundColor()" id="3" (click)="check('E', button3)">E</button>
                        <button #button17 class="button" [ngClass]="getBackgroundColor()" id="17" (click)="check('E#', button17)">E#</button>
                        <button #button11 class="button" [ngClass]="getBackgroundColor()" id="11" (click)="check('Fb', button11)">Fb</button>
                        <button #button4 class="button" [ngClass]="getBackgroundColor()" id="4" (click)="check('F', button4)">F</button>
                        <button #button18 class="button" [ngClass]="getBackgroundColor()" id="18" (click)="check('F#', button18)">F#</button>
                        <button #button12 class="button" [ngClass]="getBackgroundColor()" id="12" (click)="check('Gb', button12)">Gb</button>
                        <button #button5 class="button" [ngClass]="getBackgroundColor()" id="5" (click)="check('G', button5)">G</button>
                        <button #button19 class="button" [ngClass]="getBackgroundColor()" id="19" (click)="check('G#', button19)">G#</button>
                        <button #button13 class="button" [ngClass]="getBackgroundColor()" id="13" (click)="check('Ab', button13)">Ab</button>
                        <button #button6 class="button" [ngClass]="getBackgroundColor()" id="6" (click)="check('A', button6)">A</button>
                        <button #button20 class="button" [ngClass]="getBackgroundColor()" id="20" (click)="check('A#', button20)">A#</button>
                        <button #button14 class="button" [ngClass]="getBackgroundColor()" id="14" (click)="check('Bb', button14)">Bb</button>
                        <button #button7 class="button" [ngClass]="getBackgroundColor()" id="7" (click)="check('B', button7)">B</button>
                        <button #button21 class="button" [ngClass]="getBackgroundColor()" id="21" (click)="check('B#', button21)">B#</button>
        
                        
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="gameId == 8" class="staff-building-container">
            <div class="element-name-container">
                <h2>{{this.randomElement.getName}}</h2>
            </div> 
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
            </div> 
            <div class="staff-building-rules-container">
                <p>W = Sharp, S = Double Sharp, A = Flat, D = Double Flat</p>
                <p>Q = Remove Symbol, X = Remove Last Note</p>
            </div>
        </div>

        <div style="width: 1000px" *ngIf="gameId == 9">
            <div class="element-name-container">
                <h2>{{this.randomElement.getName}}</h2>
            </div>  
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
            </div> 
            <div class="staff-building-rules-container">
                <p>W = Sharp, S = Double Sharp, A = Flat, D = Double Flat</p>
                <p>Q = Remove Symbol, X = Remove Last Note</p>
            </div>
        </div>
        <div *ngIf="gameId == 10">
            <div class="element-name-container">
                <h2>{{this.randomElement.getName}}</h2>
            </div> 
            <div #staffContainer class="staff-container" id="dynamicDiv">
                <canvas #staffCanvas (click)="submitNote()"></canvas>
            </div>
            <div class="staff-building-rules-container">
                <p>W = Sharp, S = Double Sharp, A = Flat, D = Double Flat</p>
                <p>Q = Remove Symbol</p>
            </div>
        </div>
        <button class="submit-button finish-button" [ngClass]="getBackgroundColor()" (click)="finish()">Finish</button>
    </div>


    <script>
        // Update viewBox based on the size of the dynamicDiv
        function updateViewBox() {
            var dynamicDiv = document.getElementById('dynamicDiv');
            var dynamicSvg = document.getElementById('dynamicSvg');

            // Get the width and height of the dynamicDiv
            var width = dynamicDiv.clientWidth;
            var height = dynamicDiv.clientHeight;

            console.log(width + " : " + height);

            // Update the viewBox attribute of the SVG
            dynamicSvg.setAttribute('viewBox', '0 0 ' + width + ' ' + height);
        }

        // Call the function initially and whenever the window is resized
        updateViewBox();
        window.addEventListener('resize', updateViewBox);
    </script>
</div>