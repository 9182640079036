// shared.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class MenuDataService {
    private booleanValueSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public booleanValue$: Observable<boolean> = this.booleanValueSubject.asObservable();

    updateData(value: boolean) {
        const currentValue: boolean = this.booleanValueSubject.value;
        this.booleanValueSubject.next(!currentValue);
    }
}