<mat-sidenav-container style="background-color: var(--background-color);">
    <mat-sidenav [opened]="collapsed()" mode="side" [style.width]="'225px'">
        <div class="side-nav">
            <div class="side-nav-list" *ngFor="let item of menuItems()">
                <div (click)="handleClick(item)">
                    <div class="side-nav-list-item" [ngClass]="{'menu-item-2': item.id === 2, 'menu-item-3': item.id === 3, 'menu-item-4': item.id === 4,
                    'menu-item-5': item.id >= 5, 'menu-items-rest': item.id >= 6}">
                        <mat-icon class="icon">{{ item.icon }}</mat-icon>
                        <span class="label">{{ item.label }}</span>   
                    </div>
                </div>
                
                <div class="child-nav-list" *ngIf="item.children.length > 0">
                    <div class="child-nav-list-item" *ngFor="let child of item.children" (click)="navigateTo(child)"> 
                        <span></span>
                        <span class="label">{{ child.label }}</span>   
                        
                    </div>
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content class="content" [style.margin-left]="sideNavWidth">
        <!-- <p>v.1.0.1</p> -->
        <main class="content">
            <router-outlet></router-outlet>
        </main>
        
    </mat-sidenav-content>
</mat-sidenav-container>
