import { Renderer2 } from "@angular/core";
import { ChordQuality } from "../constants/ChordEnum";
import { GameId } from "../constants/GameIdEnum";
import { DatePipe } from '@angular/common';


//Helper functions to be leveraged across the app
export class CommonUtil{

    //Checks if a string is empty
    public static isEmpty(str: string): boolean{
        return str === "" || str == null;
    }
    //Formats second counter into standard stopwatch 
    public static getTimer(value: number): string{
        let result = "";

        let mins = Math.floor(value / 60);
        result += mins.toString()
        result += ":";
        
        let secs = value % 60;
        if(secs <= 9){
            result += "0" + secs.toString();
        } else{
            result += secs.toString();
        }

        return result;   
    }

    //Returns a random number in the given parameters
    public static randint(min: number, max: number){
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    public static randelement(arr: number[]): number{
        return arr[CommonUtil.randint(0, arr.length - 1)];
    }

    //Returns type ChordQuality based on integer value
    public static getRandomEnumValue<T>(anEnum: ChordQuality, index: number): T[keyof T] {
        const enumValues = (Object.values(anEnum) as Array<T[keyof T]>)
                         .filter(v => typeof v === "number"); // Filter out the string keys
        return enumValues[index];
    }

    //Compares two arrays
    public static arraysEqual<T>(a: T[], b: T[]): boolean{
        if (a.length !== b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (a[i] !== b[i]) {
                return false;
            }
        }

        return true;
    }

    //Regex expression to parse alphabetical and numerical characters
    public static splitString(input: string): { letter: string, number: number } {
        // Regular expression to match one or more letters followed by one or more digits
        const regex = /^([A-Ga-g]+[#bx]*)(\d)$/;
    
        const match = input.match(regex);
        if (match) {
            return { letter: match[1], number: parseInt(match[2]) };
        } else {
            throw new Error("String does not match the expected format");
        }
    }

    //Formats a string in MM-DD-YYYY 
    public static formatDate(date: Date): string{
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
        return `${month}-${day}-${year}`;
    }

    //Formats a string using UTF YYYY-MM-DD format
    public static formatDateUTF(date: Date): string{
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    //Pipes a string to be MM-DD-YYYY if it does not match that format
    public static pipeDate(date: string): string{
        const datePipe: DatePipe = new DatePipe('en-US');
        return datePipe.transform(date, 'MM/dd/yyyy') as string;
    }

    //Translates the numeric game id value into its formal name
    public static getFormalGameTypeName(gameType: string): string{
        switch(gameType){
            case '1':
                return "Ear Training - Chords";
            case '2':
                return "Ear Training - Intervals";
            case '3':
                return "Ear Training - Scales";
            case '4':
                return "Staff Identification - Chords";
            case '5':
                return "Staff Identification - Scales";
            case '6':
                return "Staff Identification - Intervals";
            case '7':
                return "Staff Identification - Notes";
            case '8':
                return "Staff Building - Chords";
            case '9':
                return "Staff Building - Scales";
            case '10':
                return "Staff Building - Intervals";
            default:
                return "Game Type Unavailable";
        }
    }

    //Convert a blob into a string
    public static convertBlobToString(blob: Blob): Promise<string> {
        return new Promise ((resolve, reject) => {
            const reader = new FileReader();

            reader.onload = () => {
                const result = reader.result as string;
                resolve(result);
            }

            reader.onerror = () => {
                reader.abort();
                reject(new Error('Error reading blob as a string'));
            }

            reader.readAsText(blob);
        });
    }

    //Get the .css class name from the game id
    public static getBackgroundColor(gameId: number): string{
        if(gameId == GameId.EarTraining_Chords || gameId == GameId.EarTraining_Intervals ||
            gameId == GameId.EarTraining_Scales){
                return 'ear-training-container';
        } else if(gameId == GameId.StaffIdentification_Chords || gameId == GameId.StaffIdentification_Intervals ||
            gameId == GameId.StaffIdentification_Notes || gameId == GameId.StaffIdentification_Scales){
                return 'staff-identification-container';
        } else if(gameId == GameId.StaffBuilding_Chords || gameId == GameId.StaffBuilding_Intervals ||
            gameId == GameId.StaffBuilding_Scales){
                return 'staff-building-container';
        }
        
        return '';
    }

    //Helper method that returns true if the string argument is alphanumeric
    public static isAlphanumeric(str: string): boolean{
        const regex: RegExp = /^[a-zA-Z0-9]+$/;
        return regex.test(str);
    }

    //Helper method to validate strong password structure
    //Returns true if string contains one uppercase and one special character
    public static isValidPassword(str: string): boolean{
        const regex: RegExp = /^(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).+$/;
        return regex.test(str);
    }

    //Helper method that returns true if the string argument is structured as a valid email address
    public static isValidEmail(str: string): boolean{
        const regex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(str);
    }

    //Generates a random number and returns a boolean
    public static flipCoin(): boolean{
        return Math.random() < 0.5 ? true : false;
    }
    

    //Converts number value to difficulty string for leaderboard records
    public static convertToDifficultyString(n: number): string{
        switch(n){
            case 1:
                return "Easy";
            case 2:
                return "Medium";
            case 3:
                return "Hard";
            default:
                return "Easy";
        }
    }

    // Adds a new background color to the main class
    public static addNewBackgroundColorClass(className: string, renderer: Renderer2): void{
        const main = document.querySelector('main');
        renderer.setAttribute(main, 'class', '');
        renderer.addClass(main, className);
    }

}