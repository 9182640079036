import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router'; 
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MenuDataService } from '../data-services/MenuDataService';
import { AuthenticationService } from '../auth/service/AuthenticationService';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LoginHoverComponent } from "../auth/login/login-hover/login-hover.component";


@Component({
    selector: 'app-nav-bar',
    standalone: true,
    templateUrl: './nav-bar.component.html',
    styleUrl: './nav-bar.component.css',
    imports: [CommonModule, MatToolbarModule, MatIconModule, MatTooltipModule, LoginHoverComponent]
})
export class NavBarComponent implements OnInit{

    @Output() collapsed = new EventEmitter<boolean>();
    public menuState: boolean = false;
    public isLogged: boolean = false;
    public isLoaded: boolean = false;
    public isModal: boolean = false;
    public modalTop: number = 0;
    public modalLeft: number = 0;
    public profilePicture: string = "";
    public isMobile: boolean = false;
    public rendered: boolean = false;

    constructor(private router: Router, private dataService: MenuDataService, private authService: AuthenticationService){}
    
    async ngOnInit(): Promise<void> {
        if(typeof localStorage !== 'undefined'){
            this.isLogged = this.isLoggedIn();
            if(this.isLogged){
                await this.getProfilePicture(); 

            }            
        }

        //Remove navbar links for mobile devices
        if (typeof window !== 'undefined') {
            // Initialize screen size
            this.updateScreenSize();
  
            // Add event listener for window resize
            window.addEventListener('resize', this.updateScreenSize.bind(this));
        }

    }

    //Handles mouse entering logic for login picture
    public onMouseEnter() {
        this.isModal = true;
    }

    //Handles mouse leaving logic for login picture
    public onMouseLeave(){
        this.isModal = false;
    }

    //Goes to the specified page
    public navigate(route: string): void{
        this.router.navigate([route]);
    }

    //Goes to the login page
    public navigateToLogin(): void{
        this.router.navigate(['/login']);
    }

    //Goes to the profile page
    public navigateToProfile(): void{
        this.router.navigate(['/profile'])
    }

    //Toggles menu state
    public toggleMenu(): void{
        this.menuState = !this.menuState;
        this.dataService.updateData(this.menuState);

    }

    //Checks that a user is logged in
    public isLoggedIn(): boolean{
        return this.authService.isLoggedIn();
    }

    //Logs user out and redirects them to home page
    public logout(): void{
        this.authService.logout(); 
        this.router.navigate(['/']).then(() => {
            window.location.reload();
        })
    }

    //Get the image url for the user if they are logged in
    public async getProfilePicture(): Promise<void>{
        if(typeof localStorage !== 'undefined'){
            const url: string = this.authService.getProfilePicture() as string;
            this.profilePicture = url;    
        }
        

    }

    //Updates the screen size and removes tags for mobile devices
    updateScreenSize(){
        const width: number = window.innerWidth;

        if(width <= 670){
            this.isMobile = true;
        } else {
            this.isMobile = false;
        }

        this.rendered = true;

    }

}
