import { Component, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PianoUtil } from '../../../util/PianoUtil';
import { GameHandlerComponent } from '../../../game/game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { ScoreRow } from '../../score-sheet/ScoreTypes';
import { Scale } from '../../../models/Scale';

@Component({
  selector: 'app-scale-identifier',
  standalone: true,
  imports: [CommonModule, GameHandlerComponent],
  templateUrl: '../../game-handler.component.html',
  styleUrls: ['../../game-handler.component.css', './scale-identifier.component.css'],
})
export class ScaleIdentifierComponent extends GameHandlerComponent implements OnInit{
    override gameId = GameId.EarTraining_Scales;
    override initialCountdown: number = 600;
    private scale!: Scale
    private sampler: PianoUtil = new PianoUtil();

    
    override ngOnInit(): void{
        const isInit: boolean = this.initModule();
        this.scale = this.generateRandomScale(true);
        if(isInit) this.playSample(false);
        this.terminateAudio();
        
        
    }

    //Plays sample of the scale
    protected override playSample(correct: boolean): void{
        console.log("Scale quality is " + this.scale.getQuality);
        if(correct){
            this.scale = this.generateRandomScale(true);
        }

        this.sampler.playNotes(this.scale.getNotes, 0); 
        this.updateBar((this.audioFileLength * this.scale.getNotes.length) / 4);
    }

    //Checks answer and updates score
    protected override checkAnswer(guess: number, buttonRef: HTMLButtonElement): void {
        let correct: boolean = false;
        let backgroundClassColor: string = '';
        const id: string = buttonRef.id;

        console.log("GUESS: " + guess + " ||| " + "ACTUAL: " + this.scale.getQuality);
        if(guess == this.scale.getQuality || (guess == 1 && this.scale.getQuality == 17) ||
           (guess == 2 && this.scale.getQuality == 18)){
            this.incrementScore();
            this.flashButton(id, true);
            backgroundClassColor = this.changeButtonBackgroundColor(true);
            correct = true;
        }else{
            this.decrementScore();
            this.flashButton(id, false);
            backgroundClassColor = this.changeButtonBackgroundColor(false);
        }
        buttonRef.classList.add(backgroundClassColor);
        this.handleAnswer(guess, correct, backgroundClassColor, buttonRef);

    }

    //Helper method to modularize game logic after answer is submitted
    private handleAnswer(guess: number, correct: boolean,
                        backgroundClassColor: string, buttonRef: HTMLButtonElement){
        this.updateScore(correct);
        let scoreRow: ScoreRow = {
            id: guess,
            expectedId: this.scale.getQuality,
            gameId: this.gameId

        }
        this.scoreRows.push(scoreRow);
        this.playSample(correct);
        setTimeout(() => {
            this.resetFlashClasses();
            buttonRef.classList.remove(backgroundClassColor);
        }, 200);
    }
}
