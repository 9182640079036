<div *ngIf="!isAdmin" class="popup-overlay">
    <div class="popup-content">
        <h2>Authentication Required</h2>
        <form (ngSubmit)="onSubmit()">
        <div class="admin-credential-row">
            <label for="username">Username:</label>
            <input type="text" id="username" [(ngModel)]="username" name="username" required />
        </div>
        <div class="admin-credential-row">
            <label for="password">Password:</label>
            <input type="password" id="password" [(ngModel)]="password" name="password" required />
        </div>
        <div class="admin-submission-button">
            <button type="submit">Submit</button>
        </div>
        <div class="auth-error-message" *ngIf="errorFlag_isNotAdmin">Invalid credentials</div>
        <div class="auth-error-message" *ngIf="errorFlag_serviceUnavailable">Backend service is currently unavailable</div>
        </form>
    </div>
</div>

<div class="admin-container" *ngIf="isAdmin"> 
    <div class="record-control-container-admin">
        <input type="text" [(ngModel)]="searchEmail" (input)="filterUsersByEmail()" placeholder="Search by email" />
        <div></div>
        <button (click)="decrement()" [disabled]="pageIndex === 0">&larr;
        </button>
        <button (click)="increment()" [disabled]="users.length <= 20 || users.length <= (pageIndex * 20) + 20">&rarr;
        </button>
    </div>
    <div class="record-container-admin">
        <div class="record-attribute">User ID</div>
        <div class="record-attribute">Username</div>
        <div class="record-attribute">Email</div>
        <div class="record-attribute">Verification Status</div>
    </div>
    <div *ngFor="let user of usersDisplayed | slice:pageIndex * 20: (pageIndex * 20) + 20; let even = even; let odd = odd; let i = index"
    class="record-container-body-admin" [ngClass]="{'even-row': i % 2 === 0, 'odd-row': i % 2 !== 0}" (click)="selectUser(user)">
        <div class="record-attribute">{{user.id}}</div>
        <div class="record-attribute">{{user.username}}</div>
        <div class="record-attribute">{{user.email}}</div>
        <div class="record-attribute" [ngClass]="{'auth-error-message': user.verificationStatus == 0, 'auth-success-message': user.verificationStatus == 1  }">{{translateVerificationStatus(user.verificationStatus)}}</div>

    </div>
</div>