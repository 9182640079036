export enum ScaleQuality{
    NULL = 0,
    MAJOR = 1,
    NATURAL_MINOR = 2,
    MELODIC_MINOR = 3,
    HARMONIC_MINOR = 4,
    HARMONIC_MAJOR = 5,
    DORIAN = 6,
    PHRYGIAN = 7,
    LYDIAN = 8,
    MIXOLYDIAN = 9,
    LOCRIAN = 10,
    PERSIAN = 11,
    HUNGARIAN_MINOR = 12,
    DOUBLE_HARMONIC = 13,
    ENIGMATIC = 14,
    NEAPOLITAN_MAJOR = 15,
    NEAPOLITAN_MINOR = 16,
    IONIAN = 17,
    AEOLIAN = 18,
    HALF_WHOLE_DIMINISHED = 19
}