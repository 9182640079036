import { Measure } from "./Measure";

//Encapsulates a piece of notated music
export class Staff{
    clef: string = "Treble";
    timeSig: [number, number];
    measures: Measure[];
    title: string = "Test";
    keySig: string = "C Major";

    constructor(timeSig: [number, number], measures: Measure[]){
        this.timeSig = timeSig;
        this.measures = measures;
    }

    get getClef(): string{
        return this.clef;
    }

    set setClef(clef: string){
        this.clef = clef;
    }

    get getTimeSig(): [number, number]{
        return this.timeSig;
    }

    set setTimeSig(timeSig: [number, number]){
        this.timeSig = timeSig;
    }

    get getMeasures(): Measure[]{
        return this.measures;
    }

    set setMeasures(measures: Measure[]){
        this.measures = measures;
    }

    get getTitle(): string{
        return this.title;
    }

    set setTitle(title: string){
        this.title = title;
    }

    get getKeySig(): string{
        return this.keySig;
    }

    set setKeySig(keySig: string){
        this.keySig = keySig;
    }
}