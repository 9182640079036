import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { Staff } from '../../../staff-graphics/models/Staff';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '../../../util/SharedDataService';
import { Scale } from '../../../models/Scale';
import { StaffScale } from '../../../staff-graphics/models/StaffScale';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';

@Component({
  selector: 'app-staff-building-scale-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-building-scale-game.component.css"]
})
export class StaffBuildingScaleGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy{
    public override gameId = GameId.StaffBuilding_Scales;
    override initialCountdown: number = 900;
    private staff: Staff = new Staff([4, 4], []);
    protected override clickEnabled: boolean = true;

    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object){
        super(router, sharedDataService, dialog, route);
    }

    override ngOnInit(): void {
        this.initModule();
        const scale: Scale = this.generateRandomScale(this.isTrebleClef);
        this.randomElement = new StaffScale(scale);
    }

    ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);

            this.enableEventHandlers();
        }

    }
}
