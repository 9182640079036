export enum ChordQuality{
    Null = 0,
    Major = 1,
    MajorFirstInv = 2,
    MajorSecondInv = 3,
    Minor = 4,
    MinorFirstInv = 5,
    MinorSecondInv = 6,
    Aug = 7,
    AugFirstInv = 8,
    AugSecondInv = 9,
    Dim = 10,
    DimFirstInv = 11,
    DimSecondInv = 12,
    Dom7 = 13,
    Dom7FirstInv = 14,
    Dom7SecondInv = 15,
    Dom7ThirdInv = 16,
    Maj7 = 17,
    Maj7FirstInv = 18,
    Maj7SecondInv = 19,
    Maj7ThirdInv = 20,
    Min7 = 21,
    Min7FirstInv = 22,
    Min7SecondInv = 23,
    Min7ThirdInv = 24,
    HalfDim7 = 25,
    HalfDim7FirstInv = 26,
    HalfDim7SecondInv = 27,
    HalfDim7ThirdInv = 28,
    Dim7 = 29,
    Maj9 = 30,
    Maj9FirstInv = 31,
    Maj9SecondInv = 32,
    Maj9ThirdInv = 33,
    Maj9FourthInv = 34,
    Dom9 = 35,
    Dom9FirstInv = 36,
    Dom9SecondInv = 37,
    Dom9ThirdInv = 38,
    Dom9FourthInv = 39,
    Min9 = 40,
    Min9FirstInv = 41,
    Min9SecondInv = 42,
    Min9ThirdInv = 43,
    Min9FourthInv = 44,
    DomFlat9 = 45,
    DomFlat9FirstInv = 46,
    DomFlat9SecondInv = 47,
    DomFlatFlat9ThirdInv = 48,
    DomFlat9FourthInv = 49,
    DomSharp9 = 50,
    DomSharp9FirstInv = 51,
    DomSharp9SecondInv = 52,
    DomSharp9ThirdInv = 53,
    DomSharp9FourthInv = 54,
    Maj11 = 55,
    Maj11FirstInv = 56,
    Maj11SecondInv = 57,
    Maj11ThirdInv = 58,
    Maj11FourthInv = 59,
    Dom11 = 60,
    Dom11FirstInv = 61,
    Dom11SecondInv = 62,
    Dom11ThirdInv = 63,
    Dom11FourthInv = 64,
    Min11 = 65,
    Min11FirstInv = 66,
    Min11SecondInv = 67,
    Min11ThirdInv = 68,
    Min11FourthInv = 69,
    DomSharp11 = 70,
    DomSharp11FirstInv = 71,
    DomSharp11SecondInv = 72,
    DomSharp11ThirdInv = 73,
    DomSharp11FourthInv = 74,
    Maj13 = 75,
    Maj13FirstInv = 76,
    Maj13SecondInv = 77,
    Maj13ThirdInv = 78,
    Maj13FourthInv = 79,
    Dom13 = 80,
    Dom13FirstInv = 81,
    Dom13SecondInv = 82,
    Dom13ThirdInv = 83,
    Dom13FourthInv = 84,
    Min13 = 85,
    Min13FirstInv = 86,
    Min13SecondInv = 87,
    Min13ThirdInv = 88,
    Min13FourthInv = 89
}