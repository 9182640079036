export class RouteConstants{
    static readonly INDEX = "";
    static readonly LOGIN = "login";
    static readonly REGISTER = "register";
    static readonly VERIFY_NEW_ACCOUNT = "verify-account";
    static readonly FINISH_VERIFICATION = "finish-verification"
    static readonly SIDE_MENU = "side-menu";
    static readonly LEADERBOARD = "leaderboard";
    static readonly PROFILE = "profile";
    static readonly ABOUT_US = "about-us";
    static readonly RESET_PASSWORD = "reset-password";
    static readonly VERIFY_PASSWORD_RESET = "verify-password-reset";
    static readonly CHORD_EAR_TRAINING_MENU = "chord-ear-training-menu";
    static readonly CHORD_EAR_TRAINING_GAME = "chord-ear-training-game";
    static readonly INTERVAL_EAR_TRAINING_MENU = "interval-ear-training-menu";
    static readonly INTERVAL_EAR_TRAINING_GAME = "interval-ear-training-game";
    static readonly SCALE_EAR_TRAINING_MENU = "scale-ear-training-menu";
    static readonly SCALE_EAR_TRAINING_GAME = "scale-ear-training-game";
    static readonly CHORD_STAFF_IDENTIFICATION_MENU = "chord-staff-identification-training-menu";
    static readonly CHORD_STAFF_IDENTIFICATION_GAME = "chord-staff-identification-training-game";
    static readonly SCALE_STAFF_IDENTIFICATION_MENU = "scale-staff-identification-training-menu";
    static readonly SCALE_STAFF_IDENTIFICATION_GAME = "scale-staff-identification-training-game";
    static readonly INTERVAL_STAFF_IDENTIFICATION_MENU = "interval-staff-identification-training-menu";
    static readonly INTERVAL_STAFF_IDENTIFICATION_GAME = "interval-staff-identification-training-game";
    static readonly NOTE_STAFF_IDENTIFICATION_MENU = "note-staff-identification-training-menu";
    static readonly NOTE_STAFF_IDENTIFICATION_GAME = "note-staff-identification-training-game";
    static readonly CHORD_STAFF_BUILDING_MENU = "chord-staff-building-menu";
    static readonly CHORD_STAFF_BUILDING_GAME = "chord-staff-building-game";
    static readonly SCALE_STAFF_BUILDING_MENU = "scale-staff-building-menu";
    static readonly SCALE_STAFF_BUILDING_GAME = "scale-staff-building-game";
    static readonly INTERVAL_STAFF_BUILDING_MENU = "interval-staff-building-menu";
    static readonly INTERVAL_STAFF_BUILDING_GAME = "interval-staff-building-game";
    static readonly ADMIN_CONSOLE = "admin-console";
    static readonly CHANGELOG = "changelog";
    static readonly CANVASTEST = "test";

}