import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommonUtil } from '../../util/CommonUtil';
import { AuthDataService } from '../../data-services/AuthDataService';

@Component({
  selector: 'app-admin-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule, FormsModule],
  templateUrl: './admin-dialog.component.html',
  styleUrl: './admin-dialog.component.css'
})
export class AdminDialogComponent {
    public newUsername: string = "";
    public newPassword: string = "";

    //Success flags
    public successFlag_username: boolean = false;
    public successFlag_password: boolean = false;
    public successFlag_emailVerification: boolean = false;
    

    //Error flags
    public errorFlag_usernameIsInvalid: boolean = false;
    public errorFlag_usernameIsTaken: boolean = false;
    public errorFlag_usernameFailedToUpdate: boolean = false;
    public errorFlag_usernameServiceUnavailable: boolean = false;


    public errorFlag_passwordIsInvalid: boolean = false;
    public errorFlag_passwordFailedToUpdate: boolean = false;
    public errorFlag_passwordServiceUnavailable: boolean = false;

    public errorFlag_userAlreadyVerified: boolean = false;
    public errorFlag_verificationUpdatedFailed: boolean = false;
    public errorFlag_verificationServiceUnavailable: boolean = false;




    constructor(@Inject(MAT_DIALOG_DATA) public user: {id: number, username: string, email: string, verificationStatus: number},
                public dialogRef: MatDialogRef<AdminDialogComponent>){} 


    //Updates the username to the new value
    public async updateUsername(): Promise<void>{
        this.resetFlags();

        //Make sure the username is valid
        if(CommonUtil.isEmpty(this.newUsername) || this.newUsername.length < 6 || this.newUsername.length > 16 || 
          !CommonUtil.isAlphanumeric(this.newUsername)){
            this.errorFlag_usernameIsInvalid = true;
            return;
        }

        //Lookup username to see if it is taken
        await AuthDataService.lookupUser(this.user.email, this.newUsername).then(json => {
            if(!CommonUtil.isEmpty(json)){
                const jsonObject = JSON.parse(json);
                this.errorFlag_usernameIsTaken = jsonObject.registrationFailed_usernameTaken;
            } else {
                this.errorFlag_usernameServiceUnavailable = true;
                return;
            }
        });
        if(this.errorFlag_usernameIsTaken) return;

        //Username is available and meets requirements, proceed to update
        let updateSuccessful: boolean = false;
        await AuthDataService.updateUsername(this.user.id.toString(), this.newUsername).then(json => {
            if(!CommonUtil.isEmpty(json)){
                updateSuccessful = JSON.parse(json);
            } else {
                this.errorFlag_usernameServiceUnavailable = true;
                return;
            }
        });

        if(updateSuccessful){
            this.successFlag_username = true;
        } else {
            this.errorFlag_usernameFailedToUpdate = true;
        }
    }

    //Change the password of a user
    public async changePassword(): Promise<void>{
        this.resetFlags();

        //Make sure that the password is valid
        if(CommonUtil.isEmpty(this.newPassword) || this.newPassword.length < 8 || this.newPassword.length > 255 || 
           !CommonUtil.isValidPassword(this.newPassword)){
            this.errorFlag_passwordIsInvalid = true;
            return;
        }

        //Password is valid, proceed to update
        let updateSuccessful: boolean = false;
        await AuthDataService.updatePasswordWithID(this.user.id.toString(), this.newPassword).then(json => {
            if(!CommonUtil.isEmpty(json)){
                updateSuccessful = JSON.parse(json);
            } else {
                this.errorFlag_passwordServiceUnavailable = true;
            }
        });
        if(this.errorFlag_passwordServiceUnavailable) return;

        if(updateSuccessful){
            this.successFlag_password = true;
        } else {
            this.errorFlag_passwordFailedToUpdate = true;
        }
    }

    //Verify a user's email
    public async verifyEmail(): Promise<void>{
        this.resetFlags();

        //Check if the user is already verified
        if(this.user.verificationStatus == 1){
            this.errorFlag_userAlreadyVerified = true;
            return;
        }
        let updateSuccessful: boolean = false;
        await AuthDataService.updateEmailVerificationStatus(this.user.id.toString()).then(json => {
            if(!CommonUtil.isEmpty(json)){
                updateSuccessful = true;
            } else {
                this.errorFlag_verificationServiceUnavailable = true;
            }
        });
        if(this.errorFlag_verificationServiceUnavailable) return;

        if(updateSuccessful){
            this.successFlag_emailVerification = true;
        } else {
            this.errorFlag_verificationUpdatedFailed = true;
        }
    }

    //Error and success flags should be reset for each admin action
    private resetFlags(): void{
        this.errorFlag_usernameIsInvalid = false;
        this.errorFlag_usernameIsTaken = false;
        this.errorFlag_usernameFailedToUpdate = false;
        this.errorFlag_usernameServiceUnavailable = false;
        this.errorFlag_passwordFailedToUpdate = false;
        this.errorFlag_passwordIsInvalid = false;
        this.errorFlag_passwordServiceUnavailable = false;
        this.errorFlag_userAlreadyVerified = false;
        this.errorFlag_verificationServiceUnavailable = false;
        this.errorFlag_verificationUpdatedFailed = false;

        this.successFlag_emailVerification = false;
        this.successFlag_password = false;
        this.successFlag_username = false;
    }


}
