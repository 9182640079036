
<!-- <h3>Select a game to get started!</h3> -->
<!-- <div style="display: grid;     grid-template-rows: auto; align-items: center;">
    <h3>Select a game to get started!</h3>

</div> -->
<div class="main-container margin-top">
    <div class="logo-container logo-container-white margin-top-icon">
         <img src="/assets/icons/BeatTheory-banner.png" /> 
         <h3>Select a game to get started!</h3>
     </div>

    <div class="parent-container parent-1">
        <h3>Ear Training</h3>
    </div>
    <div id="et" class="child-container child-1" (mouseenter)="changeBackgroundColor($event)">
        <div class="child-container-item">
            <a href={{routes.INTERVAL_EAR_TRAINING_MENU}}>{{identifyIntervals}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.CHORD_EAR_TRAINING_MENU}}>{{identifyChords}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.SCALE_EAR_TRAINING_MENU}}>{{identifyScales}}</a>
        </div>
    </div>
    <div class="parent-container parent-2">
        <h3>Staff Identification</h3>
    </div> 
    <div id="si" class="child-container child-2" (mouseenter)="changeBackgroundColor($event)">
        <div class="child-container-item">
            <a href={{routes.NOTE_STAFF_IDENTIFICATION_MENU}}>{{identifyNotes}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.INTERVAL_STAFF_IDENTIFICATION_MENU}}>{{identifyIntervals}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.CHORD_STAFF_IDENTIFICATION_MENU}}>{{identifyChords}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.SCALE_STAFF_IDENTIFICATION_MENU}}>{{identifyScales}}</a>
        </div>
    </div>
    <div class="parent-container parent-3">
        <h3>Staff Building</h3>
    </div>
    <div id="sb" class="child-container child-3" (mouseenter)="changeBackgroundColor($event)">
        <div class="child-container-item">
            <a href={{routes.INTERVAL_STAFF_BUILDING_MENU}}>{{buildIntervals}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.CHORD_STAFF_BUILDING_MENU}}>{{buildChords}}</a>
        </div>
        <div class="child-container-item">
            <a href={{routes.SCALE_STAFF_BUILDING_MENU}}>{{buildScales}}</a>
        </div>
    </div>
</div>


