import { Note } from "./Note";
import { ChordQuality } from "../constants/ChordEnum";
import { CommonUtil } from "../util/CommonUtil";
import { StaffUtil } from "../util/StaffUtil";
import { IntervalQuality } from "../constants/IntervalQualityConstants";
export class Chord{
    private root: Note;
    private quality: ChordQuality = ChordQuality.Null;
    private notes: Note[] = [];

    constructor(root: Note, quality: ChordQuality, notes?: number[]){
        this.root = root;
        this.quality = quality;
        let intervalSeries: number[] = [];
        //Build the chord out using an interval series instead of a quality
        if(this.quality === ChordQuality.Null && notes != null){
            intervalSeries = notes;
            this.quality = this.getQualityFromNotes(notes);
        } else{
            intervalSeries = this.getIntervalSeries(quality);
        }

        //Construct array of chord notes
        this.notes.push(this.root);
        for(let i = 0; i < intervalSeries.length; i++){
            const nextNote: Note = this.getNextNoteInChord(this.notes[i], intervalSeries[i]);
            this.notes.push(nextNote);
        }

    }

    get getRoot(): Note{
        return this.root;
    }

    set setRoot(root: Note){
        this.root = root;
    }

    get getQuality(): number{
        return this.quality;
    }

    set setQuality(randnum: number){
        this.quality = randnum;
    }

    get getNotes(): Note[]{
        return this.notes;
    }

    set setNotes(notes: Note[]){
        this.notes = notes;
    }
    

    //returns interval series depending on chord type
    private getIntervalSeries(quality: ChordQuality): number[]{
        let res: number[] = [];
        switch(quality){
            case ChordQuality.Major:
                res =  [4, 3];
                break;
            case ChordQuality.Minor:
                res =  [3, 4];
                break;
            case ChordQuality.Aug:
                res =  [4, 4];
                break;
            case ChordQuality.Dim:
                res =  [3, 3];
                break;
            case ChordQuality.Dom7:
                res = [4, 3, 3];
                break;
            case ChordQuality.Maj7:
                res = [4, 3, 4];
                break;
            case ChordQuality.Min7:
                res = [3, 4, 3];
                break;
            case ChordQuality.HalfDim7:
                res = [3, 3, 4];
                break;
            case ChordQuality.Dim7:
                res = [3, 3, 3];
                break;
            case ChordQuality.Dom9:
                res = [4, 3, 3, 4];
                break;
            case ChordQuality.Maj9:
                res = [4, 3, 4, 3];
                break;
            case ChordQuality.Min9:
                res = [3, 4, 3, 4];
                break;
            case ChordQuality.DomFlat9:
                res = [4, 3, 3, 3];
                break;
            case ChordQuality.DomSharp9:
                res = [4, 3, 3, 5];
                break;
            case ChordQuality.Dom11:
                res = [4, 3, 3, 4, 3];
                break;
            case ChordQuality.Maj11:
                res = [4, 3, 4, 3, 3];
                break;
            case ChordQuality.Min11:
                res = [3, 4, 3, 4, 3];
                break;
            case ChordQuality.DomSharp11:
                res = [4, 3, 3, 4, 4];
                break;
            case ChordQuality.Dom13:
                res = [4, 3, 3, 4, 3, 4];
                break;
            case ChordQuality.Maj13:
                res = [4, 3, 4, 3, 3, 4];
                break;
            case ChordQuality.Min13:
                res = [3, 4, 3, 4, 3, 4];
                break;
            default:
                res = [];
                break;
        }        

        return res;
    }

    //Returns chord quality based on interval series
    private getQualityFromNotes(notes: number[]): ChordQuality{
        //Only one interval is present, so return as null
        if(notes.length < 2){
            return ChordQuality.Null;
        }

        const stringifiedNotes: string = notes.toString();
        switch(stringifiedNotes){
            case "4,3":
                return ChordQuality.Major;
            case "3,4":
                return ChordQuality.Minor;
            case "4,4":
                return ChordQuality.Aug;
            case "3,3":
                return ChordQuality.Dim;
            case "4,3,3":
                return ChordQuality.Dom7;
            case "4,3,4":
                return ChordQuality.Maj7;
            case "3,4,3":
                return ChordQuality.Min7;
            case "3,3,4":
                return ChordQuality.HalfDim7;
            case "3,3,3":
                return ChordQuality.Dim7;
            case "4,3,3,4":
                return ChordQuality.Dom9;
            case "4,3,4,3":
                return ChordQuality.Maj9;
            case "3,4,3,4":
                return ChordQuality.Min9;
            case "4,3,3,3":
                return ChordQuality.DomFlat9;
            case "4,3,3,5":
                return ChordQuality.DomSharp9;
            case "4,3,3,4,3":
                return ChordQuality.Dom11;
            case "4,3,4,3,3":
                return ChordQuality.Maj11;
            case "3,4,3,4,3":
                return ChordQuality.Min11;
            case "4,3,3,4,4":
                return ChordQuality.DomSharp11;
            case "4,3,3,4,3,4":
                return ChordQuality.Dom13;
            case "4,3,4,3,3,4":
                return ChordQuality.Maj13;
            case "3,4,3,4,3,4":
                return ChordQuality.Min13;
            default:
                return ChordQuality.Null;
        }


    }

    //Gets the next note in the chord 
    private getNextNoteInChord(note: Note, interval: number): Note{
        let res: string = "";
        let root: string = CommonUtil.splitString(note.getFullName).letter;
        let octave: number = CommonUtil.splitString(note.getFullName).number;

        res += this.getNextChordNoteName(root);
        if(root[0] === 'E' || root[0] === 'B'){
            if(StaffUtil.isDoubleFlat(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'bb';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'b';
                }
            }
            else if(StaffUtil.isFlat(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'b';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += '#';
                }
            } else if(StaffUtil.isSharp(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += '#';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'x';
                }
            }
            else if(StaffUtil.isDoubleSharp(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'x';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'xx';
                }
            } else{
                if(interval == IntervalQuality.MAJOR_THIRD){
                    res += '#';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += 'x';
                }
            }
        } else if (root[0] === 'A' || root[0] === 'D'){
            if(StaffUtil.isDoubleFlat(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'bb';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'b';
                }
            } else if(StaffUtil.isFlat(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'b';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += '#';
                }
            } else if(StaffUtil.isSharp(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += '#';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'x';
                }
            } else if(StaffUtil.isDoubleSharp(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'x';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'xx';
                }
            } else{
                if(interval == IntervalQuality.MAJOR_THIRD){
                    res += '#';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += 'x';
                }
            }
        } else {
            if(StaffUtil.isDoubleFlat(root)){
                if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'bb';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += 'b';
                }
            } else if(StaffUtil.isFlat(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'bb';
                } else if (interval == IntervalQuality.MAJOR_THIRD){
                    res += 'b';
                }

            } else if(StaffUtil.isSharp(root)){
                if(interval == IntervalQuality.MAJOR_THIRD){
                    res += '#';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += 'x';
                }
            } else if(StaffUtil.isDoubleSharp(root)){
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += '#';
                } else if(interval == IntervalQuality.MAJOR_THIRD){
                    res += 'xx';
                }
            } else{
                if(interval == IntervalQuality.MINOR_THIRD){
                    res += 'b';
                } else if(interval == IntervalQuality.PERFECT_FOURTH){
                    res += '#';
                }
            }
        }

        //If current note is A or B, move the octave up one
        if(root[0] === 'A' || root[0] === 'B'){
            octave += 1;
        }
        res += octave;

        const nextNote: Note = new Note(res, note.getClef);
        return nextNote;
    }

    //Returns next chord note name depending on the root value
    private getNextChordNoteName(root: string): string{
        switch(root[0].toUpperCase()){
            case "C":
                return "E";
            case "D":
                return "F";
            case "E":
                return "G";
            case "F":
                return "A";
            case "G":
                return "B";
            case "A":
                return "C";
            case "B":
                return "D";   
            default:
                return "";         
        }
    }
}