import { Router } from "@angular/router";
import { GameId } from "../constants/GameIdEnum";
import { RouteConstants } from "../constants/RouteConstants";

//Utility class that handles routing and redirection
export class RoutingUtil{
    //Navigates to appropriate menu endpoint depending on game id
    public static navigateToMenu(gameId: number, router: Router){
        switch(gameId){
            case GameId.EarTraining_Chords:
                router.navigate([RouteConstants.CHORD_EAR_TRAINING_MENU]);
                break;
            case GameId.EarTraining_Intervals:
                router.navigate([RouteConstants.INTERVAL_EAR_TRAINING_MENU]);
                break;
            case GameId.EarTraining_Scales:
                router.navigate([RouteConstants.SCALE_EAR_TRAINING_MENU]);
                break;
            case GameId.StaffIdentification_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffIdentification_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffIdentification_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffIdentification_Notes:
                router.navigate([RouteConstants.NOTE_STAFF_IDENTIFICATION_MENU]);
                break;
            case GameId.StaffBuilding_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_BUILDING_MENU]);
                break;
            case GameId.StaffBuilding_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_BUILDING_MENU]);
                break;
            case GameId.StaffBuilding_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_BUILDING_MENU]);
                break;

            default:
                router.navigate(['/']);
                break;
        }
    }

    //Navigates to appropriate game endpoint depending on game id
    public static navigateToGame(gameId: number, router: Router){
        switch(gameId){
            case GameId.EarTraining_Chords:
                router.navigate([RouteConstants.CHORD_EAR_TRAINING_GAME]);
                break;
            case GameId.EarTraining_Intervals:
                router.navigate([RouteConstants.INTERVAL_EAR_TRAINING_GAME]);
                break;
            case GameId.EarTraining_Scales:
                router.navigate([RouteConstants.SCALE_EAR_TRAINING_GAME]);
                break;
            case GameId.StaffIdentification_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffIdentification_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffIdentification_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffIdentification_Notes:
                router.navigate([RouteConstants.NOTE_STAFF_IDENTIFICATION_GAME]);
                break;
            case GameId.StaffBuilding_Chords:
                router.navigate([RouteConstants.CHORD_STAFF_BUILDING_GAME]);
                break;
            case GameId.StaffBuilding_Scales:
                router.navigate([RouteConstants.SCALE_STAFF_BUILDING_GAME]);
                break;
            case GameId.StaffBuilding_Intervals:
                router.navigate([RouteConstants.INTERVAL_STAFF_BUILDING_GAME]);
                break;
            default:
                router.navigate(['/']);
                break;
        }
    }
}