import { Note } from "../../models/Note";
import { StaffElement } from "./StaffElement";

export class StaffNote extends StaffElement{
    private fullName: string;
    private clef: boolean;
    private yPos: number;
    private note: Note;

    constructor(note: Note){
        super();
        this.fullName = note.getFullName;
        this.clef = note.getClef;
        this.yPos = this.calculateYPos(this.fullName);
        this.note = note;
    }

    get getFullName(): string{
        return this.fullName;
    }

    set setFullName(fullName: string){
        this.fullName = fullName;
    }

    get getYPos(): number{
        return this.yPos;
    }

    set setYPos(yPos: number){
        this.yPos = yPos;
    }

    get getNote(): Note{
        return this.note;
    }

    set setNote(note: Note){
        this.note = note;
    }

    get getClef(): boolean{
        return this.clef;
    }

    set setClef(clef: boolean){
        this.clef = clef;
    }

    get getName(): string{
        return this.fullName;
    }

    //Calculates the y position of the note and adjusts based on selected clef
    private calculateYPos(fullName: string): number{
        let root = fullName[0];
        let octave = fullName[fullName.length - 1];
        let val = root + octave;
        

        //Treble clef
        if(this.clef){
            switch(val){
                case "F3":
                    return 250;
                case "G3":
                    return 240;
                case "A3":
                    return 230;
                case "B3":
                    return 220;
                case "C4":
                    return 210; 
                case "D4":
                    return 200;
                case "E4":
                    return 190;
                case "F4":
                    return 180;
                case "G4":
                    return 170;
                case "A4":
                    return 160;
                case "B4":
                    return 150;
                case "C5":
                    return 140;
                case "D5":
                    return 130;
                case "E5":
                    return 120;
                case "F5":
                    return 110;
                case "G5":
                    return 100;
                case "A5":
                    return 90;
                case "B5":
                    return 80;
                case "C6":
                    return 70;
                case "D6":
                    return 60;
                case "E6":
                    return 50;
                
                default:
                    return -1;
            }
        //bass clef
        } else {
            switch(val){
                case "A1":
                    return 250;
                case "B1":
                    return 240;
                case "C2":
                    return 230;
                case "D2":
                    return 220;
                case "E2":
                    return 210; 
                case "F2":
                    return 200;
                case "G2":
                    return 190;
                case "A2":
                    return 180;
                case "B2":
                    return 170;
                case "C3":
                    return 160;
                case "D3":
                    return 150;
                case "E3":
                    return 140;
                case "F3":
                    return 130;
                case "G3":
                    return 120;
                case "A3":
                    return 110;
                case "B3":
                    return 100;
                case "C4":
                    return 90;
                case "D4":
                    return 80;
                case "E4":
                    return 70;
                case "F4":
                    return 60;
                case "G4":
                    return 50;
                
                default:
                    return -1;
            }
        }
    }
}