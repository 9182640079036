import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from "../../../tooltip/tooltip.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { SharedDataService } from '../../../util/SharedDataService';
import { DifficultySelectorComponent } from '../../difficulty-selector.component';
import { GameId } from '../../../constants/GameIdEnum';
import { AuthenticationService } from '../../../auth/service/AuthenticationService';
import { SpinnerComponent } from '../../../graphics/spinner/spinner.component';

@Component({
  selector: 'app-interval-ear-training-difficulty-selector',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TooltipComponent, MatTooltipModule, SpinnerComponent],
  templateUrl: '../../difficulty-selector.component.html',
  styleUrl: '../../difficulty-selector.component.css'
})
export class IntervalEarTrainingDifficultySelectorComponent extends DifficultySelectorComponent {
    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override el: ElementRef, 
        protected override authService: AuthenticationService, @Inject(PLATFORM_ID) protected override platformId: Object, protected override renderer: Renderer2){
        super(router, sharedDataService, el, authService, platformId, renderer);
        this.gameId = GameId.EarTraining_Intervals
        this.title = "Ear Training";
        this.subtitle = "Identify Intervals";
        this.description = "Identity the interval between the two notes";
        this.description_practiceMode = "No time limit, practice identifying intervals for as long as you want.";
        this.description_challengeMode = "Race against the clock to get as many answers correct before time runs out.";
        this.easyButtonTooltip = "Thirds, Fifths, and Octaves";
        this.mediumButtonTootip = "Diatonic Intervals Relative to Major Scale";
        this.hardButtonTooltip = "All Diatonic Intervals";
    }
}
