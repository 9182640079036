import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { SpinnerComponent } from '../../graphics/spinner/spinner.component';
import { CommonUtil } from '../../util/CommonUtil';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AuthDataService } from '../../data-services/AuthDataService';


@Component({
  selector: 'app-verify-password-reset',
  standalone: true,
  imports: [FormsModule, SpinnerComponent, CommonModule, ReactiveFormsModule],
  templateUrl: './verify-password-reset.component.html',
  styleUrl: './verify-password-reset.component.css'
})
export class VerifyPasswordResetComponent implements OnInit{
    public resetPasswordForm!: FormGroup

    public passwordResetError_passwordIsInvalid: boolean = false;
    public passwordResetError_passwordsDoNotMatch: boolean = false;
    public passwordResetError_passwordFailedToUpdate: boolean = false;

    public updatingPassword: boolean = false;
    public updateSuccessful: boolean = false;

    constructor(private fb: FormBuilder, private actRoute: ActivatedRoute, private router: Router){}
    
    public ngOnInit(): void {
        //Build out the form
        this.buildForm();
    }

    //Builds out the form group and makes both inputs required for submission
    private buildForm(): void{
        this.resetPasswordForm = this.fb.group({
           password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(255)]],
           verifyPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(255)]]
        });
    }

    //Handles button submission
    public async onSubmit(): Promise<void>{
        //Reset error flags
        this.passwordResetError_passwordIsInvalid = false;
        this.passwordResetError_passwordsDoNotMatch = false;
        this.passwordResetError_passwordFailedToUpdate = false;

        //Check for valid password
        const password: string = this.resetPasswordForm.get("password")?.value;
        const verifyPassword: string = this.resetPasswordForm.get("verifyPassword")?.value;
        if(!CommonUtil.isValidPassword(password)){
            this.passwordResetError_passwordIsInvalid = true;
            this.updatingPassword = false;
            return;
        } else if(password !== verifyPassword){
            this.passwordResetError_passwordsDoNotMatch = true;
            this.updatingPassword = false;
            return;
        }

        //Passwords are valid and match, proceed to update password in backend
        //Retrieve token value from URI param
        const token: string =  this.actRoute.snapshot.queryParamMap.get('token') as string;

        //Verify that the password was updated
        this.updatingPassword = true;
        await AuthDataService.updatePassword(token, password).then(async json => {
            if(!CommonUtil.isEmpty(json)){
                const isUpdated: boolean = JSON.parse(json).passwordUpdated;
                if(!isUpdated){
                    this.passwordResetError_passwordFailedToUpdate = true;
                    this.updatingPassword = false;
                    return;
                }

                //Password has been updated, proceed to delete old token
                await AuthDataService.deletePasswordResetToken(token);
                this.updateSuccessful = true;
            }else{
                this.passwordResetError_passwordFailedToUpdate = true;
                this.updatingPassword = false;
                return;
            }
        });

        this.updatingPassword = false;    
    }

    public navigateToLogin(): void{
        this.router.navigate(['/login']);
    }
}
