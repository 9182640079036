import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) { }

    public logout(): void {
        this.clearToken();
        this.clearAuthSessionVariables();
    }

    public isLoggedIn(): boolean {
        return isPlatformBrowser(this.platformId) && !!localStorage.getItem('token');
    }

    public setToken(token: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('token', token);
        }
    }

    public getToken(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem('token');
        }
        return null;
    }

    public clearToken(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('token');
        }
    }

    public setId(id: number): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('userId', id.toString());
        }
    }

    public getId(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem('userId');
        }
        return null;
    }

    public clearId(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('userId');
        }
    }

    public setEmail(email: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('email', email);
        }
    }

    public getEmail(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem('email');
        }
        return null;
    }

    public clearEmail(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('email');
        }
    }

    public setUsername(username: string): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('username', username);
        }
    }

    public getUsername(): string | null {
        try {
            if (isPlatformBrowser(this.platformId)) {
                return localStorage.getItem('username');
            }
            return null;
        } catch (error) {
            // console.error("There was an error with fetching the username from local storage");
            return "";
        }
    }

    public clearUsername(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem('username');
        }
    }

    public setProfilePicture(img: Blob): void {
        if (isPlatformBrowser(this.platformId)) {
            const reader: FileReader = new FileReader();
            const key: string = "profilePicture";
            reader.onload = () => {
                const str: string = reader.result as string;
                if (str === "") {
                    localStorage.setItem(key, "assets/icons/treble-clef.png");
                } else {
                    localStorage.setItem(key, str);
                }
            };
            reader.readAsDataURL(img);
        }
    }

    public getProfilePicture(): string | null {
        if (isPlatformBrowser(this.platformId)) {
            return localStorage.getItem("profilePicture");
        }
        return null;
    }

    public clearProfilePicture(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("profilePicture");
        }
    }

    public setDifficultySelection(difficulty: string): void{
        if(isPlatformBrowser(this.platformId)){
            sessionStorage.setItem("difficulty", difficulty);
        }
    }

    public getDifficultySelection(): string | null{
        if(isPlatformBrowser(this.platformId)){
            return sessionStorage.getItem("difficulty");
        }
        return null;
    }

    public clearDifficultySelection(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("difficulty");
        }
    }

    public setGameMode(mode: string): void{
        if(isPlatformBrowser(this.platformId)){
            sessionStorage.setItem("gameMode", mode);
        }
    }

    public getGameMode(): string | null{
        if(isPlatformBrowser(this.platformId)){
            return sessionStorage.getItem("gameMode");
        }
        return null;
    }

    public clearGameMode(): void {
        if (isPlatformBrowser(this.platformId)) {
            localStorage.removeItem("gameMode");
        }
    }

    // Sets both the username and user id
    public setAuthSessionVariables(id: number, username: string, email: string): void {
        this.setId(id);
        this.setUsername(username);
        this.setEmail(email);
    }

    // Clears login credentials
    public clearAuthSessionVariables(): void {
        this.clearId();
        this.clearUsername();
        this.clearProfilePicture();
        this.clearEmail();
        this.clearDifficultySelection();
        this.clearGameMode();
    }

    // Print all the vars in local storage
    public printLocalStorage(): void {
        if (isPlatformBrowser(this.platformId)) {
            console.log('Printing all local storage key-value pairs:');
            for (let i = 0; i < localStorage.length; i++) {
                const key: string = localStorage.key(i) as string;
                if (key !== null) {
                    const value: string = localStorage.getItem(key) as string;
                    console.log(`Key: ${key}, Value: ${value}`);
                }
            }
        }
    }
}
