import * as Tone from 'tone';
import { Chord } from "../models/Chord";
import { Interval } from '../models/Interval';
import { Note } from '../models/Note'; 
import { AudioUtil } from './AudioUtil';
 
//Utility class for handling Tone.js sampler metho ds, particularly related to playback functionality
export class PianoUtil{
    private static piano: Tone.Sampler;
    constructor(){

    }

    public static async playChord(chord: Chord): Promise<number>{
        let audioPath: string = chord.getNotes[0].getAudioPath;
        let res: number = 0;
        let chordType: number = chord.getQuality;

        //Omit the fifth and ninth for 13th chords
        const tempArray: Note[] = chord.getNotes;
        if(chordType == 55 || chordType == 60 || chordType == 65 || chordType == 70){
            const newNoteArray: Note[] = this.omitNotes([2], chord);
            chord.setNotes = newNoteArray;
        }
        if(chordType == 75 || chordType == 80 || chordType == 85){
            const newNoteArray: Note[] = this.omitNotes([2, 4], chord);
            chord.setNotes = newNoteArray;
        }

        //Set up url mappings
        let urlMappings: Urls = {};
        chord.getNotes.forEach(note => {
            urlMappings[note.getFullName] = note.getAudioPath;
        })

        

        await fetch(audioPath)
        .then(response => response.arrayBuffer())
        .then(arrayBuffer => {
            const audioContext = new AudioContext();
            return audioContext.decodeAudioData(arrayBuffer);
        })
        .then(audioBuffer => {
            const duration = audioBuffer.duration * 1000;
            res = duration;
        })
        .catch(e => console.error(e));


        // this.piano = new Tone.Sampler({
        //     urls: urlMappings,
        //     baseUrl: '/',
        //     onload: async() => {
        //         const noteDuration: string = "1n";
        //         for(const note in urlMappings){
        //             this.piano.triggerAttackRelease([note], noteDuration);
        //         }
        //     }
        // }).toDestination();

        chord.getNotes.forEach(async (note) => {
            let path: string = note.getAudioPath;
            let lengthModifier: number = 1;
            await AudioUtil.sliceAndPlaySample(path, lengthModifier).then(source => {
                // source.onended = () => {
                //     AudioUtil.sliceAndPlaySample(audioPath2, lengthModifier)
                // }
            })
            .catch(error => console.error("Error with playing note series: ", error));
        })
        

        //Reset old note array
        chord.setNotes = tempArray;

        return res;
    }
    

    //Helper method to omit chord from upper extension chords
    private static omitNotes(arr: number[], chord: Chord): Note[]{
        let newNoteArray: Note[] = [];
        for(let i = 0; i < chord.getNotes.length; i++){
            if(!arr.includes(i)){
                newNoteArray.push(chord.getNotes[i]);
            }
        }
        return newNoteArray;
    }

    //Release sampler attack
    public static releaseSamplerNotes(): void{
        if(this.piano !== undefined){
            this.piano.releaseAll();
        }
    }

    public async playInterval(interval: Interval){
        let audioPath: string = interval.getNote1.getAudioPath;
        let audioPath2: string = interval.getNote2.getAudioPath;
        let lengthModifier: number = 3; //Plays a fourth of the audio file
        await AudioUtil.sliceAndPlaySample(audioPath, lengthModifier).then(source => {
            source.onended = () => {
                AudioUtil.sliceAndPlaySample(audioPath2, lengthModifier)
            }
        })
        .catch(error => console.error("Error with playing note series: ", error));
    }

    public async playNotes(notes: Note[], index: number){
        let audioPath: string = notes[index].getAudioPath;
        let lengthModifier: number = 4; //Plays a fourth of the audio file
        await AudioUtil.sliceAndPlaySample(audioPath, lengthModifier).then(source => {
            source.onended = () => {
                if(index < notes.length - 1){
                    index++;
                    this.playNotes(notes, index);
                }
                
            }
        })
        .catch(error => console.error("Error with playing note series: ", error));
    }


    public static delay(ms: number): Promise<void> {
        return new Promise(resolve => setTimeout(resolve, ms));
    }
}

type Urls = {
    [name: string]: string;
}