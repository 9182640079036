export enum GameId{
    EarTraining_Chords = 1,
    EarTraining_Intervals = 2,
    EarTraining_Scales = 3,
    StaffIdentification_Chords = 4,
    StaffIdentification_Scales = 5,
    StaffIdentification_Intervals = 6,
    StaffIdentification_Notes = 7,
    StaffBuilding_Chords = 8,
    StaffBuilding_Scales = 9,
    StaffBuilding_Intervals = 10
}