import { Component, OnInit, Renderer2 } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameCategoryItemComponent } from "./game-category-item/game-category-item.component";
import { GameCategoryComponent } from "./game-category/game-category.component";
import { RouteConstants } from '../constants/RouteConstants';
import { NavigationStart, NavigationEnd, NavigationCancel, NavigationError, Router } from '@angular/router';

@Component({
    selector: 'app-index',
    standalone: true,
    templateUrl: './index.component.html',
    styleUrl: './index.component.css',
    imports: [CommonModule, GameCategoryItemComponent, GameCategoryComponent]
})

//Home page component
export class IndexComponent implements OnInit{
    // Readonly values injected into html template
    public readonly earTrainingTitle: string = "Ear Training";
    public readonly staffIdentificationTitle: string = "Staff Identification";
    public readonly staffBuildingTitle: string = "Staff Building";
    public readonly identifyChords: string = "Identify Chords";
    public readonly identifyScales: string = "Identify Scales";
    public readonly identifyIntervals: string = "Identify Intervals";
    public readonly identifyNotes: string = "Identify Notes";
    public readonly buildChords: string = "Build Chords";
    public readonly buildScales: string = "Build Scales";
    public readonly buildIntervals: string = "Build Intervals";

    //Determines background color cascade
    public hoverET: boolean = false;
    public hoverSI: boolean = false;
    public hoverSB: boolean = false;

    
    public async ngOnInit(): Promise<void> {
        //Check cache and preload all audio files
        if (typeof window !== 'undefined' && 'caches' in window){
            const cache: Cache = await caches.open("audio-sample-cache-v1");    
        }

    }
    //Used to reference RouteConstants values in the DOM
    public routes = RouteConstants;
    loading = false;

    constructor(private router: Router, private renderer: Renderer2) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.loading = true;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.loading = false;
            }
        });

        
    }

    //Change background color based on link hover
    public changeBackgroundColor(event: MouseEvent): void{
        // const elementId: string = (event.target as HTMLElement).id;
        // switch(elementId){
        //     case 'et':
        //         this.hoverET = true;
        //         this.hoverSB = false;
        //         this.hoverSI = false;
        //         this.addNewBackgroundColorClass('background-color-primary');
        //         break;
        //     case 'si':
        //         this.hoverET = false;
        //         this.hoverSB = false;
        //         this.hoverSI = true;
        //         this.addNewBackgroundColorClass('background-color-secondary');
        //         break;
        //     case 'sb':
        //         this.hoverET = false;
        //         this.hoverSB = true;
        //         this.hoverSI = false;
        //         this.addNewBackgroundColorClass('background-color-tertiary');
        //         break;
        // }
    }

    //Reset main classes and add the new class
    private addNewBackgroundColorClass(className: string): void{
        const main = document.querySelector('main');
        this.renderer.setAttribute(main, 'class', '');
        this.renderer.addClass(main, className);
    }
}
