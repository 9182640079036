import { Component } from '@angular/core';

@Component({
  selector: 'app-unavailable',
  standalone: true,
  imports: [],
  templateUrl: './unavailable.component.html',
  styleUrl: './unavailable.component.css'
})
export class UnavailableComponent {

}
