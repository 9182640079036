import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { StaffGameHandlerComponent } from '../../staff-game-handler/staff-game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { Staff } from '../../../staff-graphics/models/Staff';
import { StaffBuilder } from '../../../staff-graphics/StaffBuilder';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { SharedDataService } from '../../../util/SharedDataService';
import { StaffChord } from '../../../staff-graphics/models/StaffChord';
import { Chord } from '../../../models/Chord';
import { StaffUtil } from '../../../util/StaffUtil';

@Component({
  selector: 'app-staff-building-chord-game',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './../../staff-game-handler/staff-game-handler.component.html',
  styleUrls: ['./../../staff-game-handler/staff-game-handler.component.css', "./staff-building-chord-game.component.css"]
})
export class StaffBuildingChordGameComponent extends StaffGameHandlerComponent implements OnInit, OnDestroy{
    public override gameId = GameId.StaffBuilding_Chords;
    override initialCountdown: number = 600;
    private staff: Staff = new Staff([4, 4], []);
    protected override clickEnabled: boolean = true;
    
    constructor(protected override router: Router, protected override sharedDataService: SharedDataService, protected override dialog: MatDialog, 
                protected override route: ActivatedRoute, @Inject(PLATFORM_ID) private platformId: Object){
        super(router, sharedDataService, dialog, route);
    }

    override ngOnInit(): void {
        this.initModule();
        const chord: Chord = this.generateRandomChord(this.isTrebleClef);
        this.randomElement = new StaffChord(chord);


    }
    ngAfterViewInit(){
        if(isPlatformBrowser(this.platformId)){
            this.setCanvasSize(); 
            this.builder = new StaffBuilder(this.staff, this.canvas.nativeElement, this.gameId);
            this.builder.drawSimpleStaff(this.randomElement, this.isTrebleClef);
            if(this.randomElement instanceof StaffChord){
                if(StaffUtil.isPitchModified(this.randomElement.getNotes[0].getNote.getRoot)){
                    this.builder.pitchCount += 1;
                }
            }
            

            this.enableEventHandlers();
        }

    }
}
