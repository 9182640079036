import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
// import * as paper from 'paper';
// import { Color } from 'paper/dist/paper-core';

@Component({
  selector: 'app-canvas-test',
  standalone: true,
  imports: [],
  templateUrl: './canvas-test.component.html',
  styleUrl: './canvas-test.component.css'
})
export class CanvasTestComponent implements AfterViewInit{

    @ViewChild('staffCanvas') staffCanvas!: ElementRef<HTMLCanvasElement>;


    constructor(@Inject(PLATFORM_ID) protected platformId: Object){
               
    }
    public ngAfterViewInit(): void {
        if(isPlatformBrowser(this.platformId)){

        } 
    }

}
