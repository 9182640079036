import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { UserDataService } from '../../../data-services/UserDataService';
import { AuthenticationService } from '../../../auth/service/AuthenticationService';
import { CommonUtil } from '../../../util/CommonUtil';


@Component({
  selector: 'app-profile-dialog',
  standalone: true,
  imports: [CommonModule, MatDialogModule],
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.css', '../../profile.component.css']
})
export class ProfileDialogComponent {
    public uploadError_usernameUnavailable: boolean = false;
    public uploadError_failedToUpload: boolean = false;
    public profileImageUrl: string | ArrayBuffer | null | undefined = '';
    public currentImage: string = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: { profilePicUrl: string }, public dialogRef: MatDialogRef<ProfileDialogComponent>,
                private authService: AuthenticationService){
        this.profileImageUrl = data.profilePicUrl;
        this.currentImage = this.profileImageUrl;
    }
    
    //Open file explorer and allow user to select .jpg or .png file type
    public upload(): void{
        document.getElementById('upload_file')?.click();
    }

    //Handles select image
    public uploadImage(event: any){
        const file = event.target.files[0];
        if(file){
            const reader: FileReader = new FileReader();
            reader.onload = (e) => {     
                if(e.target !== null && e.target !== undefined){
                    this.profileImageUrl = e.target.result as string;
                }           
            }
            reader.readAsDataURL(file);
        }
        
    }

    //If user clicks cancel button, close the dialog
    public cancel(): void{
        this.dialogRef.close();
    }

    //Confirms the user's image selection
    public confirm(): void{
        //Check that an image was selected
        if(this.profileImageUrl !== null && this.profileImageUrl !== undefined &&
           this.profileImageUrl !== this.currentImage){
            this.profileImageUrl = this.profileImageUrl as string;
            this.convertUrlToBlob(this.profileImageUrl).then(async blob => {
                if(blob != null){
                    //Retrieve the username from the session
                    let jsonString: string = "";
                    const username: string = this.authService.getUsername() as string;
                    if(!CommonUtil.isEmpty(username)){
                        //Update new profile picture
                        jsonString = await UserDataService.setUserProfilePicture(blob, username); //Set the new profile picture in the database
                    } else {
                        // console.error("Username could not be retrieved from session, closing dialog");
                        this.uploadError_usernameUnavailable = true;
                    }

                    //Check for valid response from setUserProfilePicture method
                    const pictureUpdated: boolean = JSON.parse(jsonString).updateSuccessful;
                    if(pictureUpdated){
                        //Update local storage to show new profile picture
                        this.authService.setProfilePicture(blob);
                        this.dialogRef.close(blob);
                        window.location.reload();
                    } else {
                        this.uploadError_failedToUpload = true;
                    }

                    
                }
                
            });
        } else {
            this.dialogRef.close();
        }
    }

    //Converts the image into a blob object
    private async convertUrlToBlob(url: string): Promise<Blob> {
        const response: Response = await fetch(url);
        const blob: Blob = await response.blob();
        return blob;
    }
}
