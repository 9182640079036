import { ScaleQuality } from "../constants/ScaleQualityConstants";
import { Note } from "./Note";
import { CommonUtil } from "../util/CommonUtil";
import { StaffUtil } from "../util/StaffUtil";

export class Scale{
    private notes: Note[];
    private intervals: string[];
    private quality: ScaleQuality;

    constructor(quality: ScaleQuality, note: Note, intervalSeries?: number[]){
        if(intervalSeries != null){
            this.quality = this.getScaleQuality(intervalSeries);
        } else {
            this.quality = quality;
        }
        this.intervals = this.getIntervalSeries(this.quality);
        this.notes = this.generateNoteArray(note, note.getClef, this.intervals);
    }

    get getNotes(): Note[]{
        return this.notes;
    }

    set setNotes(notes: Note[]){
        this.notes = notes;
    }

    get getIntervals(): string[]{
        return this.intervals;
    }

    set setIntervals(intervals: string[]){
        this.intervals = intervals;
    }

    get getQuality(): ScaleQuality{
        return this.quality;
    }

    set setQuality(quality: ScaleQuality){
        this.quality = quality;
    }

    //Returns the interval series string array based on the scale quality param
    private getIntervalSeries(quality: ScaleQuality): string[]{
        let res: string[] = [];
        switch(quality){
            case(ScaleQuality.MAJOR):
                res = ['W', 'W', 'H', 'W', 'W', 'W', 'H'];
                break;
            case(ScaleQuality.NATURAL_MINOR):
                res = ['W', 'H', 'W', 'W', 'H', 'W', 'W'];
                break;
            case(ScaleQuality.MELODIC_MINOR):
                res = ['W', 'H', 'W', 'W', 'W', 'W', 'H'];
                break;
            case(ScaleQuality.HARMONIC_MINOR):
                res = ['W', 'H', 'W', 'W', 'H', 'WH', 'H'];
                break;
            case(ScaleQuality.IONIAN):
                res = ['W', 'W', 'H', 'W', 'W', 'W', 'H'];
                break;
            case(ScaleQuality.DORIAN):
                res = ['W', 'H', 'W', 'W', 'W', 'H', 'W'];
                break;
            case(ScaleQuality.PHRYGIAN):
                res = ['H', 'W', 'W', 'W', 'H', 'W', 'W'];
                break;
            case(ScaleQuality.LYDIAN):
                res = ['W', 'W', 'W', 'H', 'W', 'W', 'H'];
                break;
            case(ScaleQuality.MIXOLYDIAN):
                res = ['W', 'W', 'H', 'W', 'W', 'H', 'W'];
                break;
            case(ScaleQuality.AEOLIAN):
                res = ['W', 'H', 'W', 'W', 'H', 'W', 'W'];
                break;
            case(ScaleQuality.LOCRIAN):
                res = ['H', 'W', 'W', 'H', 'W', 'W', 'W'];
                break;
            case(ScaleQuality.HARMONIC_MAJOR):
                res = ['W', 'W', 'H', 'W', 'H', 'WH', 'H'];
                break;
            case(ScaleQuality.PERSIAN):
                res = ['H', 'WH', 'H', 'H', 'W', 'WH', 'H'];
                break;
            case(ScaleQuality.ENIGMATIC):
                res = ['H', 'WH', 'W', 'W', 'W', 'H', 'H'];
                break;
            case(ScaleQuality.NEAPOLITAN_MAJOR):
                res = ['H', 'W', 'W', 'W', 'W', 'W', 'H'];
                break;
            case(ScaleQuality.NEAPOLITAN_MINOR):
                res = ['H', 'W', 'W', 'W', 'H', 'WH', 'H'];
                break;
            case(ScaleQuality.DOUBLE_HARMONIC):
                res = ['H', 'WH', 'H', 'W', 'H', 'WH', 'H'];
                break;
            case(ScaleQuality.HUNGARIAN_MINOR):
                res = ['W', 'H', 'WH', 'H', 'H', 'WH', 'H'];
                break;
            default:
                break;
        }

        return res;
    }

    //Returns the scale quality based on the intervalSeries param
    private getScaleQuality(intervalSeries: number[]): ScaleQuality{
        const stringifiedNotes: string = intervalSeries.toString();
        switch(stringifiedNotes){
            case "2,2,1,2,2,2,1":
                return ScaleQuality.MAJOR;
            case "2,1,2,2,1,2,2":
                return ScaleQuality.NATURAL_MINOR;
            case "2,1,2,2,2,2,1":
                return ScaleQuality.MELODIC_MINOR;
            case "2,1,2,2,1,3,1":
                return ScaleQuality.HARMONIC_MINOR;
            case "2,1,2,2,2,1,2":
                return ScaleQuality.DORIAN;
            case "1,2,2,2,1,2,2":
                return ScaleQuality.PHRYGIAN;
            case "2,2,2,1,2,2,1":
                return ScaleQuality.LYDIAN;
            case "2,2,1,2,2,1,2":
                return ScaleQuality.MIXOLYDIAN;
            case "1,2,2,1,2,2,2":
                return ScaleQuality.LOCRIAN;
            case "2,2,1,2,1,3,2":
                return ScaleQuality.HARMONIC_MAJOR;
            case "1,3,1,1,2,3,1":
                return ScaleQuality.PERSIAN;
            case "1,3,2,2,2,1,1":
                return ScaleQuality.ENIGMATIC;
            case "1,2,2,2,2,2,2,1":
                return ScaleQuality.NEAPOLITAN_MAJOR;
            case "1,2,2,2,2,2,1":
                return ScaleQuality.NEAPOLITAN_MINOR;
            case "1,2,2,2,1,3,1":
                return ScaleQuality.HALF_WHOLE_DIMINISHED;
            case "1,3,1,2,1,3,1":
                return ScaleQuality.DOUBLE_HARMONIC;
            case "2,1,3,1,1,3,1":
                return ScaleQuality.HUNGARIAN_MINOR;        
            default: 
                return ScaleQuality.NULL;
        }
    }

    //Returns the notes of a key given the list of intervals
    private generateNoteArray(note: Note, clef: boolean, intervals: string[]): Note[] {
        let res: Note[] = [];
        res.push(note);

        for(let interval of intervals){
            let newNote: Note = this.getNextNote(note.getFullName, clef, interval);
            note = newNote;
            res.push(note);
        }
        
        return res;
    }

    //Gets the next note in the interval series
    private getNextNote(noteName: string, clef: boolean, interval: string): Note{
        let res: string = "";
        let root: string = CommonUtil.splitString(noteName).letter;
        let octave: number = CommonUtil.splitString(noteName).number;

        res += this.getNextNoteName(root);
        if(root[0] === 'E' || root[0] === 'B'){
            if(StaffUtil.isDoubleFlat(root)){
                if(interval === 'H'){
                    res += 'bb';
                }
                else if(interval === 'W'){
                    res += 'b';
                }
            }
            else if(StaffUtil.isFlat(root)){
                if(interval === 'H'){
                    res += 'b';
                } else if(interval === 'WH'){
                    res += '#'
                }
            } else if(StaffUtil.isSharp(root)){
                if(interval === 'H'){
                    res += '#';
                } else if(interval === 'W'){
                    res += 'x';
                } else{
                    res += 'xx';
                }
            }
            else if(StaffUtil.isDoubleSharp(root)){
                if(interval === 'H'){
                    res += 'x';
                } else if(interval === 'W'){
                    res += 'xx';
                } else{
                    res += 'xxx';
                }
            } else{
                if(interval === 'W'){
                    res += '#';    
                } else if(interval === 'WH'){
                    res += 'x';
                }
            }
        } else {
            if(StaffUtil.isDoubleFlat(root)){
                if(interval === 'W'){
                    res += 'bb';
                } else if(interval === 'WH'){
                    res += 'b';
                }
            }
            else if(StaffUtil.isFlat(root)){
                if(interval === 'H'){
                    res += 'bb';
                } else if(interval === 'W'){
                    res += 'b';
                }
            } else if(StaffUtil.isSharp(root)){
                if(interval === 'W'){
                    res += '#';
                } else if(interval === 'WH'){
                    res += 'x';
                }
                
            } else if(StaffUtil.isDoubleSharp(root)){
                if(interval === 'H'){
                    res += '#';
                } else if(interval === 'W'){
                    res += 'x';
                } else{
                    res += 'xx';
                }
            } else{
                if(interval === 'H'){
                    res += 'b';
                } else if(interval === 'WH'){
                    res += '#';
                }
            }
        }

        //If current note is B, move the octave up one
        if(root[0] === 'B'){
            octave += 1;
        }
        res += octave;

        const note: Note = new Note(res, clef);
        return note;
    }

    //Gets the neighboring note
    private getNextNoteName(root: string){
        switch(root[0].toUpperCase()){
            case "C":
                return "D";
            case "D":
                return "E";
            case "E":
                return "F";
            case "F":
                return "G";
            case "G":
                return "A";
            case "A":
                return "B";
            case "B":
                return "C";   
            default:
                return "";         
        }
    }
}