import { RouterOutlet } from '@angular/router';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { Component, Host, HostListener, Inject, OnInit, PLATFORM_ID  } from '@angular/core';
import { FooterComponent } from "./footer/footer.component";
import { CommonModule, isPlatformBrowser } from '@angular/common';


@Component({
    selector: 'app-root',
    standalone: true,
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    imports: [MatToolbarModule, NavBarComponent, MatIconModule, RouterOutlet,
    MatSidenavModule, SideMenuComponent, FooterComponent, CommonModule]
})
export class AppComponent implements OnInit{
    //Used to track is display is compatible with desktop layout
    incompatible: boolean = false;

    constructor(@Inject(PLATFORM_ID) private platformId: object){
        if(isPlatformBrowser(this.platformId)){
            //Register service worker for caching audio files
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.register('/assets/sw.js').then(registration => {
                    console.log('Service Worker successfully registered');
                }).catch(error => {
                    console.error('Service Worker registration failed:', error);
                });
            }
        }
    }


    ngOnInit(): void {
        // Check if window is defined
        if (typeof window !== 'undefined') {
            // Initialize screen size
            this.updateScreenSize();
  
            // Add event listener for window resize
            window.addEventListener('resize', this.updateScreenSize.bind(this));
        } else {
            console.log('undefined');
        }

        
        
    }

    updateScreenSize(){
        const width: number = window.innerWidth;
        const height: number = window.innerHeight;
        // console.log(width + " : " + height);

        if(width <= 1060 || height <= 860){
            this.incompatible = true;
        } else {
            this.incompatible = false;
        }
    }

    // constructor(private router: Router) {
    //   this.router.events.subscribe(event => {
    //     if (event instanceof NavigationStart) {
    //       this.loading = true;
    //     } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
    //       this.loading = false;
    //     }
    //   });
    // }
}
