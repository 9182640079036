// import { Routes } from '@angular/router';
// import { ChordIdentifierComponent } from './game/ear-training/chord-identifier/chord-identifier.component';
// import { ChordEarTrainingDifficultySelectorComponent } from './difficulty-selector/ear-training/chord-ear-training-difficulty-selector/chord-ear-training-difficulty-selector.component';
// import { IntervalEarTrainingDifficultySelectorComponent } from './difficulty-selector/ear-training/interval-ear-training-difficulty-selector/interval-ear-training-difficulty-selector.component';
// import { IntervalIdentifierComponent } from './game/ear-training/interval-identifier/interval-identifier.component';
// import { RouteConstants } from './constants/RouteConstants';
// import { ScaleEarTrainingDifficultySelectorComponent } from './difficulty-selector/ear-training/scale-ear-training-difficulty-selector/scale-ear-training-difficulty-selector.component';
// import { ScaleIdentifierComponent } from './game/ear-training/scale-identifier/scale-identifier.component';
// import { LoginComponent } from './auth/login/login.component';
// import { RegisterComponent } from './auth/register/register.component';
// import { VerifyNewAccountComponent } from './auth/verify-new-account/verify-new-account.component';
// import { IndexComponent } from './index/index.component';
// import { ChordStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/chord-staff-identification-difficulty-selector/chord-staff-identification-difficulty-selector.component';
// import { ScaleStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/scale-staff-identification-difficulty-selector/scale-staff-identification-difficulty-selector.component';
// import { IntervalStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/interval-staff-identification-difficulty-selector/interval-staff-identification-difficulty-selector.component';
// import { NoteStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/note-staff-identification-difficulty-selector/note-staff-identification-difficulty-selector.component';
// import { StaffIdentificationNoteGameComponent } from './game/staff-identification/staff-identification-note-game/staff-identification-note-game.component';
// import { StaffIdentificationIntervalGameComponent } from './game/staff-identification/staff-identification-interval-game/staff-identification-interval-game.component';
// import { StaffIdentificationScaleGameComponent } from './game/staff-identification/staff-identification-scale-game/staff-identification-scale-game.component';
// import { StaffIdentificationChordGameComponent } from './game/staff-identification/staff-identification-chord-game/staff-identification-chord-game.component';
// import { ChordStaffBuildingDifficultySelectorComponent } from './difficulty-selector/staff-building/chord-staff-building-difficulty-selector/chord-staff-building-difficulty-selector.component';
// import { IntervalStaffBuildingDifficultySelectorComponent } from './difficulty-selector/staff-building/interval-staff-building-difficulty-selector/interval-staff-building-difficulty-selector.component';
// import { ScaleStaffBuildingDifficultySelectorComponent } from './difficulty-selector/staff-building/scale-staff-building-difficulty-selector/scale-staff-building-difficulty-selector.component';
// import { StaffBuildingChordGameComponent } from './game/staff-building/staff-building-chord-game/staff-building-chord-game.component';
// import { StaffBuildingIntervalGameComponent } from './game/staff-building/staff-building-interval-game/staff-building-interval-game.component';
// import { StaffBuildingScaleGameComponent } from './game/staff-building/staff-building-scale-game/staff-building-scale-game.component';
// import { LeaderboardComponent } from './leaderboard/leaderboard.component';
// import { ProfileComponent } from './profile/profile.component';
// import { AboutUsComponent } from './foolish-wizard/about-us/about-us.component';
// import { FinishVerificationComponent } from './auth/finish-verification/finish-verification.component';
// import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
// import { VerifyPasswordResetComponent } from './auth/verify-password-reset/verify-password-reset.component';
// import { AdminComponent } from './admin/admin.component';

// export const routes: Routes = [
//     {path: RouteConstants.INDEX, 
//         loadComponent: () => 
//             import('./index/index.component').then(mod => mod.IndexComponent)
//     },
//     {path: RouteConstants.LOGIN, loadComponent: () => import('./auth/login/login.component').then(mod => mod.LoginComponent)},
//     {path: RouteConstants.REGISTER, loadComponent: () => import('./auth/register/register.component').then(mod => mod.RegisterComponent)},
//     {path: RouteConstants.LEADERBOARD, loadComponent: () => import('./leaderboard/leaderboard.component').then(mod => mod.LeaderboardComponent)},
//     {path: RouteConstants.PROFILE, loadComponent: () => import('./profile/profile.component').then(mod => mod.ProfileComponent)},
//     {path: RouteConstants.ABOUT_US, loadComponent: () => import('./foolish-wizard/about-us/about-us.component').then(mod => mod.AboutUsComponent)},
//     {path: RouteConstants.VERIFY_NEW_ACCOUNT, loadComponent: () => import('./auth/verify-new-account/verify-new-account.component').then(mod => mod.VerifyNewAccountComponent)},
//     {path: RouteConstants.FINISH_VERIFICATION, loadComponent: () => import('./auth/finish-verification/finish-verification.component').then(mod => mod.FinishVerificationComponent)},
//     {path: RouteConstants.RESET_PASSWORD, loadComponent: () => import('./auth/reset-password/reset-password.component').then(mod => mod.ResetPasswordComponent)},
//     {path: RouteConstants.VERIFY_PASSWORD_RESET, loadComponent: () => import('./auth/verify-password-reset/verify-password-reset.component').then(mod => mod.VerifyPasswordResetComponent)},
//     {path: RouteConstants.CHORD_EAR_TRAINING_MENU, loadComponent: () => import('./difficulty-selector/ear-training/chord-ear-training-difficulty-selector/chord-ear-training-difficulty-selector.component').then(mod => mod.ChordEarTrainingDifficultySelectorComponent)},
//     {path: RouteConstants.CHORD_EAR_TRAINING_GAME, component: ChordIdentifierComponent},
//     {path: RouteConstants.INTERVAL_EAR_TRAINING_MENU, loadComponent: () => import('./difficulty-selector/ear-training/interval-ear-training-difficulty-selector/interval-ear-training-difficulty-selector.component').then(mod => mod.IntervalEarTrainingDifficultySelectorComponent)},
//     {path: RouteConstants.INTERVAL_EAR_TRAINING_GAME, component: IntervalIdentifierComponent},
//     {path: RouteConstants.SCALE_EAR_TRAINING_MENU, loadComponent: () => import('./difficulty-selector/ear-training/scale-ear-training-difficulty-selector/scale-ear-training-difficulty-selector.component').then(mod => mod.ScaleEarTrainingDifficultySelectorComponent)},
//     {path: RouteConstants.SCALE_EAR_TRAINING_GAME, component: ScaleIdentifierComponent},
//     {path: RouteConstants.CHORD_STAFF_IDENTIFICATION_MENU, loadComponent: () => import('./difficulty-selector/staff-identification/chord-staff-identification-difficulty-selector/chord-staff-identification-difficulty-selector.component').then(mod => mod.ChordStaffIdentificationDifficultySelectorComponent)},
//     {path: RouteConstants.CHORD_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationChordGameComponent},
//     {path: RouteConstants.SCALE_STAFF_IDENTIFICATION_MENU, loadComponent: () => import('./difficulty-selector/staff-identification/scale-staff-identification-difficulty-selector/scale-staff-identification-difficulty-selector.component').then(mod => mod.ScaleStaffIdentificationDifficultySelectorComponent)},
//     {path: RouteConstants.SCALE_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationScaleGameComponent},
//     {path: RouteConstants.INTERVAL_STAFF_IDENTIFICATION_MENU, loadComponent: () => import('./difficulty-selector/staff-identification/interval-staff-identification-difficulty-selector/interval-staff-identification-difficulty-selector.component').then(mod => mod.IntervalStaffIdentificationDifficultySelectorComponent)},
//     {path: RouteConstants.INTERVAL_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationIntervalGameComponent},
//     {path: RouteConstants.NOTE_STAFF_IDENTIFICATION_MENU, loadComponent: () => import('./difficulty-selector/staff-identification/note-staff-identification-difficulty-selector/note-staff-identification-difficulty-selector.component').then(mod => mod.NoteStaffIdentificationDifficultySelectorComponent)},
//     {path: RouteConstants.NOTE_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationNoteGameComponent},
//     {path: RouteConstants.CHORD_STAFF_BUILDING_MENU, component: ChordStaffBuildingDifficultySelectorComponent},
//     {path: RouteConstants.CHORD_STAFF_BUILDING_GAME, component: StaffBuildingChordGameComponent},
//     {path: RouteConstants.SCALE_STAFF_BUILDING_MENU, component: ScaleStaffBuildingDifficultySelectorComponent},
//     {path: RouteConstants.SCALE_STAFF_BUILDING_GAME, component: StaffBuildingScaleGameComponent},
//     {path: RouteConstants.INTERVAL_STAFF_BUILDING_MENU, component: IntervalStaffBuildingDifficultySelectorComponent},
//     {path: RouteConstants.INTERVAL_STAFF_BUILDING_GAME, component: StaffBuildingIntervalGameComponent},
//     {path: RouteConstants.ADMIN_CONSOLE, component: AdminComponent}



// ];

import { Routes } from '@angular/router';
import { ChordIdentifierComponent } from './game/ear-training/chord-identifier/chord-identifier.component';
import { ChordEarTrainingDifficultySelectorComponent } from './difficulty-selector/ear-training/chord-ear-training-difficulty-selector/chord-ear-training-difficulty-selector.component';
import { IntervalEarTrainingDifficultySelectorComponent } from './difficulty-selector/ear-training/interval-ear-training-difficulty-selector/interval-ear-training-difficulty-selector.component';
import { IntervalIdentifierComponent } from './game/ear-training/interval-identifier/interval-identifier.component';
import { RouteConstants } from './constants/RouteConstants';
import { ScaleEarTrainingDifficultySelectorComponent } from './difficulty-selector/ear-training/scale-ear-training-difficulty-selector/scale-ear-training-difficulty-selector.component';
import { ScaleIdentifierComponent } from './game/ear-training/scale-identifier/scale-identifier.component';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { VerifyNewAccountComponent } from './auth/verify-new-account/verify-new-account.component';
import { IndexComponent } from './index/index.component';
import { ChordStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/chord-staff-identification-difficulty-selector/chord-staff-identification-difficulty-selector.component';
import { ScaleStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/scale-staff-identification-difficulty-selector/scale-staff-identification-difficulty-selector.component';
import { IntervalStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/interval-staff-identification-difficulty-selector/interval-staff-identification-difficulty-selector.component';
import { NoteStaffIdentificationDifficultySelectorComponent } from './difficulty-selector/staff-identification/note-staff-identification-difficulty-selector/note-staff-identification-difficulty-selector.component';
import { StaffIdentificationNoteGameComponent } from './game/staff-identification/staff-identification-note-game/staff-identification-note-game.component';
import { StaffIdentificationIntervalGameComponent } from './game/staff-identification/staff-identification-interval-game/staff-identification-interval-game.component';
import { StaffIdentificationScaleGameComponent } from './game/staff-identification/staff-identification-scale-game/staff-identification-scale-game.component';
import { StaffIdentificationChordGameComponent } from './game/staff-identification/staff-identification-chord-game/staff-identification-chord-game.component';
import { ChordStaffBuildingDifficultySelectorComponent } from './difficulty-selector/staff-building/chord-staff-building-difficulty-selector/chord-staff-building-difficulty-selector.component';
import { IntervalStaffBuildingDifficultySelectorComponent } from './difficulty-selector/staff-building/interval-staff-building-difficulty-selector/interval-staff-building-difficulty-selector.component';
import { ScaleStaffBuildingDifficultySelectorComponent } from './difficulty-selector/staff-building/scale-staff-building-difficulty-selector/scale-staff-building-difficulty-selector.component';
import { StaffBuildingChordGameComponent } from './game/staff-building/staff-building-chord-game/staff-building-chord-game.component';
import { StaffBuildingIntervalGameComponent } from './game/staff-building/staff-building-interval-game/staff-building-interval-game.component';
import { StaffBuildingScaleGameComponent } from './game/staff-building/staff-building-scale-game/staff-building-scale-game.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ProfileComponent } from './profile/profile.component';
import { AboutUsComponent } from './foolish-wizard/about-us/about-us.component';
import { FinishVerificationComponent } from './auth/finish-verification/finish-verification.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { VerifyPasswordResetComponent } from './auth/verify-password-reset/verify-password-reset.component';
import { AdminComponent } from './admin/admin.component';
import { ChangelogComponent } from './changelog/changelog.component';
import { CanvasTestComponent } from './canvas-test/canvas-test.component';

export const routes: Routes = [
{path: RouteConstants.INDEX, component: IndexComponent},
{path: RouteConstants.LOGIN, component: LoginComponent},
{path: RouteConstants.REGISTER, component: RegisterComponent},
{path: RouteConstants.LEADERBOARD, component: LeaderboardComponent},
{path: RouteConstants.PROFILE, component: ProfileComponent},
{path: RouteConstants.ABOUT_US, component: AboutUsComponent},
{path: RouteConstants.VERIFY_NEW_ACCOUNT, component: VerifyNewAccountComponent},
{path: RouteConstants.FINISH_VERIFICATION, component: FinishVerificationComponent},
{path: RouteConstants.RESET_PASSWORD, component: ResetPasswordComponent},
{path: RouteConstants.VERIFY_PASSWORD_RESET, component: VerifyPasswordResetComponent},
{path: RouteConstants.CHORD_EAR_TRAINING_MENU, component: ChordEarTrainingDifficultySelectorComponent},
{path: RouteConstants.CHORD_EAR_TRAINING_GAME, component: ChordIdentifierComponent},
{path: RouteConstants.INTERVAL_EAR_TRAINING_MENU, component: IntervalEarTrainingDifficultySelectorComponent},
{path: RouteConstants.INTERVAL_EAR_TRAINING_GAME, component: IntervalIdentifierComponent},
{path: RouteConstants.SCALE_EAR_TRAINING_MENU, component: ScaleEarTrainingDifficultySelectorComponent},
{path: RouteConstants.SCALE_EAR_TRAINING_GAME, component: ScaleIdentifierComponent},
{path: RouteConstants.CHORD_STAFF_IDENTIFICATION_MENU, component: ChordStaffIdentificationDifficultySelectorComponent},
{path: RouteConstants.CHORD_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationChordGameComponent},
{path: RouteConstants.SCALE_STAFF_IDENTIFICATION_MENU, component: ScaleStaffIdentificationDifficultySelectorComponent},
{path: RouteConstants.SCALE_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationScaleGameComponent},
{path: RouteConstants.INTERVAL_STAFF_IDENTIFICATION_MENU, component: IntervalStaffIdentificationDifficultySelectorComponent},
{path: RouteConstants.INTERVAL_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationIntervalGameComponent},
{path: RouteConstants.NOTE_STAFF_IDENTIFICATION_MENU, component: NoteStaffIdentificationDifficultySelectorComponent},
{path: RouteConstants.NOTE_STAFF_IDENTIFICATION_GAME, component: StaffIdentificationNoteGameComponent},
{path: RouteConstants.CHORD_STAFF_BUILDING_MENU, component: ChordStaffBuildingDifficultySelectorComponent},
{path: RouteConstants.CHORD_STAFF_BUILDING_GAME, component: StaffBuildingChordGameComponent},
{path: RouteConstants.SCALE_STAFF_BUILDING_MENU, component: ScaleStaffBuildingDifficultySelectorComponent},
{path: RouteConstants.SCALE_STAFF_BUILDING_GAME, component: StaffBuildingScaleGameComponent},
{path: RouteConstants.INTERVAL_STAFF_BUILDING_MENU, component: IntervalStaffBuildingDifficultySelectorComponent},
{path: RouteConstants.INTERVAL_STAFF_BUILDING_GAME, component: StaffBuildingIntervalGameComponent},
{path: RouteConstants.ADMIN_CONSOLE, component: AdminComponent},
{path: RouteConstants.CHANGELOG, component: ChangelogComponent},
{path: RouteConstants.CANVASTEST, component: CanvasTestComponent}
]



