import { Observable } from "rxjs/internal/Observable";
import { Clef, Difficulty } from "../constants/DifficultyEnum";
import { Subject } from "rxjs/internal/Subject";
import { Subscription } from "rxjs/internal/Subscription";
import { ScoreRow } from "./score-sheet/ScoreTypes";
import { SharedDataService } from "../util/SharedDataService";
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { RoutingUtil } from "../util/RoutingUtil";
import { timer } from "rxjs/internal/observable/timer";
import { takeUntil } from "rxjs/internal/operators/takeUntil";
import { map } from "rxjs/internal/operators/map";
import { CommonUtil } from "../util/CommonUtil";
import { take } from "rxjs/internal/operators/take";
import { ScoreSheetComponent } from "./score-sheet/score-sheet.component";
import { IntervalQuality } from "../constants/IntervalQualityConstants";
import { ScaleQuality } from "../constants/ScaleQualityConstants";
import { Scale } from "../models/Scale";
import { Note } from "../models/Note";
import { StaffUtil } from "../util/StaffUtil";
import { Interval } from "../models/Interval"; 
import { Chord } from "../models/Chord";
import { ChordQuality } from "../constants/ChordEnum";
import { GameId } from "../constants/GameIdEnum";


export abstract class GameHandler{
    protected allNotes_treble: string[] = ["A3", "A#3/Bb3", "B3", "C4", "C#4/Db4", "D4", "D#4/Eb4", "E4", "F4", "F#4/Gb4", "G4", "G#4/Ab4", "A4", "A#4/Bb4", "B4", "C5", "C#5/Db5", "D5", "D#5/Eb5", "E5", "F5", "F#5/Gb5", "G5"];
    protected allNotes_bass: string[] = ["A1", "A#1/Bb1", "B1", "C2", "C#2/Db2", "D2", "D#2/Eb2", "E2", "F2", "F#2/Gb2", "G2", "G#2/Ab2", "A2", "A#2/Bb2", "B2", "C3", "C#3/Db3", "D3", "D#3/Eb3", "E3", "F3", "F#3/Gb3", "G3"];
    protected allNotes_trebleFlat: string[] = ["A3", "Bb3", "B3", "C4", "Db4", "D4", "Eb4", "E4", "F4", "Gb4", "G4", "Ab4", "A4", "Bb4", "B4", "C5", "Db5", "D5", "Eb5", "E5", "F5", "Gb5", "G5", "Ab5", "A5", "Bb5", "B5", "C6", "Db6", "D6", "Eb6", "E6"];
    protected allNotes_bassFlat: string[] = ["A1", "Bb1", "B1", "C2", "Db2", "D2", "Eb2", "E2", "F2", "Gb2", "G2", "Ab2", "A2", "Bb2", "B2", "C3", "Db3", "D3", "Eb3", "E3", "F3", "Gb3", "G3", "Ab3", "A3", "Bb3", "B3", "C4", "Db4", "D4", "Eb4", "E4"];
    protected allNotes_trebleSharp: string[] = ["A3", "A#3", "B3", "C4", "C#4", "D4", "D#4", "E4", "F4", "F#4", "G4", "G#4", "A4", "A#4", "B4", "C5", "C#5", "D5", "D#5", "E5", "F5", "F#5", "G5", "G#5", "A5", "A#5", "B5", "C6", "C#6", "D6", "D#6", "E6"];
    protected allNotes_bassSharp: string[] = ["A1", "A#1", "B1", "C2", "C#2", "D2", "D#2", "E2", "F2", "F#2", "G2", "G#2", "A2", "A#2", "B2", "C3", "C#3", "D3", "D#3", "E3", "F3", "F#3", "G3", "G#3", "A3", "A#3", "B3", "C4", "C#4", "D4", "D#4", "E4"];
    protected allNotes_whiteKeys_trebleClef: string[] = ["F3", "G3", "A3", "B3", "C4", "D4", "E4", "F4", "G4", "A4", "B4", "C5", "D5", "E5", "F5", "G5", "A5", "B5", "C6", "D6", "E6"];
    protected allNotes_whiteKeys_bassClef: string[] = ["A1", "B1", "C2", "D2", "E2", "F2", "G2", "A2", "B2", "C3", "D3", "E3", "F3", "G3", "A3", "B3", "C4", "D4", "E4", "F4", "G4"];

    protected naturalMinorIntervals: string[] = ["W", "H", "W", "W", "H", "W", "W"];
    protected melodicMinorIntervals: string[] = ["W", "H", "W", "W", "W", "W", "H"];
    protected harmonicMinorIntervals: string[] = ["W", "H", "W", "W", "H", "WH", "H"];

    protected intervalsEasy: IntervalQuality[] = [IntervalQuality.MINOR_THIRD, IntervalQuality.MAJOR_THIRD, IntervalQuality.PERFECT_FIFTH, IntervalQuality.OCTAVE];
    protected intervalsMedium: IntervalQuality[] = [IntervalQuality.MAJOR_SECOND, IntervalQuality.MAJOR_THIRD, IntervalQuality.PERFECT_FOURTH, IntervalQuality.PERFECT_FIFTH, 
                                IntervalQuality.MAJOR_SIXTH, IntervalQuality.MAJOR_SEVENTH, IntervalQuality.OCTAVE];
    protected intervalsHard: IntervalQuality[] = [IntervalQuality.MINOR_SECOND, IntervalQuality.MAJOR_SECOND, IntervalQuality.MINOR_THIRD, IntervalQuality.MAJOR_THIRD, IntervalQuality.PERFECT_FOURTH, IntervalQuality.TRITONE, 
                            IntervalQuality.PERFECT_FIFTH, IntervalQuality.MINOR_SIXTH, IntervalQuality.MAJOR_SIXTH, IntervalQuality.MINOR_SEVENTH, IntervalQuality.MAJOR_SEVENTH, 
                            IntervalQuality.OCTAVE];
    protected chordsEasy: ChordQuality[] = [ChordQuality.Major, ChordQuality.Minor, ChordQuality.Aug, ChordQuality.Dim];
    protected chordsMedium: ChordQuality[] = [ChordQuality.Dom7, ChordQuality.Maj7, ChordQuality.Min7, ChordQuality.HalfDim7, ChordQuality.Dim7];
    protected chordsHard: ChordQuality[] = [ChordQuality.Dom9, ChordQuality.Maj9, ChordQuality.Min9, ChordQuality.DomFlat9, ChordQuality.DomSharp9,
                            ChordQuality.Dom11, ChordQuality.Maj11, ChordQuality.Min11, ChordQuality.DomSharp11, ChordQuality.Dom13,
                            ChordQuality.Maj13, ChordQuality.Min13];
    protected scalesEasy: ScaleQuality[] =    [ScaleQuality.MAJOR, ScaleQuality.NATURAL_MINOR, ScaleQuality.MELODIC_MINOR, ScaleQuality.HARMONIC_MINOR];
    protected scalesMedium: ScaleQuality[] =  [ScaleQuality.IONIAN, ScaleQuality.DORIAN, ScaleQuality.PHRYGIAN, ScaleQuality.LYDIAN, ScaleQuality.MIXOLYDIAN,
                            ScaleQuality.AEOLIAN, ScaleQuality.LOCRIAN];  
    protected scalesHard: ScaleQuality[] =    [ScaleQuality.HARMONIC_MAJOR, ScaleQuality.PERSIAN, ScaleQuality.HUNGARIAN_MINOR, ScaleQuality.DOUBLE_HARMONIC,
                            ScaleQuality.ENIGMATIC, ScaleQuality.NEAPOLITAN_MAJOR, ScaleQuality.NEAPOLITAN_MINOR];
    protected gameId: number = 0;
    protected difficulty: number = Difficulty.Easy;
    protected clefType: number = Clef.Treble;
    protected practiceMode: boolean = false;
    protected challengeMode: boolean = false;
    protected correctGuesses: number = 0;
    protected totalGuesses: number = 0;
    public timer$ : Observable<string> = new Observable<string>();
    public initialCountdown: number = 5;
    public countdown: string = "";
    protected stopTimer$ = new Subject<void>();
    protected loading$: Observable<number> = new Observable<number>();
    protected secondsPassed: number = 0;
    protected countdownSubscription: Subscription = new Subscription();
    protected flashGreen: boolean = false;
    protected flashRed: boolean = false;
    protected score: number = 0;
    protected scoreRows: ScoreRow[] = [];

    protected isFlashingGreen1: boolean = false;
    protected isFlashingGreen2: boolean = false;
    protected isFlashingGreen3: boolean = false;
    protected isFlashingGreen4: boolean = false;
    protected isFlashingGreen5: boolean = false;
    protected isFlashingGreen6: boolean = false;
    protected isFlashingGreen7: boolean = false;
    protected isFlashingGreen8: boolean = false;
    protected isFlashingGreen9: boolean = false;
    protected isFlashingGreen10: boolean = false;
    protected isFlashingGreen11: boolean = false;
    protected isFlashingGreen12: boolean = false;
    protected isFlashingGreen13: boolean = false;
    protected isFlashingGreen14: boolean = false;
    protected isFlashingGreen15: boolean = false;
    protected isFlashingGreen16: boolean = false;
    protected isFlashingGreen17: boolean = false;
    protected isFlashingGreen18: boolean = false;
    protected isFlashingGreen19: boolean = false;
    protected isFlashingGreen20: boolean = false;
    protected isFlashingGreen21: boolean = false;

    protected isFlashingRed1: boolean = false;
    protected isFlashingRed2: boolean = false;
    protected isFlashingRed3: boolean = false;
    protected isFlashingRed4: boolean = false;
    protected isFlashingRed5: boolean = false;
    protected isFlashingRed6: boolean = false;
    protected isFlashingRed7: boolean = false;
    protected isFlashingRed8: boolean = false;
    protected isFlashingRed9: boolean = false;
    protected isFlashingRed10: boolean = false;
    protected isFlashingRed11: boolean = false;
    protected isFlashingRed12: boolean = false;
    protected isFlashingRed13: boolean = false;
    protected isFlashingRed14: boolean = false;
    protected isFlashingRed15: boolean = false;
    protected isFlashingRed16: boolean = false;
    protected isFlashingRed17: boolean = false;
    protected isFlashingRed18: boolean = false;
    protected isFlashingRed19: boolean = false;
    protected isFlashingRed20: boolean = false;
    protected isFlashingRed21: boolean = false;

    //Used to ensure no two identical elements are generated back to back
    protected lastNote: Note = new Note("A0", true);
    protected lastInterval: Interval = new Interval(this.lastNote, 0, true);
    protected lastChord: Chord = new Chord(this.lastNote, ChordQuality.Null);
    protected lastScale: Scale = new Scale(ScaleQuality.NULL, this.lastNote);

    constructor(protected router: Router, protected sharedDataService: SharedDataService, protected dialog: MatDialog, 
                protected route: ActivatedRoute
    ){
        
    }
    
    //Abstract method to handle logic of checking whether an answer is correct or not
    protected abstract checkAnswer(guess: any, buttonRef?: HTMLButtonElement): void;

    //Abstract method to play a sample from the piano util
    protected abstract playSample(correct: boolean): void;

    //Abstract method to handle game termination logic
    protected abstract finish(): void;

    //Returns the number of correct guesses to number of total guesses
    public returnScoreAsString(): string{
        let val: string = "";
        if(this.totalGuesses === 0){
            return val;
        }
        
        val = this.correctGuesses.toString() + "/" + this.totalGuesses.toString();
        return val;
    }

    //Initializes fundamental game elements in component
    protected initModule(): boolean{
        //Receives payload from difficulty selector
        const payload = this.sharedDataService.getAndClearFormData();
        if (payload) {
            this.difficulty = payload['difficulty'];
            this.practiceMode = payload['practiceModeSelected'];
            this.challengeMode = payload['challengeModeSelected'];
        } else {
            RoutingUtil.navigateToMenu(this.gameId, this.router);
            return false;
        }

        this.initializeTimers();
        return true;
    }

    //Sets up timer observables and begins subscription
    protected initializeTimers(): void{
        //Selects timer based on practice or challenge mode
        if(this.practiceMode){
            this.timer$.subscribe();
            this.timer$ = timer(0, 1000).pipe(
                takeUntil(this.stopTimer$),
                map(value => {
                    this.secondsPassed = value;
                    return CommonUtil.getTimer(value);         
                })
            );
        }

        if(this.challengeMode){
            this.countdownSubscription = timer(0, 1000).pipe(
                map(value => this.secondsPassed = value),
                take(this.initialCountdown + 1),
                map(elapsedTime => CommonUtil.getTimer(this.initialCountdown - elapsedTime))
            ).subscribe({
                next: value => this.countdown = value,
                complete: () => this.finish()
            });
        }
    }

    //Updates background color of the module depending on game type
    protected changeButtonBackgroundColor(isCorrect: boolean): string{
        if(this.gameId == GameId.EarTraining_Chords || this.gameId == GameId.EarTraining_Intervals ||
           this.gameId == GameId.EarTraining_Scales){ 
            if(isCorrect){
                return "flash-green-primary";
            } else {
                return "flash-red-primary";
            }
        } else if(this.gameId == GameId.StaffIdentification_Chords || this.gameId == GameId.StaffIdentification_Intervals ||
                  this.gameId == GameId.StaffIdentification_Notes || this.gameId == GameId.StaffIdentification_Scales){
            if(isCorrect){
                return "flash-green-secondary";
            } else {
                return "flash-red-secondary";
            }
        } else if(this.gameId == GameId.StaffBuilding_Chords || this.gameId == GameId.StaffBuilding_Intervals ||
                  this.gameId == GameId.StaffBuilding_Scales){
            if(isCorrect){
                return "flash-green-tertiary";
            } else{
                return "flash-red-tertiary";
            }
        } else {
            if(isCorrect){
                return "flash-green";
            } else{
                return "flash-red";
            }
        }
    }

    //Flashes the specified button based on the button's id and if user answered correctly
    protected flashButton(id: string, isCorrect: boolean): void{
        if(isCorrect){
            switch(id){
                case "1": 
                    this.isFlashingGreen1 = true;
                    break;
                case "2": 
                    this.isFlashingGreen2 = true;
                    break;
                case "3": 
                    this.isFlashingGreen3 = true;
                    break;
                case "4": 
                    this.isFlashingGreen4 = true;
                    break;
                case "5": 
                    this.isFlashingGreen5 = true;
                    break;
                case "6": 
                    this.isFlashingGreen6 = true;
                    break;
                case "7": 
                    this.isFlashingGreen7 = true;
                    break;
                case "8": 
                    this.isFlashingGreen8 = true;
                    break;
                case "9": 
                    this.isFlashingGreen9 = true;
                    break;
                case "10": 
                    this.isFlashingGreen10 = true;
                    break;
                case "11": 
                    this.isFlashingGreen11 = true;
                    break;
                case "12": 
                    this.isFlashingGreen12 = true;
                    break;
                case "13": 
                    this.isFlashingGreen13 = true;
                    break;
                case "14": 
                    this.isFlashingGreen14 = true;
                    break;
                case "15": 
                    this.isFlashingGreen15 = true;
                    break;
                case "16": 
                    this.isFlashingGreen16 = true;
                    break;
                case "17": 
                    this.isFlashingGreen17 = true;
                    break;
                case "18": 
                    this.isFlashingGreen18 = true;
                    break;
                case "19": 
                    this.isFlashingGreen19 = true;
                    break;
                case "20": 
                    this.isFlashingGreen20 = true;
                    break;
                case "21": 
                    this.isFlashingGreen21 = true;
                    break;
            }
        //Answer is incorrect, flash red
        } else {
            switch(id){
                case "1": 
                    this.isFlashingRed1 = true;
                    break;
                case "2": 
                    this.isFlashingRed2 = true;
                    break;
                case "3": 
                    this.isFlashingRed3 = true;
                    break;
                case "4": 
                    this.isFlashingRed4 = true;
                    break;
                case "5": 
                    this.isFlashingRed5 = true;
                    break;
                case "6": 
                    this.isFlashingRed6 = true;
                    break;
                case "7": 
                    this.isFlashingRed7 = true;
                    break;
                case "8": 
                    this.isFlashingRed8 = true;
                    break;
                case "9": 
                    this.isFlashingRed9 = true;
                    break;
                case "10": 
                    this.isFlashingRed10 = true;
                    break;
                case "11": 
                    this.isFlashingRed11 = true;
                    break;
                case "12": 
                    this.isFlashingRed12 = true;
                    break;
                case "13": 
                    this.isFlashingRed13 = true;
                    break;
                case "14": 
                    this.isFlashingRed14 = true;
                    break;
                case "15": 
                    this.isFlashingRed15 = true;
                    break;
                case "16": 
                    this.isFlashingRed16 = true;
                    break;
                case "17": 
                    this.isFlashingRed17 = true;
                    break;
                case "18": 
                    this.isFlashingRed18 = true;
                    break;
                case "19": 
                    this.isFlashingRed19 = true;
                    break;
                case "20": 
                    this.isFlashingRed20 = true;
                    break;
                case "21": 
                    this.isFlashingRed21 = true;
                    break;
            }
        }
        
    }

    //Reset flash classes to ensure that flashing does not persist past one button click
    protected resetFlashClasses(): void{
        this.isFlashingGreen1 = false;
        this.isFlashingGreen2 = false;
        this.isFlashingGreen3 = false;
        this.isFlashingGreen4 = false;
        this.isFlashingGreen5 = false;
        this.isFlashingGreen6 = false;
        this.isFlashingGreen7 = false;
        this.isFlashingGreen8 = false;
        this.isFlashingGreen9 = false;
        this.isFlashingGreen10 = false;
        this.isFlashingGreen11 = false;
        this.isFlashingGreen12 = false;
        this.isFlashingGreen13 = false;
        this.isFlashingGreen14 = false;
        this.isFlashingGreen15 = false;
        this.isFlashingGreen16 = false;
        this.isFlashingGreen17 = false;
        this.isFlashingGreen18 = false;
        this.isFlashingGreen19 = false;
        this.isFlashingGreen20 = false;
        this.isFlashingGreen21 = false;
    
        this.isFlashingRed1 = false;
        this.isFlashingRed2 = false;
        this.isFlashingRed3 = false;
        this.isFlashingRed4 = false;
        this.isFlashingRed5 = false;
        this.isFlashingRed6 = false;
        this.isFlashingRed7 = false;
        this.isFlashingRed8 = false;
        this.isFlashingRed9 = false;
        this.isFlashingRed10 = false;
        this.isFlashingRed11 = false;
        this.isFlashingRed12 = false;
        this.isFlashingRed13 = false;
        this.isFlashingRed14 = false;
        this.isFlashingRed15 = false;
        this.isFlashingRed16 = false;
        this.isFlashingRed17 = false;
        this.isFlashingRed18 = false;
        this.isFlashingRed19 = false;
        this.isFlashingRed20 = false;
        this.isFlashingRed21 = false;
    }


    //Resets timers, observables, and score counters
    protected restartGame(): void{
        this.correctGuesses = 0;
        this.totalGuesses = 0;
        this.timer$ = new Observable<string>();
        this.loading$ = new Observable<number>();
        this.secondsPassed = 0;
        this.countdownSubscription = new Subscription();
        this.scoreRows = [];
        this.score = 0;
        this.initializeTimers();
    }

    //Exits game and returns to difficulty selector
    protected quitGame(): void{
        this.stopTimer(); //Prevents dialog from finalizing when navigating away
        RoutingUtil.navigateToMenu(this.gameId, this.router);
    }

    //Stops observable subscription from incrementing
    protected stopTimer(): void{
        this.countdownSubscription.unsubscribe();
        this.stopTimer$.next();
    }

    protected handleDialog(payload: any): void{
        //Opens score sheet dialog component
        let dialogRef: MatDialogRef<ScoreSheetComponent> = this.dialog.open(ScoreSheetComponent, {
            panelClass: 'score-sheet-container',
            width: '450px',
            maxWidth: '90vw',
            position: { top: '10%' },
            disableClose: true,
            data: payload
        });

        //Handles closing dialog
        dialogRef.afterClosed().subscribe(result => {
            let playAgain = result['playAgain'];
            if(playAgain){
                this.restartGame();
            } else {
                this.quitGame();
            }
            
        })
    }

    //Generates a random note for staff and ear training games
    protected generateRandomNote(clef: boolean): Note{
        let noteName: string = "";
        let noteArr: string[] = this.getNoteArray(clef);

        noteName = noteArr[CommonUtil.randint(0, noteArr.length - 1)];
        //For hard mode, insert sharp or flat character
        if(CommonUtil.flipCoin() && this.difficulty == Difficulty.Hard){
            let tempNoteName: string = "";
            let newChar: string = "";
            if(CommonUtil.flipCoin()){
                newChar = "b";
            }else{
                newChar = "#";
            }

            //Construct and replace new note name
            tempNoteName = noteName[0] + newChar + noteName[1];
            noteName = tempNoteName;
        }
        noteName = StaffUtil.splitNote(noteName);

        const note: Note = new Note(noteName, clef);
        if(note === this.lastNote){
            this.generateRandomNote(clef);
        }
        this.lastNote = note;

        return note;
    }

    //Creates a random interval to be used in staff and ear training games
    protected generateRandomInterval(clef: boolean): Interval{
        //Generates a random semitone number and grabs the second note relative to the root
        let noteArr: string[]= clef ? this.allNotes_treble : this.allNotes_bass; 
        let semitones: number = this.generateSemitones();
        let index: number = CommonUtil.randint(0, noteArr.length - 1 - semitones);

        //Create root note of the interval
        let note1Name: string = "";
        note1Name = noteArr[index];
        note1Name = StaffUtil.splitNote(note1Name);
        let root: string = CommonUtil.splitString(note1Name).letter;
        let note1: Note = new Note(note1Name, clef);

        while(['D#', 'A#', "G#", "Gb", "Db"].includes(root)){
            note1 = this.generateNewNote(noteArr, clef);
            root = CommonUtil.splitString(note1.getFullName).letter;
        }
            
        const interval: Interval = new Interval(note1, semitones, clef);
        if(interval === this.lastInterval){
            this.generateRandomInterval(clef);
        }
        this.lastInterval = interval;
        return interval;
    }

    //Generates random interval root note
    private generateNewNote(noteArr: string[], clef: boolean): Note{
        const index: number = CommonUtil.randint(0, noteArr.length / 3);
        let note1Name: string = noteArr[index];
        note1Name = StaffUtil.splitNote(note1Name);
        return new Note(note1Name, clef);
    }

    //Generates random semitones value
    private generateSemitones(): number{
        let semitones: number = 0;
        if(this.difficulty == Difficulty.Easy){
            semitones = this.intervalsEasy[CommonUtil.randint(0, this.intervalsEasy.length - 1)];
        } else if(this.difficulty == Difficulty.Medium){
            semitones = this.intervalsMedium[CommonUtil.randint(0, this.intervalsMedium.length - 1)];
        } else if(this.difficulty == Difficulty.Hard){
            semitones = this.intervalsHard[CommonUtil.randint(0, this.intervalsHard.length - 1)];
        }
        return semitones;
    }


    //Creates a random chord to be used in staff and ear training games
    protected generateRandomChord(clef: boolean): Chord{
        let noteName: string = "";
        let quality: ChordQuality = ChordQuality.Null;

        //Select the chord quality depending on the game difficulty
        let noteArrIndex: number = 2;
        if(this.difficulty == Difficulty.Easy){
            quality = CommonUtil.randelement(this.chordsEasy);
        } else if (this.difficulty == Difficulty.Medium){
            quality = CommonUtil.randelement(this.chordsMedium);
        } else if (this. difficulty == Difficulty.Hard){
            quality = CommonUtil.randelement(this.chordsHard);
            noteArrIndex = 3;
        }

        let noteArr: string[] = this.getNoteArray(clef);
        noteArr = noteArr.slice(2, noteArr.length / noteArrIndex);

        //Get the root note
        noteName = noteArr[CommonUtil.randint(0, noteArr.length - 1)];
        if(CommonUtil.flipCoin()){
            let tempNoteName: string = "";
            let newChar: string = "";
            if(CommonUtil.flipCoin()){
                if(noteName[0] !== 'C' && noteName[0] !== 'F') newChar = "b";
            }else{
                if(noteName[0] !== 'E' && noteName[0] !== 'B') newChar = "#";
            }

            //Construct and replace new note name
            tempNoteName = noteName[0] + newChar + noteName[1];
            noteName = tempNoteName;
        }
        noteName = StaffUtil.splitNote(noteName);


        let rootNote: Note = new Note(noteName, clef);

        //Return the random chord
        const chord: Chord = new Chord(rootNote, quality);
        // const chord: Chord = new Chord(new Note("A#3", true), ChordQuality.Aug);

        //Flag to see if an invalid symbol is generated
        let isInvalid: boolean = false;
        chord.getNotes.forEach((note) => {
            const root: string = note.getRoot;
            isInvalid = StaffUtil.isTripleSharp(root);
        })

        //Generate a new chord if last chord is invalid or repeated
        if(chord === this.lastChord || isInvalid){
            this.generateRandomChord(clef);
        }
        this.lastChord = chord;
        return chord;

    }
  
    //Generates a random scale to be played 
    protected generateRandomScale(clef: boolean): Scale{
        //Generates a random note from the first fourth of the notes array
        let note: Note = this.generateScaleNote(clef);

        //Ignore theoritical keys
        while(note.getRoot === 'Db' || note.getRoot === 'D#'){
            note = this.generateScaleNote(clef);
        }

        //Randomly picks the scale quality
        let quality: ScaleQuality = ScaleQuality.NULL;
        if(this.difficulty == Difficulty.Easy){
            quality = this.scalesEasy[CommonUtil.randint(0, this.scalesEasy.length - 1)];
        } else if(this.difficulty == Difficulty.Medium){
            quality = this.scalesMedium[CommonUtil.randint(0, this.scalesMedium.length - 1)];
        } else if(this.difficulty == Difficulty.Hard){
            quality = this.scalesHard[CommonUtil.randint(0, this.scalesHard.length - 1)];
        }


        //Generates scale and determines notes
        const scale: Scale = new Scale(quality, note);

        //If the scale contains a note with an invalid symbol, generate a new scale
        let isInvalid: boolean = false;
        scale.getNotes.forEach((note) => {
            const root: string = note.getRoot;
            isInvalid = StaffUtil.isTripleSharp(root);
        })
        if(scale === this.lastScale){
            this.generateRandomScale(clef);
        }
        this.lastScale = scale;
        return scale;
    }

    private generateScaleNote(clef: boolean): Note{
        let index: number = 0;
        let noteName: string = "";
        if(clef){
            index = CommonUtil.randint(1, Math.floor(this.allNotes_treble.length / 3)); 
            noteName = StaffUtil.splitNote(this.allNotes_treble[index]);
        } else {
            index = CommonUtil.randint(1, Math.floor(this.allNotes_bass.length / 3)); 
            noteName = StaffUtil.splitNote(this.allNotes_bass[index]);
        }

        return new Note(noteName, clef);
    }

    protected incrementScore(): void{
        this.score += 10;
    }

    protected decrementScore(): void{
        if(this.score > 0){
            this.score -= 5;
        }
    }


    //Returns appropriate note array
    private getNoteArray(clef: boolean){
        //Handles note array differently based on selected clef
        if(clef){
            return this.allNotes_whiteKeys_trebleClef;
        } else {
            return this.allNotes_whiteKeys_bassClef;
        }
    }

    //Returns the appropriate background color for the game type
    public getBackgroundColor(): string{
        return CommonUtil.getBackgroundColor(this.gameId);
    }


}