import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder,  FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthDataService } from '../../data-services/AuthDataService';
import {} from '@angular/common/http';
import { Router } from '@angular/router';
import { SHA256Service } from '../../data-services/SHA256Service';
import { AuthenticationService } from '../service/AuthenticationService';
import { UserDataService } from '../../data-services/UserDataService';
import { CompressionUtil } from '../../util/CompressionUtil';
import { SpinnerComponent } from "../../graphics/spinner/spinner.component";
import { CommonUtil } from '../../util/CommonUtil';
import base64 from 'base-64';


@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.css',
    imports: [CommonModule, ReactiveFormsModule, SpinnerComponent]
})
export class LoginComponent implements OnInit {

    public loginForm!: FormGroup;
    public loginFailed_noUserFound: boolean = false;
    public loginFailed_incorrectPassword: boolean = false;
    public loginFailed_serverDown: boolean = false;
    public loginFailed_notRegistered: boolean = false;
    public loginFailed_verificationEmailFailedToSend: boolean = false;
    public sentNewVerificationEmail: boolean = false;
    public isSubmitting: boolean = false;
    public isSendingVerificationEmail: boolean = false;
    public email: string = ""; //Contains submitted email in case of new verification email
    constructor(private fb: FormBuilder, private router: Router, private authService: AuthenticationService){}
        
    ngOnInit(): void {
        this.buildForm(); 
    }

    //Builds out NG form
    private buildForm(): void {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required,Validators.email]],
            password: ['', [Validators.required]],
        });
    }

    //Handles submission flow
    public async onSubmit() {
        //reset error conditions for each login attempt
        this.loginFailed_noUserFound = false;
        this.loginFailed_incorrectPassword = false;
        this.loginFailed_notRegistered = false;
        this.loginFailed_serverDown = false;
        this.loginFailed_verificationEmailFailedToSend = false;
        this.sentNewVerificationEmail = false;
        this.isSubmitting = true;
        
        let email = this.loginForm.get("email")?.value;
        this.email = email;

        //Begin validation process
        await this.validateCredentials()
        .then(async isAuthenticated => {
            this.isSubmitting = false;
            //Log the auth attempt regardless of successful login
            await AuthDataService.logAuthAttempt(email, isAuthenticated);
            if(isAuthenticated){
                //User is authenticated, now check for verification status
                const token: string = await this.checkUserVerification(email);
                if(token !== ""){
                    this.authService.setToken(token);
                    
                    //Set the profile picture
                    const username: string = this.authService.getUsername() as string;
                    const userProfileJsonString: string = await UserDataService.getUserProfileDetails(username);
                    if(CommonUtil.isEmpty(userProfileJsonString)){
                        this.setDefaultProfilePicture();
                    } else {
                        const userProfilePictureAsBase64: string = JSON.parse(userProfileJsonString).profilePicture;
                        if(CommonUtil.isEmpty(userProfilePictureAsBase64)){
                            this.setDefaultProfilePicture();
                        } else {
                            const userProfileBinary: Blob = CompressionUtil.decodeBase64Image(userProfilePictureAsBase64);
                            this.authService.setProfilePicture(userProfileBinary);
                        }
                        
                    } 

                    //Navigate to profile page after successful login
                    this.router.navigate(["/profile"]).then(() => {
                        //Peforms a hard reload of the index page
                        window.location.reload();
                    });
                } else{
                    this.loginFailed_notRegistered = true;
                    this.sentNewVerificationEmail = true;
                    this.authService.clearAuthSessionVariables();
                }
            }
        });
        
    }

    //Set default profile picture in the session
    public setDefaultProfilePicture(): void{
        const img: string = base64.encode('img');
        const userProfileBinary: Blob = CompressionUtil.decodeBase64Image(img);
        this.authService.setProfilePicture(userProfileBinary);
    }

    //Validates the credentials passed in from the form.  Returns true if all potential error conditions
    //return false
    private async validateCredentials(): Promise<boolean>{
        let email: string = this.loginForm.get('email')?.value;
        let password: string = this.loginForm.get('password')?.value;

        //Retrieve JSON response, parse credentials and validate that they are populated
        const jsonString: string = await AuthDataService.getUser(email);
        if(jsonString == null){
            this.loginFailed_serverDown = true;
            return false;
        }

        //Return server down error if jsonObject is null
        const jsonObject = JSON.parse(jsonString);
        if(jsonObject == null){
            this.loginFailed_serverDown = true;
            return false;
        }

        //Check if a valid user was returned
        if(jsonObject.email === "" || jsonObject.username === "" || jsonObject.password === "" || 
           jsonObject.salt === "" || jsonObject.userId < 0){
            this.loginFailed_noUserFound = true;
            return false;
        }

        //validate that the password matches submitted value
        let hashCheck: boolean = SHA256Service.compareHash(password, jsonObject.password, jsonObject.salt);
        if(!hashCheck){
            this.loginFailed_incorrectPassword = true;
            return false;
        }
        
        //Verify that all error conditions are false
        if(!this.loginFailed_noUserFound && !this.loginFailed_incorrectPassword){
            this.authService.setAuthSessionVariables(jsonObject.userId, jsonObject.username, jsonObject.email);
            return true;
        }

        return false;
    }

    //Checks if user is verified.  If not, sends out email verification 
    private async checkUserVerification(email: string): Promise<string>{
        const jsonString: string = await AuthDataService.checkEmailVerification(email);
        return JSON.parse(jsonString).token;
    }

    //If user attempts to authenticate but isn't registered, and clicks new email
    //link, sends them a new verification email
    public async resendVerificationEmail(): Promise<void> {
        this.isSendingVerificationEmail = true;
        await AuthDataService.sendEmailVerification(this.email)
        .then(isSubmitted => {
            if(!isSubmitted){
                this.loginFailed_verificationEmailFailedToSend = true;
            }
        });

        this.isSendingVerificationEmail = false;
        this.sentNewVerificationEmail = false; //Return to false after email attempts to send
    }

}
