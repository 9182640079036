import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtil } from '../../util/CommonUtil';
import { AuthDataService } from '../../data-services/AuthDataService';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from "../../graphics/spinner/spinner.component";

@Component({
    selector: 'app-finish-verification',
    standalone: true,
    templateUrl: './finish-verification.component.html',
    styleUrl: './finish-verification.component.css',
    imports: [CommonModule, SpinnerComponent]
})
export class FinishVerificationComponent implements OnInit{
    public isLoading: boolean = false;
    public registrationSuccessful: boolean = false;
    public registrationFailed: boolean = false;
    constructor(public router: Router, private actRoute: ActivatedRoute){}

    async ngOnInit(): Promise<void> {
        this.isLoading = true;
        //Retrieve token from the URI
        const token: string = this.actRoute.snapshot.queryParamMap.get('token') as string;
        
        //Return user to home page if token is null
        if(CommonUtil.isEmpty(token)){
            this.router.navigate(['/']);
        }

        //Check for valid token
        const isRegistered: boolean = await AuthDataService.completeRegistration(token);
        if(isRegistered && isRegistered !== undefined && isRegistered !== null){
            this.registrationSuccessful = true;
        } else {
            this.registrationFailed = true;
        }
        this.isLoading = false;
        
    }
}
