export enum IntervalQuality{
    NULL = 0,
    MINOR_SECOND = 1,
    MAJOR_SECOND = 2,
    MINOR_THIRD = 3,
    MAJOR_THIRD = 4,
    PERFECT_FOURTH = 5,
    TRITONE = 6,
    PERFECT_FIFTH = 7,
    MINOR_SIXTH = 8,
    MAJOR_SIXTH = 9,
    MINOR_SEVENTH = 10,
    MAJOR_SEVENTH = 11,
    OCTAVE = 12,
    MINOR_NINTH = 13,
    MAJOR_NINTH = 14,
    MINOR_THIRTEENTH = 20,
    MAJOR_THIRTEENTH = 21
}