import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AuthDataService } from '../data-services/AuthDataService';
import { SHA256Service } from '../data-services/SHA256Service';
import { CommonModule } from '@angular/common';
import { UserDataService } from '../data-services/UserDataService';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { AdminDialogComponent } from './admin-dialog/admin-dialog.component';
import { CommonUtil } from '../util/CommonUtil';

//Interface for encapsulating user details
interface UserData{
    id: number;
    username: string;
    email: string;
    verificationStatus: number;
}

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css'
})
export class AdminComponent{
    public users: UserData[] = [];
    public usersDisplayed: UserData[] = [];
    public recordCounter: number = 0;
    public pageIndex: number = 0;
    public username: string = '';
    public password: string = '';

    public isAdmin: boolean = false;
    public errorFlag_isNotAdmin: boolean = false;
    public errorFlag_serviceUnavailable: boolean = false;

    public searchEmail: string = "";

    constructor(private dialog: MatDialog) { }
    

    //Handles submission of admin credentials
    public async onSubmit(): Promise<void> {
        //Reset error flag
        this.errorFlag_isNotAdmin = false;
        this.errorFlag_serviceUnavailable = false;

        //Authenticate admin user in the backend
        const jsonResult: string = await AuthDataService.authenticateAdmin(this.username);
        if(CommonUtil.isEmpty(jsonResult) || jsonResult === undefined){
            this.errorFlag_serviceUnavailable = true;
            return;
        }

        //Check if password matches hashed pw
        const hashedPw: string = JSON.parse(jsonResult).pw;
        const salt: string = JSON.parse(jsonResult).salt;

        const hashCheck: boolean = SHA256Service.compareHash(this.password, hashedPw, salt);
        this.isAdmin = hashCheck;
        this.errorFlag_isNotAdmin = !hashCheck;

        //If admin came back successfully, make call to list all records
        if(this.isAdmin){
            const userJsonResult: string = await UserDataService.getUserList();
            this.users = JSON.parse(userJsonResult);
            this.usersDisplayed = this.users;
        }

    }

    //Selects a user 
    public selectUser(user: UserData): void{
        this.openProfilePictureDialogBox(user.id, user.username, user.email, user.verificationStatus);
    }

    //Moves forward one page
    public increment(): void{
        this.pageIndex += 1;
    }

    //Moves backward one page
    public decrement(): void{
        this.pageIndex -= 1;
    }

    //Opens a dialog box to perform admin actions for a user
    private openProfilePictureDialogBox(id: number, username: string, email: string, verificationStatus: number): void{
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.width = '450px';
        dialogConfig.data = {id, username, email, verificationStatus};
        const dialogRef: MatDialogRef<AdminDialogComponent> = this.dialog.open(AdminDialogComponent, dialogConfig);
    
        dialogRef.afterClosed().subscribe(async () => {
            //Refresh the records
            this.pageIndex = 0;
            this.users = [];
            const userJsonResult: string = await UserDataService.getUserList();
            this.users = JSON.parse(userJsonResult);
            this.usersDisplayed = this.users;
        });
    } 

    //Translates value of verification status from numeric to string
    public translateVerificationStatus(status: number): string{
        if(status === 0){
            return "Unverified";
        }

        return "Verified";
    }

    //Search for a user by their email
    public filterUsersByEmail(): void {
        if(!this.searchEmail){
            this.usersDisplayed = this.users;
        }

        this.usersDisplayed = this.users.filter(user => user.email.toLowerCase().includes(this.searchEmail.toLowerCase()));
    }

}
