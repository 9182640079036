
<div class="score-sheet-container">
    <div *ngIf="isVisible">
        <div class="score-sheet-header" [ngClass]="changeBackgroundColor()">
            <div>
                <h2>Results</h2>  
            </div>
            
        </div>
    
        <div class="stat-container">

            <div class="stat-row">
                <div class="container">
                    <div class="label">
                        <h3>Score:</h3>
                    </div>
                    <div class="result-container">
                        <p>{{ score }}</p>
                    </div>
                </div>
            </div>
            
            <div class="stat-row">
                <div class="container">
                    <div class="label">
                        <h3>Time:</h3>
                    </div>
                    <div class="result-container">
                        <p>{{ time }}</p>
                    </div>
                </div>
            </div>

            
            <div class="stat-row">
                <div class="container">
                    <div class="label">
                        <h3>Total:</h3>
                    </div>
                    <div *ngIf="totalQuestions < 1" class="result-container">
                        <p>N/A</p>
                    </div>
                    <div *ngIf="totalQuestions >= 1" class="result-container">
                        <p>{{ totalCorrect }}/{{ totalQuestions}}</p>
                    </div>
                </div>
            </div>
            <div class="stat-row">
                <div class="container">
                    <div class="label">
                        <h3>Accuracy:</h3>
                    </div>
                    <div class="result-container">
                        <p>{{ accuracy }}%</p>
                    </div>
                </div>
            </div>
    
            <div *ngIf="scoreRows.length >= 1" class="score-container">
                <div *ngFor="let score of scoreDetails; let i = index">
                    <div *ngIf="score.totalQuestions != 0 && shouldDisplay();" class="score-details-container bottom-border" [ngClass]="getRowClass()">
                        <div class="label">
                            <p> {{ score.name }}: </p>
                        </div>
                        <div class="result-container">
                            <p> {{ score.totalCorrect }} / {{ score.totalQuestions }} </p>
                        </div>
                        <div></div>
                        <div class="result-container">
                            <p>{{ score.accuracy }}%</p>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="this.mode && this.isMessageVisible">
                <hr />
                <div *ngIf="!this.isSaved && !this.isLoggedIn()">{{ this.scoreMessage_notLoggedIn }}</div>
                <div class="auth-error-message auth-error-message-left" *ngIf="!this.isSaved && this.isLoggedIn()">{{ this.scoreErrorMessage }}</div>
                <div class="auth-success-message" *ngIf="this.isSaved && this.isLoggedIn()">{{ this.scoreSuccessMessage }}</div>

            </div>
            <br />
        </div>
        <div class="button-container" [ngClass]="changeBackgroundColor()">
            <div>
                <button mat-button [mat-dialog-close]="{playAgain: true}" class="submit-button submit-left" [ngClass]="changeBackgroundColor()">Play Again</button>
            </div>
            <div>
                <button mat-button [mat-dialog-close]="{playAgain: false}" class="submit-button submit-right" [ngClass]="changeBackgroundColor()">Quit</button>
            </div>
        </div>
    </div>
</div>





