
<div class="main-container about-us margin-top">
    <div class="logo-container margin-top-icon">
        <img src="/assets/icons/FoolishWizardLogo.png" />
     </div>
    <div class="about-us-container">
        <!-- <hr /> -->
        <h2>Who am I?</h2>
        <p>I am a software developer and musician that is focused on providing an accessible 
            music education web service to the public.  I believe that
            the Internet is best used as a repository of educational content, and that its primary used
            should be to imbue others with knowledge.  I take pride in providing a platform that helps users 
            progress as musicians.
        </p>

        <hr />
        <h2>What is BeatTheory?</h2>


        <p>BeatTheory is the first application that I have developed.  This web service provides an
            interactive and engaging set of modules for testing your understanding of music theory fundamentals.  
            The modules are divided into games that include a practice mode for those who wish to hone their skills, and
            a challenge mode for more competitive users.  I believe that gamifying the learning process is a great way to motivate users,
            and BeatTheory provides just that.
        </p>

        <p>Currently, BeatTheory includes three module categories: Ear Training, Staff Identification, and Staff Building.  These modules
            help train users in essential skills for developing a strong ear and a sharp eye.  The Ear Training modules will help users
            identify intervals, chords, and scales without needing notation, which is a technique that all musicians should be well versed in.
            The Staff Identification and Staff Building modules will assist users in reading music notation more fluently.
        </p>

        <p>I intend to optimize the current modules and introduce new ones over time, so keep an eye on the <a href="/changelog">changelog</a> for 
        patches and updates!</p>

        <hr />

        <h2>Inquiries, User Support, and More</h2>
        <p>For any business inquiries, user support, general feedback, or other questions, please send an email
            to <b>foolishwizard.csr&#64;gmail.com</b>.

        </p>
    </div>
</div>
