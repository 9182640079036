import { Component, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AuthDataService } from '../../data-services/AuthDataService';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { SpinnerComponent } from "../../graphics/spinner/spinner.component";
import { CommonUtil } from '../../util/CommonUtil';
import { AuthenticationService } from '../service/AuthenticationService';


@Component({
    selector: 'app-register',
    standalone: true,
    templateUrl: './register.component.html',
    styleUrl: './register.component.css',
    animations: [
        trigger('circleAnimation', [
            state('small', style({ r: '10' })),
            state('medium', style({ r: '20' })),
            state('large', style({ r: '30' })),
            transition('* => *', animate('500ms ease-in-out')),
        ]),
    ],
    imports: [CommonModule, ReactiveFormsModule, SpinnerComponent ]
})
export class RegisterComponent {
    public registrationForm!: FormGroup;
    public registrationFailed: boolean = false;
    public registrationFailed_invalidEmail: boolean = false;
    public registrationFailed_emailTaken: boolean = false;
    public registrationFailed_invalidUsername_invalidChars: boolean = false;
    public registrationFailed_usernameTaken: boolean = false;
    public registrationFailed_invalidPassword_failsRegex: boolean = false;
    public registrationFailed_passwordsDontMatch: boolean = false;
    public registrationFailed_serviceUnavailable: boolean = false;
    public isSubmitting: boolean = false;

    //SVG variables 
    public circleState: 'small' | 'medium' | 'large' = 'small';
    public circleRadius: number = 10;

    constructor(private fb: FormBuilder, private router: Router, private cdr: ChangeDetectorRef,
        private authService: AuthenticationService
    ){}
        
    ngOnInit(): void {
        this.buildForm(); 
    }

    private buildForm(): void {
        this.registrationForm = this.fb.group({
            email: ['', [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(255)]],
            username: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(16)]],
            password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(255)]],
            passwordConfirmation: ['', [Validators.required]]
        });
    }

    //Handles submission flow
    async onSubmit() {
        const email: string = this.registrationForm.get("email")?.value;
       
        //Reset all error conditions on submission
        this.registrationFailed = false;
        this.registrationFailed_invalidEmail = false;
        this.registrationFailed_emailTaken = false;
        this.registrationFailed_invalidUsername_invalidChars = false;
        this.registrationFailed_usernameTaken = false;
        this.registrationFailed_invalidPassword_failsRegex = false;
        this.registrationFailed_passwordsDontMatch = false;
        this.registrationFailed_serviceUnavailable = false;
        this.isSubmitting = true;
        this.cdr.detectChanges();

        //Begin registration flow
        await this.validateRegistrationArguments()
        .then(async isSuccess => {
            if(isSuccess){

                await AuthDataService.generateToken(email)
                .then(async isGenerated => {
                    this.cdr.detectChanges();
                    if(isGenerated){
                        await AuthDataService.sendEmailVerification(email)
                        .then(emailSent => {
                            if(emailSent){
                                this.authService.setEmail(email);
                                this.router.navigate(['/verify-account'])
                            } else{
                                this.registrationFailed = true;
                            }
                        });
                    } else{
                        this.registrationFailed = true;
                    }
                })
                
            }
            else{
                this.cdr.detectChanges();
            }
        })
        this.isSubmitting = false;
        return;
    }

    //Validates the credentials passed in from the form
    public async validateRegistrationArguments(): Promise<boolean>{
        let email = this.registrationForm.get('email');
        let username = this.registrationForm.get('username');
        let password = this.registrationForm.get('password');
        let passwordConfirmation = this.registrationForm.get('passwordConfirmation');

        //Validate that the email is structured correctly
        if(email?.invalid){
            this.registrationFailed_invalidEmail = true;
        }

        //Validate username properties
        if(!CommonUtil.isAlphanumeric(username?.value)){
            this.registrationFailed_invalidUsername_invalidChars = true;
        }

        //Lookup email and username for pre-existing entries
        if(!this.registrationFailed_invalidEmail && !this.registrationFailed_invalidUsername_invalidChars){
            await AuthDataService.lookupUser(email?.value, username?.value)
            .then(json => {
                if(!CommonUtil.isEmpty(json)){
                    const jsonObject = JSON.parse(json);
                    this.registrationFailed_emailTaken = jsonObject.registrationFailed_emailTaken;
                    this.registrationFailed_usernameTaken = jsonObject.registrationFailed_usernameTaken;
                } else {
                    //Service is unavailable 
                    this.registrationFailed_serviceUnavailable = true;
                }
                
            });
        }
        

        //Validate password, confirm matching password
        if(!CommonUtil.isValidPassword(password?.value)){
            this.registrationFailed_invalidPassword_failsRegex = true;
        } else if(password?.value !== passwordConfirmation?.value){
            this.registrationFailed_passwordsDontMatch = true;
        }

        //Checks to see if all error conditions are false
        //if so, make registration API call and return true
        if( this.registrationFailed_invalidEmail === false &&
            this.registrationFailed_emailTaken === false &&
            this.registrationFailed_invalidUsername_invalidChars === false &&
            this.registrationFailed_usernameTaken === false &&
            this.registrationFailed_invalidPassword_failsRegex === false &&
            this.registrationFailed_passwordsDontMatch === false &&
            this.registrationFailed_serviceUnavailable === false){
                const res = await AuthDataService.registerNewUser(email?.value, username?.value, password?.value)
                .then(json => {
                    if(CommonUtil.isEmpty(json)){
                        return false;
                    } else {
                        const jsonObject = JSON.parse(json);
                        if(jsonObject != null){
                            return jsonObject.isRegistered;
                        }
                    }
                });        
                
                return res;
            }


        return false;
    }
}
