<mat-dialog-content>
    <h3 class="dialog-header">Select a new username:</h3>
    <div class="profile-change-container">
        <input type="text" maxlength="16" [(ngModel)]="newUsername" />
    </div>

    <div *ngIf="nameChangeError_serviceUnavailable" class="auth-error-message">
        <p>The service is currently unavailable.  Please try again later.</p>
    </div>
    <div *ngIf="nameChangeError_usernameIsTaken" class="auth-error-message">
        This username is already taken.
    </div>
    <div *ngIf="nameChangeError_usernameIsInvalid" class="auth-error-message">
        <p>The username must be alphanumeric.</p>
    </div>

    <div class="profile-change-button-container">
        <!-- <button (click)="upload()">Upload</button> -->
        <button (click)="confirm()">Confirm</button>
        <button (click)="cancel()">Cancel</button>
    </div>
</mat-dialog-content>