import { APIDataService } from "./APIDataService";

interface RequestOptions{
    method: string,
    headers: {
        'Content-Type': string;
        'X-API-KEY': string;
    };
    body: URLSearchParams;
}

//Handles making calls to the backend related to authentication transactions
export class AuthDataService extends APIDataService{

    //Test API connection to ensure that the service is available
    public static async testConnection(): Promise<boolean>{
        const url: string = this.getAPIUrl() + `/auth/test`;
        const res: boolean = await this.get(url);
        return res;
    }
    //Makes API request to get the user
    public static async getUser(email: string): Promise<string>{
        const url: string = this.getAPIUrl() + `/auth/user/${email}`;
        const res: string = await this.get(url);
        return res;
    }

    public static async checkIfUsernameTaken(username: string): Promise<string>{
        const url: string = this.getAPIUrl() + `/auth/user/${username}/isTaken`;
        const res: string = await this.get(url);
        
        return res;
    }

    //API request to check for pre-existing email and user
    public static async lookupUser(email: string, username: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);
        params.append("username", username);

        const url: string = this.getAPIUrl() + `/auth/register/isTaken`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }
        
        const res: string = await this.post(url, requestOptions);
        return res;

    }

    //Makes API call to register a new user and returns true or false depending on if the process was successful
    public static async registerNewUser(email: string, username: string, password: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);
        params.append("username", username);
        params.append("pw", password);

        const url: string = this.getAPIUrl() + `/auth/register`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
        
    }

    //Finish the registration process
    public static async completeRegistration(uid: string): Promise<boolean>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("uid", uid);

        const url: string = this.getAPIUrl() + `/auth/registerToken`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: boolean = await this.post(url, requestOptions);
        return res;
    }

    //Update the current user's username
    public static async updateUsername(id: string, username: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("id", id);
        params.append("username", username);

        const url: string = this.getAPIUrl() + `/auth/user/updateUsername`;
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to log the authentication attempt in the backend
    public static async logAuthAttempt(email: string, isValid: boolean): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);
        params.append("valid", isValid.toString());
        const url: string = this.getAPIUrl() + `/auth/log`;

        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to generate an email verification token after registration details are logged in the db
    public static async generateToken(email: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);

        const url: string = this.getAPIUrl() + `/auth/generateToken`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to generate a password reset token
    public static async generatePasswordResetToken(email: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);

        const url: string = this.getAPIUrl() + `/auth/generatePasswordResetToken`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to send the verification email after token is generated
    public static async sendEmailVerification(email: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);

        const url: string = this.getAPIUrl() + `/auth/sendVerificationEmail`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to send a password reset email
    public static async sendPasswordResetEmail(email: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("email", email);

        const url: string = this.getAPIUrl() + `/auth/sendPasswordResetEmail`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to update the password for the user
    public static async updatePassword(token: string, pw: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("token", token);
        params.append("pw", pw);

        const url: string = this.getAPIUrl() + `/auth/updatePassword`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to update the password given their user id (for admin console)
    public static async updatePasswordWithID(id: string, pw: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("id", id);
        params.append("pw", pw);

        const url: string = this.getAPIUrl() + `/auth/updatePasswordWithID`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to update the user's email verification status (for admin console)
    public static async updateEmailVerificationStatus(id: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("id", id);

        const url: string = this.getAPIUrl() + `/auth/verifyEmail`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to delete the password reset token after successful update
    public static async deletePasswordResetToken(token: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("token", token);

        const url: string = this.getAPIUrl() + `/auth/deletePasswordResetToken`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }

    //Makes API call to check for user verification
    public static async checkEmailVerification(email: string): Promise<string>{
        const url: string = this.getAPIUrl() + `/auth/user/${email}/checkUserVerification`;
        const res: string = await this.get(url);
        return res;
    }

    //Makes API call to check if user has admin privileges
    public static async authenticateAdmin(username: string): Promise<string>{
        const params: URLSearchParams = new URLSearchParams();
        params.append("username", username);

        const url: string = this.getAPIUrl() + `/auth/authenticateAdmin`;
        const requestOptions: RequestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-API-KEY': this.getAPIKey()
            },
            body: params
        }

        const res: string = await this.post(url, requestOptions);
        return res;
    }
    
}