<mat-toolbar class="footer">
    <p>v.1.0.1</p>
    <!-- <div class="footer-item-container">
        <a class="footer-text">Developed by Foolish Wizard&trade;</a>
        <a href="about-us">Contact Us</a>
        <a class="footer-text">Listen Up is currently only compatible with desktop devices.  A mobile and tablet friendly version is coming soon.</a>
    </div>  -->

    <!-- <span class="nav-space"></span> -->
    <!-- <div class="login-button-container" *ngIf="isLogged" (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()">
        <img class="profile-picture" [src]="profilePicture" (click)="navigateToProfile()" />
        <div class="modal-content" *ngIf="isModal">
            <div class="modal-selection">
                <p (click)="navigateToProfile()">My profile</p>
            </div>
            <div class="modal-selection">
                <p (click)="logout()">Log out</p>
            </div>
        </div>
    </div>  -->
    
    <!-- <div class="login-button-container">
        <button *ngIf="!isLogged" class="nav-button" (click)="navigateToLogin()">Log In</button>
    </div> -->

    
</mat-toolbar>