import { ScaleQuality } from "../../constants/ScaleQualityConstants";
import { Scale } from "../../models/Scale";
import { StaffElement } from "./StaffElement";
import { StaffNote } from "./StaffNote";

//Encapsulate a scale to be drawn to the canvas
export class StaffScale extends StaffElement{
    private scale: Scale;
    private notes: StaffNote[];

    constructor(scale: Scale){
        super();
        this.scale = scale;
        this.notes = this.getStaffNotes(scale);
    }

    //Creates a series of staff notes from the note array of the passed in scale object
    private getStaffNotes(scale: Scale): StaffNote[]{
        let res: StaffNote[] = [];
        for(let note of scale.getNotes){
            let staffNote = new StaffNote(note);
            res.push(staffNote);
        }
        return res;
    }

    get getScale(): Scale{
        return this.scale;
    }

    set setScale(scale: Scale) {
        this.scale = scale;
    }

    get getNotes(): StaffNote[]{
        return this.notes;
    }

    set setNotes(notes: StaffNote[]){
        this.notes = notes;
    }

    get getName(): string{
        switch(this.scale.getQuality){
            case ScaleQuality.MAJOR:
                return "Major Scale";
            case ScaleQuality.NATURAL_MINOR:
                return "Natural Minor Scale";
            case ScaleQuality.MELODIC_MINOR:
                return "Melodic Minor Scale";
            case ScaleQuality.HARMONIC_MINOR:
                return "Harmonic Minor Scale";
            case ScaleQuality.HARMONIC_MAJOR:
                return "Harmonic Major Scale";
            case ScaleQuality.IONIAN:
                return "Ionian Mode";
            case ScaleQuality.DORIAN:
                return "Dorian Mode";
            case ScaleQuality.PHRYGIAN:
                return "Phrygian Mode";
            case ScaleQuality.LYDIAN:
                return "Lydian Mode";
            case ScaleQuality.MIXOLYDIAN:
                return "Mixolydian Mode";
            case ScaleQuality.AEOLIAN:
                return "Aeolian Mode";
            case ScaleQuality.LOCRIAN:
                return "Locrian Mode";
            case ScaleQuality.PERSIAN:
                return "Persian Scale";
            case ScaleQuality.HUNGARIAN_MINOR:
                return "Hungarian Minor Scale";
            case ScaleQuality.DOUBLE_HARMONIC:
                return "Double Harmonic Minor";
            case ScaleQuality.ENIGMATIC:
                return "Enigmatic Scale";
            case ScaleQuality.NEAPOLITAN_MAJOR:
                return "Neapolitan Major";
            case ScaleQuality.NEAPOLITAN_MINOR:
                return "Neapolitan Minor";
            case ScaleQuality.HALF_WHOLE_DIMINISHED:
                return "Half-Whole Diminished Scale";
            case ScaleQuality.NULL:
                return "";
        }
    }


}