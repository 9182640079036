import * as CryptoJS from 'crypto-js';

//Service class for hashing values w/ SHA256 algorithm
export class SHA256Service {

    //Hash plaintext using SHA256 algorithm
    public static hash(plaintext: string): Promise<string> {
        return new Promise((resolve, reject) => {
        try {
            const encoder: TextEncoder = new TextEncoder();
            const data: Uint8Array = encoder.encode(plaintext);

            crypto.subtle.digest('SHA-256', data).then((hashBuffer) => {
                const hashArray: number[] = Array.from(new Uint8Array(hashBuffer));
                const hashedStr: string = hashArray.map((byte) => byte.toString(16).padStart(2, '0')).join('');
                resolve(hashedStr);
            });
        } catch (error) {
            console.error('Error hashing:', error);
            reject(error);
        }
        });
    }

    //Hashes the plaintext string and compares it to the hashedtext value
    public static compareHash(plaintext: string, hashedtext: string, salt: string): boolean {
        const userHash: string = CryptoJS.SHA256(plaintext + salt).toString();
        return userHash === hashedtext;
    }
}
