<mat-dialog-content>
    <h3 class="dialog-header">Upload a new profile picture:</h3>
    <div class="profile-change-container">
        <img class="profile-image profile-image-border" [src]="profileImageUrl"/>
    </div>

    <div *ngIf="uploadError_failedToUpload || uploadError_usernameUnavailable" class="auth-error-message auth-error-message-left"><p>There was an error with uploading your picture.  Please try again.</p></div>
    <div class="profile-change-button-container">
        <button (click)="upload()">Upload</button>
        <button (click)="confirm()">Confirm</button>
        <input type="file" style="visibility: hidden; width: 1px; height: 1px;" id="upload_file"
                accept=".png,.jpg" (change)="uploadImage($event)">
    </div>
</mat-dialog-content>
