import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as Tone from 'tone';
import { PianoUtil } from '../../../util/PianoUtil';
import { Chord } from '../../../models/Chord';
import { GameHandlerComponent } from '../../../game/game-handler.component';
import { GameId } from '../../../constants/GameIdEnum';
import { ScoreRow } from '../../score-sheet/ScoreTypes';
import { ChordQuality } from '../../../constants/ChordEnum';

@Component({
    selector: 'app-chord-identifier',
    standalone: true,
    templateUrl: '../../game-handler.component.html',
    styleUrls: ['../../game-handler.component.css', './chord-identifier.component.css'],
    imports: [CommonModule, GameHandlerComponent]
})
export class ChordIdentifierComponent extends GameHandlerComponent{
    override initialCountdown: number = 300;
    private chord!: Chord;
    override gameId = GameId.EarTraining_Chords;

    override ngOnInit(): void {
        const isInit: boolean = this.initModule();
        this.chord = this.generateRandomChord(true);
        if(isInit) this.playSample(false);
        this.terminateAudio();
    }

    //Plays sample of the random chord
    protected override playSample(correct: boolean): void{
        if(correct){
            const c: Chord = this.generateRandomChord(true);
            this.chord = c;   
        }
        Tone.start().then(async () => {
            let sampleLength: Promise<number> = PianoUtil.playChord(this.chord);
            this.updateBar(await sampleLength);
        });
    }

    //Checks answer and updates score
    protected override checkAnswer(guess: number, buttonRef: HTMLButtonElement){
        let correct: boolean = false;
        let backgroundClassColor: string = '';
        const id: string = buttonRef.id;

        //User is correct, update score and play new sample
        if(guess == this.chord.getQuality){
            this.incrementScore();
            this.flashButton(id, true);
            backgroundClassColor = this.changeButtonBackgroundColor(true);
            correct = true;
        } else{
            this.decrementScore();
            this.flashButton(id, false);
            backgroundClassColor = this.changeButtonBackgroundColor(false);
        }
        buttonRef.classList.add(backgroundClassColor);
        this.handleAnswer(guess, this.gameId, correct, backgroundClassColor, buttonRef);
    }

    //Helper method to modularize game logic after answer is submitted
    private handleAnswer(guess: number, gameId: number, correct: boolean, 
                        backgroundClassColor: string, buttonRef: HTMLButtonElement){
        this.updateScore(correct);
        let scoreRow: ScoreRow = {
            id: guess,
            expectedId: this.chord.getQuality,
            gameId: gameId

        }
        this.scoreRows.push(scoreRow);
        this.playSample(correct);
        setTimeout(() => {
            this.resetFlashClasses();
            buttonRef.classList.remove(backgroundClassColor);
        }, 200);
    }


 


}
