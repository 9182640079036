import { ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { GameDataService } from '../data-services/GameDataService';
import { CommonUtil } from '../util/CommonUtil';
import { UserDataService } from '../data-services/UserDataService';
import { AuthenticationService } from '../auth/service/AuthenticationService';
import { MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ProfileDialogComponent } from './dialog/profile-dialog/profile-dialog.component';
import { CompressionUtil } from '../util/CompressionUtil';
import { UsernameProfileDialogComponent } from './dialog/username-profile-dialog/username-profile-dialog.component';
import { UnavailableComponent } from "../graphics/unavailable/unavailable.component";
import { SpinnerComponent } from "../graphics/spinner/spinner.component";
import { PasswordProfileDialogComponent } from './dialog/password-profile-dialog/password-profile-dialog.component';
import { GameData } from '../constants/GameData';


@Component({
    selector: 'app-profile',
    standalone: true,
    templateUrl: './profile.component.html',
    styleUrl: './profile.component.css',
    imports: [CommonModule, MatDialogModule, UnavailableComponent, SpinnerComponent]
})
export class ProfileComponent implements OnInit{
    public records: GameData[] = [];
    public isValidUser: boolean = false;
    public isCurrentUser: boolean = false;
    public username: string = "";
    public dateJoined: string = "";
    public profilePic: Blob = new Blob();
    public profilePicLoaded: boolean = false;
    public profilePicUrl: string = "";

    public showOverlay: boolean = false;
    public serviceUnavailable: boolean = false;
    public isLoaded: boolean = false;
    public isGearHovered: boolean = false;

    
    constructor(private authService: AuthenticationService, private cdRef: ChangeDetectorRef,
                private dialog: MatDialog, @Inject(PLATFORM_ID) private platformId: Object){}

    async ngOnInit(): Promise<void> {
        let username: string | null = "";
         if (isPlatformBrowser(this.platformId)) {
            username = this.authService.getUsername() as string;
        }

        //Check if a valid username was include in the URI
        if(CommonUtil.isEmpty(username)){
            this.serviceUnavailable = true;
            return;
        } else{
            this.isValidUser = true;
            const json: string = await GameDataService.getUserLeaderboardRecords(username);
            if(!CommonUtil.isEmpty(json)){
                this.records = JSON.parse(json);
                if(this.records == null){
                    this.isValidUser = false;
                }
            } else{
                this.isValidUser = false;
                this.serviceUnavailable = true;
            }
            // Run change detection after setting values
            this.cdRef.detectChanges();
        }

        //User is valid, set frontend details
        if(this.isValidUser){
            //Get the users joined date
            this.username = username;
            const json: string = await UserDataService.getUserProfileDetails(username);
            if(!CommonUtil.isEmpty(json)){
                const jsonObject = JSON.parse(json);
                this.dateJoined = jsonObject.joinedDate;
                const picAsBase64 = jsonObject.profilePicture;

                //If the user hasn't uploaded a profile picture yet, just load in the default one
                if(jsonObject.profilePicture !== null){
                    this.profilePic = jsonObject.profilePicture;
                    this.profilePicLoaded = true;
                }
                //User has an image, decode it and set the image url
                this.profilePic = CompressionUtil.decodeBase64Image(picAsBase64);
                this.setImageUrl();
            }
        }

        //Check if the current user is viewing their own page
        if(username === this.authService.getUsername()){
            this.isCurrentUser = true;
        }
        // Run change detection after setting values
        this.cdRef.detectChanges();
        this.isLoaded = true;
    }

    //Handles logic for hovering over gear icon
    public onHoverGearIcon(): void{
        this.isGearHovered = true;
    }

    public offHoverGearIcon(): void{
        this.isGearHovered = false;
    }

    //If the current user is viewing their own page, show profile picture change
    public onHoverProfilePicture(): boolean{
        if(this.isCurrentUser){
            this.showOverlay = true;
        }

        return this.showOverlay;
    }

    public offHoverProfilePicture(): boolean{
        this.showOverlay = false;
        return this.showOverlay;
    }

    //calls common util method for converting difficulty number to string
    public convertToDifficultyString(n: number): string{
        return CommonUtil.convertToDifficultyString(n);
    }

    //Opens a dialog box for changing the user's profile picture
    public openProfilePictureDialogBox(): void{
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.width = '450px';
        dialogConfig.data = { profilePicUrl: CommonUtil.isEmpty(this.profilePicUrl) ? "assets/icons/treble-clef.png" : this.profilePicUrl }
        const dialogRef: MatDialogRef<ProfileDialogComponent> = this.dialog.open(ProfileDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(async result => {
            if(result !== null && result !== undefined){
                this.profilePic = result;
                this.setImageUrl();
            } 
        });
    }

    //Opens a dialog box for changing the user's username
    public openUsernameChangeDialogBox(): void{
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.width = '300px';
        const dialogRef: MatDialogRef<UsernameProfileDialogComponent> = this.dialog.open(UsernameProfileDialogComponent, dialogConfig);


        dialogRef.afterClosed().subscribe(async result => {
            if(result !== null && result !== undefined){
                this.username = result;
                this.profilePic = result;
            } 
        })
    }

    //Opens a dialog box for changing the user's password
    public openPasswordChangeDialogBox(): void{
        const dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.width = '300px';
        const dialogRef: MatDialogRef<PasswordProfileDialogComponent> = this.dialog.open(PasswordProfileDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(() => {});
    }

    public getFormalGameTypeName(gameId: string){
        return CommonUtil.getFormalGameTypeName(gameId);
    }

    //Loads the blob into the image tag
    public setImageUrl(): void{
        if(this.profilePic.size > 0){
            this.profilePicUrl = URL.createObjectURL(this.profilePic);
        } else{
            this.profilePicUrl = 'assets/staff/treble-clef.png';
        }
        
    }

    //Pipe the date
    public pipeDate(date: string): string{
        return CommonUtil.pipeDate(date);
    }
}
