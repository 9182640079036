import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {
    private formData: BehaviorSubject<{ [key: string]: any } | null> = new BehaviorSubject<{ [key: string]: any } | null>(null);
    currentFormData = this.formData.asObservable();

    constructor() { }

    updateFormData(data: { [key: string]: any }) {
        this.formData.next(data);
    }

    getAndClearFormData() {
        const currentData = this.formData.value;
        this.formData.next(null);
        return currentData;
    }
}