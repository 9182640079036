import pako from 'pako';

//Utility class for compressing and decompressing blobs
export class CompressionUtil{
    public static async compress(blob: Blob): Promise<Blob> {
        return new Promise((resolve, reject) => {
            const reader: FileReader = new FileReader();
            reader.onload = () => {
                const byteArray: Uint8Array = new Uint8Array(reader.result as ArrayBuffer);
                
                // Compress the byte array using pako
                const compressedData: Uint8Array = pako.deflate(byteArray);
                
                // Create a Blob from the compressed data
                const compressedBlob: Blob = new Blob([compressedData]);
                
                resolve(compressedBlob);
            };
            
            reader.onerror = () => { 
                reject(reader.error);
            };
        
            reader.readAsArrayBuffer(blob);
        });
    }

    public static decompress(compressedImageData: Uint8Array): Uint8Array{
        try {
            // Decompress the image using Pako's inflate function
            const decompressedImageData: Uint8Array = pako.inflate(compressedImageData);
            return decompressedImageData;
        } catch (error) {
            console.error('Error decompressing image:', error);
            return new Uint8Array();
        }
    }

    public static decodeBase64Image(img: string): Blob{        
        // Decode the base64 data into raw binary data
        const binaryData = atob(img);
        
        // Convert the binary data into an array buffer
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }
        
        // Create a Blob object from the array buffer
        const blob = new Blob([uint8Array]);
        return blob;
    }
}